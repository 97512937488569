import {HTMLProps} from "react";
import * as React from "react";

export function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                               }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            style={{
                width: 16,
                height: 16,
                accentColor: "#6B46C1",
                cursor: "pointer",
                // boxShadow: "0px 0px 0px 1px rgba(255,0,0,1)",
                // appearance: "checkbox",
                // outline: "1px solid gray",
                // border: "1px solid gray",
                // borderRadius: "4px",
                // marginTop: "3px",
                // marginBottom: "0px",
                // paddingBottom: "0px",
            }}
            ref={ref}
            {...rest}
        />
    )
}
