import {
    Box,
    Center,
    Heading,
    HStack, LinkBox,
    SimpleGrid, Skeleton,
    Stack, Table, TableContainer, Tbody, Td,
    Text, Th, Thead, Tooltip, Tr,
    useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from '../Cards/Stat'
import {useCounterContext} from '../../store/userStore';
import {Card} from '../Cards/Card';
import moment from "moment";
import {Device} from "../../store/devicesStore";
import {format_raw} from "../../utils/format";
import {
    Link as A, useLocation, useParams,
} from "react-router-dom";
import {
    FaRegCheckCircle,
} from "react-icons/fa";
import {useEffect, useState} from "react";
import {DevicePage} from './DevicePage';


export const Devices = () => {
    const {currentUser, devicesStore, deviceInfoRequest} = useCounterContext();
    let {deviceId} = useParams();

    // const [lastDeviceId, setLastDeviceId] = useState("");
    // let lastDeviceId = ""
    // useEffect(() => {
    //     if (!deviceId) {
    //         return
    //     }
    //     if (lastDeviceId !== deviceId) {
    //         console.log(deviceId);
    //     }
    //     lastDeviceId = deviceId
    //     deviceInfoRequest(deviceId)
    //     // setLastDeviceId(deviceId)
    // }, [deviceId]);

    let stats: any[] = [undefined, undefined, undefined]
    if (devicesStore !== undefined && devicesStore.Dashboard !== undefined) {
        stats = [
            {label: 'Всего устройств', value: devicesStore.Dashboard.count, delta: {value: '0', isUpwardsTrend: true}},
            {
                label: 'В порядке',
                value: devicesStore.Dashboard.count_good,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {label: 'Требуют внимания', value: devicesStore.Dashboard.count_warning, delta: {value: '0', isUpwardsTrend: false}},
        ]
    }

    function getMinMaxTemp(temps: number[]) {
        let min = temps[0];
        let max = temps[0];
        for (let i = 1; i < temps.length; i++) {
            if (temps[i] < min) {
                min = temps[i];
            }
            if (temps[i] > max) {
                max = temps[i];
            }
        }
        return {min, max};
    }

    return (
        <>
            <Stack
                spacing="4"
                direction={{base: 'column', lg: 'row'}}
                justify="space-between"
                align={{base: 'start', lg: 'center'}}
            >
                <Stack spacing="1">
                    <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                        Устройства,
                    </Heading>
                    <Text color="muted">мониторинг и статистика</Text>
                </Stack>
                {/*<HStack spacing="3">*/}
                {/*<Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>*/}
                {/*    Загрузить отчет*/}
                {/*</Button>*/}
                {/*<Button variant="primary">Create</Button>*/}
                {/*</HStack>*/}
            </Stack>
            {deviceId === undefined &&
              <Stack spacing={{base: '5', lg: '6'}}>
                <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                    {stats.map((stat, id) => {
                        if (stat === undefined) {
                            return (
                                <Box
                                    px={{base: '4', md: '6'}}
                                    py={{base: '5', md: '6'}}
                                    bg="bg-surface"
                                    borderRadius="lg"
                                    key={id}>
                                    <Stack>
                                        <Skeleton height='20px'/>
                                        <Skeleton height='20px'/>
                                        <Skeleton height='20px'/>
                                    </Stack>
                                </Box>
                            )
                        }
                        return (
                            <Stat key={id} {...stat} />
                        )
                    })}
                </SimpleGrid>
              </Stack>
            }

            {devicesStore === undefined &&
              <Stack>
                <Skeleton height='50px'/>
                <Skeleton height='20px'/>
              </Stack>
            }
            {devicesStore === undefined && (
                <Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                        </Stack>
                    </Card>
                    <Box py={4}>
                        <Skeleton height='50px'/>
                    </Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                        </Stack>
                    </Card>
                </Box>
            )}
            {/*{devicesStore !== undefined && devicesStore.Dashboard !== undefined && devicesStore.Dashboard.pools.map((pool: Pool, id) => {*/}
            {/*    return (*/}
            {/*        <PoolItem key={id} pool={pool}/>*/}
            {/*    )*/}
            {/*})}*/}

            <Card mt={5} p={4} minH={1}>
                <SimpleGrid columns={[3, null, 24]} spacing='10px'>
                    {devicesStore && devicesStore.Dashboard && devicesStore.Dashboard.devices.map((device, index) => {
                        return (
                            <LinkBox key={index}>
                                <Tooltip
                                    hasArrow
                                    placement="top" label={`${device.brand} ${device.model}`} bg='gray.300' color='black'>
                                    <A to={`/devices/${device.id}`}>
                                        <Box
                                            _hover={{
                                                backgroundColor: getBackgroundColorHover(deviceId, device),
                                            }}
                                            bg={getBackgroundColor(deviceId, device)}
                                            p={2}>
                                            <Center>
                                                <FaRegCheckCircle
                                                    color={getIconColor(deviceId, device)}
                                                    style={{margin: 0, padding: 0}}
                                                />
                                            </Center>

                                            {/*<HStack*/}
                                            {/*    // textAlign={"center"}*/}
                                            {/*    justify="space-between"*/}
                                            {/*    color={device.is_ok ? '#2e4618' : '#423217'}*/}
                                            {/*    style={{margin: 0, padding: 0}}*/}
                                            {/*    fontSize={12}>*/}
                                            {/*    <FaRegClock*/}
                                            {/*        style={{margin: 0, padding: 0}}*/}
                                            {/*    />*/}
                                            {/*    <>{device.seen}</>*/}
                                            {/*</HStack>*/}
                                        </Box>
                                    </A>
                                </Tooltip>
                            </LinkBox>
                        )
                    })}
                </SimpleGrid>
            </Card>

            {deviceId !== undefined && <DevicePage deviceId={deviceId}/>}

            {deviceId === undefined &&
              <>
                <Heading size={"xs"} fontWeight="medium">
                  Список устройств
                </Heading>
                <Card minH="sm" p={4}>
                  <TableContainer>
                    <Table variant='striped'>
                      <Thead>
                        <Tr>
                          <Th>
                            Дата добавления<br/>Бренд, модель
                          </Th>
                          <Th>
                            Владелец
                            <br/>
                            Cерийный номер
                          </Th>
                          <Th>
                            Хешрейт 1ч<br/>
                            Хешрейт 24ч
                          </Th>
                          <Th>
                            Температура плат<br/>
                            Температура чипов (мин.-макс.)
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                          {devicesStore && devicesStore.Dashboard && devicesStore.Dashboard.devices.map((item: Device, i) => {
                              return (
                                  <Tr key={i}>
                                      <Td>
                                          <A to={`/devices/${item.id}`}>
                                              <Box fontSize={12}>{moment(item.created).format('DD.MM.YYYY, HH:mm:ss')}</Box>
                                              <Box>{item.brand} {item.model}</Box>
                                          </A>
                                      </Td>
                                      <Td>
                                          <A to={`/devices/${item.id}`}>
                                              <Box fontSize={12}>{item.owner_description}</Box>
                                              <Box>{item.device_serial}</Box>
                                          </A>
                                      </Td>
                                      <Td>
                                          <Box>{format_raw(item.hashrate_stats.hash_rate_1h / 1000000000000)}TH</Box>
                                          <Box>{format_raw(item.hashrate_stats.hash_rate_24h / 1000000000000)}TH</Box>
                                      </Td>
                                      <Td>
                                          <Box>
                                              <HStack>
                                                  {item.stats.boards_temp.map((temp: number, i) => {
                                                      return (
                                                          <Box key={i}>{temp}°C</Box>
                                                      )
                                                  })}
                                              </HStack>
                                          </Box>
                                          <Box>
                                              <HStack>
                                                  {item.stats.chips_temp.map((temps: number[], i) => {
                                                      let {min, max} = getMinMaxTemp(temps)
                                                      return (
                                                          <Box key={i}>{min}°C-{max}°C</Box>
                                                      )
                                                  })}
                                              </HStack>
                                          </Box>
                                      </Td>
                                  </Tr>

                              )
                          })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Card>
              </>
            }
        </>
    )
}

function getBackgroundColor(deviceId: string | undefined, device: Device) {
    // deviceId !== undefined && deviceId === device.id ? device.is_ok ? '#e2f3cf' : '#ff9200' : device.is_ok ? '#84bf40' : '#62431a'
    if (deviceId !== undefined) {
        if (deviceId === device.id) {
            return device.is_ok ? '#84bf40' : '#ff9200'
        }
        return device.is_ok ? 'rgba(150, 150, 150, 0.2)' : 'rgba(150, 150, 150, 0.2)' // '#e2f3cf' 'rgba(130, 150, 150, 0.1)'
    }
    return device.is_ok ? '#84bf40' : '#ff9200'
}

function getBackgroundColorHover(deviceId: string | undefined, device: Device) {
    // '#84bf40' : '#b46700' : device.is_ok ? '#84bf40' : '#ff9200'
    if (deviceId !== undefined) {
        if (deviceId === device.id) {
            return device.is_ok ? '#84bf40' : '#ff9200'
        }
        return device.is_ok ? '#c3d9a9' : '#ff9200'
    }
    return device.is_ok ? '#84bf40' : '#ff9200'
}

function getIconColor(deviceId: string | undefined, device: Device) {
    // deviceId !== undefined && deviceId === device.id ? device.is_ok ? '#a5c284' : '#6e4c22' : device.is_ok ? '#3e6511' : '#7c5d31'
    if (deviceId !== undefined) {
        if (deviceId === device.id) {
            return device.is_ok ? '#3e6511' : '#6e4c22'
        }
        return device.is_ok ? '#a1b28e' : '#af9c82'
    }
    return device.is_ok ? '#41621c' : '#6e4c22'
}
