import {Box, Flex, FlexProps, HStack, Img, Skeleton, Stack, useColorModeValue, useMenuButton, VStack} from '@chakra-ui/react'
import * as React from 'react'
import {HiSelector} from 'react-icons/hi'
import {UserProfile} from "./UserProfile";
import {FaBitcoin} from "react-icons/fa";
import {useCounterContext} from "../../store/userStore";
import {IsPlaceOwner} from "../../store/userHelper";

export const AccountSwitcherButton = (props: FlexProps) => {
    const buttonProps = useMenuButton(props)

    const {currentUser, setCurrentUser, currentUsers} = useCounterContext();

    let balance = ""
    if (!IsPlaceOwner(currentUser)) {
        if (currentUser && currentUser.balance) {
            balance = currentUser.balance
        } else {
            balance = "0.00000000"
        }
    }

    // let userStr = localStorage.getItem("user")
    // let user = undefined
    // if (userStr !== null && userStr !== "") {
    //     user = JSON.parse(userStr)
    // }
    return (
        <Flex
            {...buttonProps}
            w="full"
            display="flex"
            alignItems="flex-start"
            rounded="lg"
            // bg="gray.700"
            m="-2px 4px -2px -2px"
            px="1"
            py="5px"
            fontSize="sm"
            userSelect="none"
            cursor="pointer"
            // outline="0"
            // transition="all 0.2s"
            // backgroundColor={useColorModeValue('gray.700', 'gray.100')}
            _active={{bg: useColorModeValue('gray.100', 'gray.700')}}
            _focus={{shadow: 'outline', backgroundColor: useColorModeValue('gray.100', 'gray.700')}}
        >
            <HStack width={"100%"} justify="space-between">
                {currentUser && (
                    <UserProfile
                        name={(currentUser.firstname ? `${currentUser.firstname} ${currentUser.username}` : `User ${currentUser.username}`) || "User"}
                        // image="https://tinyurl.com/yhkm2ek8"
                        email={balance}
                    />
                )}
                <Box fontSize="lg" color="gray.400">
                    <HiSelector/>
                </Box>
            </HStack>
        </Flex>
    )
}
