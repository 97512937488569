import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'


export function SigninByClick() {
    return (
        <Container maxW="lg"
                   py={{base: '12', md: '24'}}
                   px={{base: '0', sm: '8'}}
        >
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{base: '2', md: '3'}} textAlign="center">
                        <Heading size={useBreakpointValue({base: 'xs', md: 'sm'})}>
                            Вход в аккаунт...
                        </Heading>
                        {/*<HStack spacing="1" justify="center">*/}
                        {/*    <Text color="muted">Нет аккаунта?</Text>*/}
                        {/*    <Button variant="link" colorScheme="blue">*/}
                        {/*        Зарегистрироваться*/}
                        {/*    </Button>*/}
                        {/*</HStack>*/}
                    </Stack>
                </Stack>
                {/*<Box*/}
                {/*    py={{base: '0', sm: '8'}}*/}
                {/*    px={{base: '4', sm: '10'}}*/}
                {/*    bg={useBreakpointValue({base: 'transparent', sm: 'bg-surface'})}*/}
                {/*    boxShadow={{base: 'none', sm: useColorModeValue('md', 'md-dark')}}*/}
                {/*    borderRadius={{base: 'none', sm: 'xl'}}*/}
                {/*>*/}
                {/*    <Stack spacing="6">*/}
                {/*        <Stack spacing="5">*/}
                {/*            <Stack*/}
                {/*                pb="5"*/}
                {/*                align={'center'}*/}
                {/*                alignSelf={'center'}>*/}
                {/*            </Stack>*/}

                {/*        </Stack>*/}

                {/*    </Stack>*/}
                {/*</Box>*/}
            </Stack>
        </Container>
    )
}
