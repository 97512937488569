import {
    Box,
    Menu,
    MenuDivider,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup, Skeleton, Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {PlaceSwitcherButton} from './PlaceSwitcherButton'
import {useCounterContext} from "../../store/userStore";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {User} from "../../store/userHelper";
import {BiLocationPlus} from "react-icons/all";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_ADDRESS} from "../../Config";
import {axiosConfig} from "../../store/localStore";
import {menuItems} from "../Manager/manager_menu";

interface Place {
    id: string
    title: string
}

interface PlacesResp {
    data: Place[]
}

export function PlaceSwitcher() {
    const {isLoading, error, data, isFetching, isFetched, isFetchedAfterMount} = useQuery(["/v1/manager/places"], () =>
        axios.get(`${API_ADDRESS}/v1/manager/places`, axiosConfig())
            .then((res: PlacesResp) => {
                return res.data
            })
    );

    let {placeId} = useParams();
    let navigate = useNavigate();

    const switchPlace = (id: string) => {
        navigate(`/manager/${id}/overview`)
    }

    if (isLoading) return (
        <Box>
            <Stack>
                <Skeleton height='20px'/>
                <Skeleton height='20px'/>
            </Stack>
        </Box>
    );
    if (error && error instanceof Error) return <Box>An error has occurred {error.message}</Box>;

    let currentPlace = data?.find(item => placeId === item.id)

    return (
        <Menu>
            <PlaceSwitcherButton currentplace={currentPlace} />
            <MenuList shadow="lg" py="4"
                // color={useColorModeValue('gray.600', 'gray.200')}
                      px="3">
                {/*<Text fontWeight="medium" mb="2">*/}
                {/*  joe.biden@chakra-ui.com*/}
                {/*</Text>*/}
                {data && (
                    <MenuOptionGroup defaultValue={placeId}>
                        {data?.map((u: Place, index: number) => {
                            return (
                                <MenuItemOption
                                    key={index}
                                    value={u.id}
                                    onClick={() => switchPlace(u.id)}
                                    fontWeight="semibold" rounded="md">
                                    {u.title}
                                </MenuItemOption>
                            )
                        })}
                    </MenuOptionGroup>
                )}
                <MenuDivider/>
                <MenuItem rounded="md" onClick={() => {
                    navigate("/settings")
                }}>Настройки</MenuItem>
                <MenuDivider/>
                <MenuItem rounded="md" onClick={() => {
                    navigate("/signin")
                }}><BiLocationPlus style={{marginRight: 10}}/>Добавить площадку</MenuItem>
            </MenuList>
        </Menu>
    )
}
