import constate from "constate";
import {useCallback, useEffect, useState} from "react";
import useWebSocket from "react-use-websocket";
import {JsonPrimitive} from "react-use-websocket/src/lib/types";
import {useToast} from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {useBoolean} from '@chakra-ui/react'
import {InvestmentsStore} from "./investmentsStore";
import {RealtimeRates} from "./realtimeRates";
import {WS_ADDRESS} from "../Config";
import {PoolsDashboard, poolsSetDashboard, PoolsStore} from "./poolsStore";
import {
    defaultRealtimeHashrateStore,
    Device,
    DevicesDashboard,
    devicesSetDashboard,
    devicesSetDevice, devicesSetRealtimeHashrate,
    DevicesStore,
    RealtimeHashrate,
    RealtimeHashrateStore
} from "./devicesStore";
import {TicketsDashboard, ticketsSetDashboard, TicketsStore} from "./ticketsStore";
import {User} from "./userHelper";

export type JsonArray = JsonValue[];
export type JsonObject = { [Key in string]?: JsonValue | JsonPrimitive };
export type JsonValue = JsonObject | JsonArray;

export interface TFARequest {
    password: string;
    one_time_code: string;
}

export interface WithdrawalRequest {
    wallet: string;
    value: string;
    password: string;
    one_time_code: string;
    agrees: string[];
}

export interface ChangePasswordRequest {
    one_time_code: string;
    old_password: string;
    new_password1: string;
    new_password2: string;
}

export interface Settings {
    require_tfa: boolean;
}

function useStore() {
    const [currentSettings, setCurrentSettings1] = useState<Settings>();

    const [currentUser, setCurrentUser1] = useState<User>();
    const [currentUsers, setCurrentUsers1] = useState<User[]>();

    const [investmentsStore, setInvestmentsStore] = useState<InvestmentsStore>();
    const [realtimeRatesStore, setRealtimeRatesStore] = useState<RealtimeRates>();
    const [poolsStore, setPoolsStore] = useState<PoolsStore>();
    const [devicesStore, setDevicesStore] = useState<DevicesStore>();
    const [realtimeHashrateStore, setRealtimeHashrateStore] = useState<RealtimeHashrateStore>(defaultRealtimeHashrateStore);
    const [deviceStore, setDeviceStore] = useState<DevicesStore>();
    const [ticketsStore, setTicketsStore] = useState<TicketsStore>();

    const functions: { [K: string]: Function } = {
        setInvestmentsDashboard: function (payload: InvestmentsStore) {
            setInvestmentsStore(payload)
        },
        setPoolsDashboard: function (payload: PoolsDashboard) {
            setPoolsStore(poolsSetDashboard(poolsStore, payload))
        },
        setDevicesDashboard: function (payload: DevicesDashboard) {
            setDevicesStore(devicesSetDashboard(devicesStore, payload))
        },
        setDevice: function (payload: Device) {
            setDeviceStore(devicesSetDevice(deviceStore, payload))
        },
        setRealtimeHashrate: function (payload: RealtimeHashrate) {
            setRealtimeHashrateStore(devicesSetRealtimeHashrate(realtimeHashrateStore, payload))
        },
        setTicketsDashboard: function (payload: TicketsDashboard) {
            setTicketsStore(ticketsSetDashboard(ticketsStore, payload))
        },
        setRealtimeRates: function (payload: RealtimeRates) {
            setRealtimeRatesStore(payload)
        },
    };

    // console.log(process.env, WS_ADDRESS);

    const setCurrentSettings = useCallback((payload: any) => {
        setCurrentSettings1({...currentSettings, ...payload});
    }, []);

    const setCurrentUser = useCallback((u: User | undefined) => {
        console.log("setCurrentUser", u);
        localStorage.setItem("user", u === undefined ? "" : JSON.stringify(u))
        setCurrentUser1(u);
    }, []);

    const setCurrentUsers = useCallback((u: User[]) => {
        console.log("setCurrentUsers", u);
        localStorage.setItem('users', JSON.stringify(u));
        setCurrentUsers1(u);
    }, []);

    let location = useLocation()
    let navigate = useNavigate();

    const [lastReconnectTimestamp, setReconnectTimestamp] = useState(0);
    const [isConnectMessageShow, setConnectMessageShow] = useBoolean()

    function newsSubscribe(payload: any) {
        sendJsonMessage(
            {
                type: 'news-subscribe-request',
                // @ts-ignore
                payload: payload,
            },
            true
        )
    }

    function deviceInfoRequest(payload: any) {
        sendJsonMessage(
            {
                type: 'device-info-request',
                // @ts-ignore
                payload: payload,
            },
            true
        )
    }

    function withdrawalRequest(payload: WithdrawalRequest) {
        sendJsonMessage(
            {
                type: 'new-withdrawal-request',
                // @ts-ignore
                payload: payload,
            },
            false
        )
    }

    function initTFA(payload: TFARequest) {
        sendJsonMessage(
            {
                type: 'init-tfa',
                // @ts-ignore
                payload: payload,
            },
            false
        )
    }

    function changePassword(payload: ChangePasswordRequest) {
        sendJsonMessage(
            {
                type: 'change-password',
                // @ts-ignore
                payload: payload,
            },
            false
        )
    }

    function saveUser(u: User) {
        sendJsonMessage(
            {
                type: 'save-user',
                // @ts-ignore
                payload: u,
            },
            true
        )
        setCurrentUser(u);
    }

    function sendSigninTokenMessage(token: string, pathname: string, hash: string) {
        sendJsonMessage(
            {
                type: 'signin-token',
                payload: {
                    "token": token,
                    "pathname": pathname,
                    "hash": hash,
                }
            },
            false
        )
    }

    const {sendJsonMessage, lastJsonMessage, sendMessage, lastMessage, readyState, getWebSocket} = useWebSocket(
        WS_ADDRESS,
        {
            onOpen: () => {
                setConnectMessageShow.off()
                console.log('ws opened')

                // toast({
                //           position: "top-right",
                //           title: "Соединение с платформой установлено",
                //           status: "success",
                //           isClosable: true,
                //           duration: 3000,
                //       })

                if (location.pathname === "/signin-by-click") {
                    sendJsonMessage(
                        {
                            type: 'signin',
                            payload: {
                                "hash": location.hash,
                            }
                        },
                        false
                    )
                    navigate("/", {replace: true})
                    return
                }

                let user = getUserFromLocal()
                if (user) {
                    console.log("send token:", user.token)
                    // user.token
                    //sendJsonMessage({
                    sendSigninTokenMessage(user.token, location.pathname, location.hash)
                    return
                }

                // function sayHi() {
                //     navigate("/signin")
                // }
                // setTimeout(sayHi, 10000*60);
                navigate("/signin")

            },
            onClose: () => {
                if (!isConnectMessageShow) {
                    toast({
                              position: "top-right",
                              title: "Потеря соединения, попытка переподключения...",
                              status: "error",
                              isClosable: true,
                              duration: 3000,
                          })
                    setConnectMessageShow.on()
                }
            },
            onError: (error) => {
                let errorText = "Ошибка подключения, возможно проблема с интернет соединением"
                if (lastReconnectTimestamp !== 0) {
                    errorText += ", следующая попытка через " + ((error.timeStamp - lastReconnectTimestamp) / 1000).toFixed(2) + " сек..."
                }
                setReconnectTimestamp(error.timeStamp)
                toast({
                          position: "top-right",
                          title: errorText,
                          status: "warning",
                          isClosable: true,
                          duration: 3000,
                      })
            },
            shouldReconnect: (closeEvent) => true,
            share: true,
            reconnectAttempts: 999999,
            reconnectInterval: 5000,
        }
    );

    // useEffect(() => {
    //     if (lastMessage !== null) {
    //         console.log(lastMessage);
    //     }
    // }, [lastMessage]);

    const switchUser = (id: string) => {
        let users = getUsersFromLocal()
        let user = users.find((u: User) => u.id === id)
        if (user) {
            setCurrentUser(user)

            // clear all stores
            setInvestmentsStore(undefined)

            sendSigninTokenMessage(user.token, location.pathname, location.hash)
            // window.location.reload()
        }
    }
    const signOut = (e: any) => {
        let users = getUsersFromLocal()
        let user = getUserFromLocal()
        let index = users.findIndex((u: User | undefined) => u && u.id === user.id)
        if (index !== -1) {
            users.splice(index, 1)
        }
        setCurrentUsers(users)
        if (users.length > 0) {
            setCurrentUser(users[0])
        } else {
            setCurrentUser(undefined)
        }
        window.location.reload()
    }

    const getUserFromLocal = () => {
        const u = localStorage.getItem("user")
        if (u !== null && u !== "") {
            return JSON.parse(u)
        }
        return undefined
    }
    const getUsersFromLocal = () => {
        let users = []
        let usersStr = localStorage.getItem("users")
        if (usersStr !== null && usersStr !== "") {
            users = JSON.parse(usersStr)
        }
        return users
    }
    const addCurrentUser = (payload: User) => {
        let success = updateCurrentUser(payload)
        if (success) {
            return
        }
        let users = getUsersFromLocal()
        users.push(payload)
        setCurrentUser(payload)
        setCurrentUsers(users)
    }

    const updateCurrentUser = (payload: User) => {
        let success = false
        let users = getUsersFromLocal()
        // update user in local storage
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === payload.id) {
                let token = users[i].token
                users[i] = payload
                if (payload.token === "") {
                    users[i].token = token
                }
                success = true
                break
            }
        }
        if (success) {
            setCurrentUser(payload)
            setCurrentUsers(users)
        }
        return success
    }

    const removeToken = (token: string) => {
        let users = getUsersFromLocal()
        users = users.filter(function (obj: User) {
            return obj.token !== token;
        });
        setCurrentUsers(users)
        if (users.length > 0) {
            setCurrentUser(users[0])
        } else {
            setCurrentUser(undefined)
        }
    }

    const removeCurrentUser = (payload: User) => {
        let users = getUsersFromLocal()
        users = users.filter(function (obj: User) {
            return obj.id !== payload.id;
        });
        setCurrentUsers(users)
        if (users.length > 0) {
            setCurrentUser(users[0])
        } else {
            setCurrentUser(undefined)
        }
    }

    // useEffect(() => {
    //     console.log(currentUser);
    // }, [currentUser]);

    let lastLocationPathname = ""
    useEffect(() => {
        if (!location) {
            return
        }
        if (lastLocationPathname !== location.pathname) {
            sendJsonMessage(
                {
                    type: 'changeLocation',
                    // @ts-ignore
                    payload: location,
                }, true)
        }
        lastLocationPathname = location.pathname
    }, [location]);

    const toast = useToast()

    useEffect(() => {

        if (lastJsonMessage !== null) {
            // console.log(lastJsonMessage);

            // @ts-ignore
            let payload = lastJsonMessage.payload
            // @ts-ignore
            let t = lastJsonMessage.type

            if (t !== "setRealtimeRates" && t !== "setRealtimeHashrate") {
                console.log(lastJsonMessage);
            }

            if (t === "R") {
                window.location.reload()
                return
            }
            if (t === "notification") {
                toast({
                          position: "top-right",
                          title: payload.message,
                          status: payload.type,
                          isClosable: true,
                      })
                return
            }
            // if (t === "updateCurrentUser") {
            //     updateCurrentUser(payload)
            // return
            // }
            if (t === "signOut") {
                signOut({})
                return
            }
            if (t === "removeToken") {
                removeToken(payload.token)
                return
            }
            if (t === "removeCurrentUser") {
                removeCurrentUser(payload)
                return
            }
            if (t === "addCurrentUser") {
                addCurrentUser(payload)
                return
            }
            if (t === "changePage") {
                navigate(payload.url)
                return
            }
            if (t === "setCurrentSettings") {
                setCurrentSettings(payload)
            }

            if (functions[t]) {
                return functions[t](payload);
            }
            // throw new Error(`Method '${t}' is not implemented.`);
        }
    }, [lastJsonMessage]);

    const wsSendJson = (msg: any) => sendJsonMessage(msg, true);

    return {
        switchUser,
        signOut,
        currentUser,
        setCurrentUser,
        currentUsers,
        setCurrentUsers,
        investmentsStore,
        realtimeRatesStore,
        saveUser,
        initTFA,
        withdrawalRequest,
        deviceInfoRequest,
        changePassword,
        currentSettings,
        wsSendJson,
        poolsStore,
        devicesStore,
        deviceStore,
        realtimeHashrateStore,
        ticketsStore,
        newsSubscribe,
    };
}

const [CounterProvider, useCounterContext] = constate(useStore);

export {CounterProvider, useCounterContext};
