import {
    Box, BoxProps, Breadcrumb, BreadcrumbItem, BreadcrumbLink,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {menuItems} from "./manager_menu";
import {useManagerContext} from "../../store/managerStore";
import {useEffect} from "react";
import {FaRegStar} from "react-icons/all";


export const ManagerContent = (props: BoxProps) => {
    let location = useLocation();
    let navigate = useNavigate();
    let {placeId} = useParams();

    const {pageSettings} = useManagerContext();

    let submenuFound = menuItems.find(item => location.pathname.startsWith(`/manager/${placeId}${item.link}`))
    let submenuBase = submenuFound?.link
    let submenuItems = submenuFound?.submenu
    let subSubmenuFound = submenuItems ? submenuItems.find(
        item => location.pathname.startsWith(`/manager/${placeId}${submenuBase}${item.link}`)) : undefined

    let shadow = useColorModeValue(
        '0 0 4px rgba(0,0,0,.1),0 0 24px rgba(0,0,0,.08)',
        '0 0 4px rgba(255,255,255,.1),0 0 24px rgba(255,255,255,.08)'
    )

    useEffect(() => {
        console.log("pageSettings >>>>>>> ", pageSettings);
    }, [pageSettings])

    return (
        <Box
            // bg={useColorModeValue('white', 'gray.900')}
            bg={useColorModeValue('custom.contentBgLight', 'custom.contentBgDark')}
            flex="1"
            overflowY="auto"
            // onScroll={(x) => setmMainIsScrolled(x.currentTarget.scrollTop > 32)}
            shadow={submenuItems && submenuItems.length > 0 ? undefined : shadow}
        >
            {/*
                    <ColumnHeader shadow={mainIsScrolled ? 'base' : 'none'}>
                        <HStack justify="space-between" width="full">
                            <HStack spacing="3">
                                <ColumnIconButton
                                    aria-label="Navigate back"
                                    icon={<FiArrowLeft/>}
                                    display={{base: 'inline-flex', md: 'none'}}
                                />
                                {mainIsScrolled && <ColumnHeading>Компактно</ColumnHeading>}
                            </HStack>
                            <ColumnButton leftIcon={<FiHeart/>}>12</ColumnButton>
                        </HStack>
                    </ColumnHeader>
                    */}
            <Box
                // maxW="3xl"
                // mx="auto"
                // py="8"
                pt={4}
                px={{base: '4', md: '6'}}
                // h="100vh"
            >

                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/manager/${placeId}${submenuFound?.link}`)
                            }}
                            href={`/manager/${placeId}${submenuFound?.link}`}>
                            {submenuFound?.name}
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    {subSubmenuFound &&
                      <BreadcrumbItem>
                        <BreadcrumbLink
                          onClick={(e) => {
                              e.preventDefault();
                              navigate(`/manager/${placeId}${submenuBase}${subSubmenuFound?.link}`)
                          }}
                          href={`/manager/${placeId}${submenuBase}${subSubmenuFound?.link}`}>
                            {subSubmenuFound?.name}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    }

                    {pageSettings && pageSettings.title !== "" && location.pathname.startsWith(pageSettings.location) &&
                      <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink
                          onClick={(e) => {
                              e.preventDefault();
                          }}
                          href={``}
                        >
                            {pageSettings?.title}
                        </BreadcrumbLink>
                        <FaRegStar style={{marginLeft: "7px", cursor: "pointer"}} />
                      </BreadcrumbItem>
                    }

                </Breadcrumb>

                <Outlet/>
            </Box>
            {/*
                    <ManagerContent
                        // maxW="3xl"
                        // mx="auto"
                        // py="8"
                        px={{base: '4', md: '8'}}
                        h="100vh"
                    />
                    */}
        </Box>
    )
}
