import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input, PinInput, PinInputField, Spinner,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue, VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import {useEffect} from "react";
import {useCounterContext} from "./store/userStore";
import {useLocation, useNavigate} from "react-router-dom";
import {IsPlaceOwner} from "./store/userHelper";

export function Redirect() {
    const {currentUser} = useCounterContext();

    let location = useLocation()
    let navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            // console.log("Redirecting to /dashboard");
            if (IsPlaceOwner(currentUser)) {
                navigate("/pools", {replace: true})
            } else {
                navigate("/investments", {replace: true})
            }
        }
    });

    return (
        <Container
            minH="100vh"
            maxW="lg"
        >
            <Center minH="100vh">
                <VStack>
                    <Heading size={'xs'}>
                        Загрузка...
                    </Heading>
                    <HStack p={10}>
                        <Spinner size='xl'/>
                    </HStack>
                    <Text color="muted" textAlign={"center"}>Долго загружается?<br/>Попробуйте обновить страницу.</Text>
                </VStack>
            </Center>
        </Container>
    )
}
