import {
    Badge,
    Box,
    Button,
    Center,
    Container,
    Divider,
    Heading,
    HStack, LinkBox,
    SimpleGrid, Skeleton,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tooltip, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from '../Cards/Stat'
import {useCounterContext} from '../../store/userStore';
import {Card} from '../Cards/Card';
import moment from "moment";
import {Ticket} from "../../store/ticketsStore";
import {FiEdit, GiFishingNet, SiBitcoin} from "react-icons/all";
import {useNavigate} from "react-router-dom";


export const Tickets = () => {
    const {currentUser, ticketsStore} = useCounterContext();
    let navigate = useNavigate();

    // console.log(ticketsStore);

    let stats: any[] = [undefined, undefined, undefined]
    if (ticketsStore !== undefined && ticketsStore.Dashboard !== undefined) {
        stats = [
            {label: 'Всего обращений', value: ticketsStore.Dashboard.count, delta: {value: '0', isUpwardsTrend: true}},
            {
                label: 'В работе',
                value: ticketsStore.Dashboard.count_opened,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {label: 'Завершены', value: ticketsStore.Dashboard.count_closed, delta: {value: '0', isUpwardsTrend: false}},
        ]
    }


    return (
        <>
            <Stack
                spacing="4"
                direction={{base: 'column', lg: 'row'}}
                justify="space-between"
                align={{base: 'start', lg: 'center'}}
            >
                <Stack spacing="1">
                    <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                        Обращения,
                    </Heading>
                    <Text color="muted">предложения и пожелания, запросы на вывод средств</Text>
                </Stack>
                <HStack spacing="3">
                    <Tooltip hasArrow shouldWrapChildren placement="top" label='Скоро!' bg='gray.300' color='black'>
                        <Button variant="secondary" disabled leftIcon={<FiEdit fontSize="1.25rem"/>}>
                            Создать обращение
                        </Button>
                    </Tooltip>
                    <Button
                        variant="secondary"
                        leftIcon={<SiBitcoin fontSize="1.25rem"/>}
                        onClick={() => {
                            navigate("/tickets/withdrawal")
                        }}>Запрос на вывод средств
                    </Button>
                </HStack>
            </Stack>
            <Stack spacing={{base: '5', lg: '6'}}>
                <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                    {stats.map((stat, id) => {
                        if (stat === undefined) {
                            return (
                                <Box
                                    px={{base: '4', md: '6'}}
                                    py={{base: '5', md: '6'}}
                                    bg="bg-surface"
                                    borderRadius="lg"
                                    key={id}>
                                    <Stack>
                                        <Skeleton height='20px'/>
                                        <Skeleton height='20px'/>
                                        <Skeleton height='20px'/>
                                    </Stack>
                                </Box>
                            )
                        }
                        return (
                            <Stat key={id} {...stat} />
                        )
                    })}
                </SimpleGrid>
            </Stack>

            {ticketsStore === undefined &&
              <Stack>
                <Skeleton height='50px'/>
                <Skeleton height='20px'/>
              </Stack>
            }
            {ticketsStore === undefined && (
                <Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                        </Stack>
                    </Card>
                    <Box py={4}>
                        <Skeleton height='50px'/>
                    </Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                        </Stack>
                    </Card>
                </Box>
            )}


            <Heading size={"xs"} fontWeight="medium">
                Список обращений
            </Heading>
            <Card minH="sm" p={4}>
                <TableContainer>
                    <Table variant='striped'>
                        {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                        <Thead>
                            <Tr>
                                <Th>
                                    Дата добавления<br/>Тип обращения
                                </Th>
                                <Th>
                                    Тема
                                    <br/>
                                    Сообщение
                                </Th>
                                <Th>
                                    Количество<br/>сообщений
                                </Th>
                                <Th>
                                    Статус
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ticketsStore && ticketsStore.Dashboard && ticketsStore.Dashboard.tickets.map((item: Ticket, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td>
                                            {/*<A to={`/tickets/${item.id}`}>*/}
                                            <Box fontSize={12}>{moment(item.created).format('DD.MM.YYYY, HH:mm:ss')}</Box>
                                            <Box>{item.type_display}</Box>
                                            {/*</A>*/}
                                        </Td>
                                        <Td>
                                            {/*<A to={`/tickets/${item.id}`}>*/}
                                            <Box fontSize={12}>{item.subject}</Box>
                                            <Box>{item.message}</Box>
                                            {/*</A>*/}
                                        </Td>
                                        <Td>
                                            {/*<A to={`/tickets/${item.id}`}>*/}
                                            <Box>{item.sub_tickets_count}</Box>
                                            {/*</A>*/}
                                        </Td>
                                        <Td>
                                            <Badge size="sm" colorScheme={item.is_finished ? "green" : "yellow"}>
                                                {item.status_display}
                                            </Badge>
                                        </Td>
                                    </Tr>

                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>

        </>
    )
}
