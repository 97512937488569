import {
    Box,
    Menu,
    MenuDivider,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup, Skeleton, Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {AccountSwitcherButton} from './AccountSwitcherButton'
import {useCounterContext} from "../../store/userStore";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {User} from "../../store/userHelper";

export function AccountSwitcher() {
    let {
        currentUser,
        setCurrentUser,
        currentUsers,
        switchUser,
        signOut,
    } = useCounterContext();
    let navigate = useNavigate();

    return (
        <Menu>
            {!currentUser ? (
                <Box>
                    <Stack>
                        <Skeleton height='20px'/>
                        <Skeleton height='20px'/>
                    </Stack>
                </Box>
            ) : (
                <AccountSwitcherButton/>
            )}
            <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
                {/*<Text fontWeight="medium" mb="2">*/}
                {/*  joe.biden@chakra-ui.com*/}
                {/*</Text>*/}
                {currentUsers && currentUser && (
                    <MenuOptionGroup defaultValue={currentUser && currentUser.username}>
                        {currentUsers && currentUsers.map((u: User, index: number) => {
                            return (
                                <MenuItemOption
                                    key={index}
                                    value={u.username}
                                    onClick={() => switchUser(u.id)}
                                    fontWeight="semibold" rounded="md">
                                    {u.firstname ? u.firstname : u.username}
                                </MenuItemOption>
                            )
                        })}
                    </MenuOptionGroup>
                )}
                <MenuDivider/>
                <MenuItem rounded="md" onClick={() => {
                    navigate("/settings")
                }}>Настройки</MenuItem>
                <MenuItem rounded="md" onClick={() => {
                    navigate("/signin")
                }}>Добавить аккаунт</MenuItem>
                <MenuDivider/>
                <MenuItem rounded="md" onClick={signOut}>Выход</MenuItem>
            </MenuList>
        </Menu>
    )
}
