import {ComponentStyleConfig, useColorModeValue} from "@chakra-ui/react";
import {mode} from "@chakra-ui/theme-tools";

// var variantSimple = function variantSimple(props) {
//   var c = props.colorScheme;
//   return {
//     th: _extends({
//       color: mode("gray.600", "gray.400")(props),
//       borderBottom: "1px",
//       borderColor: mode(c + ".100", c + ".700")(props)
//     }, numericStyles),
//     td: _extends({
//       borderBottom: "1px",
//       borderColor: mode(c + ".100", c + ".700")(props)
//     }, numericStyles),
//     caption: {
//       color: mode("gray.600", "gray.100")(props)
//     },
//     tfoot: {
//       tr: {
//         "&:last-of-type": {
//           th: {
//             borderBottomWidth: 0
//           }
//         }
//       }
//     }
//   };
// };

const borderColorLight = "rgba(0,0,0,0.1)";
const borderColorDark = "rgba(255,255,255,0.1)";

export const Table: ComponentStyleConfig = {
    baseStyle: ({colorMode}) => ({
        th: {
            fontFamily: "heading",
            fontWeight: "bold",
            textTransform: "none",
            letterSpacing: "normal",
            textAlign: "start",
            // color: "red",
            borderColor: colorMode === "dark" ? borderColorDark : borderColorLight,
            borderBottomWidth: "1px",
            _last: {
                position: "sticky",
                right: 0,
                zIndex: 2,
                bg: colorMode === "light" ? 'custom.contentBgLight' : 'custom.contentBgDark',
            },

        },
        td: {
            _notFirst: {
                // color: colorMode === "dark" ? "gray.500" : "gray.500",
                // bg: "red",
            },
            textAlign: "start",
            borderColor: colorMode === "dark" ? borderColorDark : borderColorLight,
            borderBottomWidth: "1px",
            // input: {
            //     content: '[x]',
            // },
            _last: {
                position: "sticky",
                right: 0,
                zIndex: 1,
                bg: colorMode === "light" ? 'custom.contentBgLight' : 'custom.contentBgDark',
            },
        },
        caption: {
            mt: 4,
            fontFamily: "heading",
            textAlign: "center",
            fontWeight: "medium"
        }
    }),
    sizes: {
        sm: {
            th: {
                px: "4",
                py: "1",
                lineHeight: "4",
                fontSize: "xs"
            },
            td: {
                px: "4",
                py: "2",
                fontSize: "sm",
                lineHeight: "4"
            },
            caption: {
                px: "4",
                py: "2",
                fontSize: "xs"
            }
        },
        md: {
            th: {
                overflow: "hidden",
                px: "1",
                py: "3",
                // pr: "1",
                // pl: "1",
                lineHeight: "4",
                fontSize: "13px",
                _first: {
                    pl: "4"
                },
                _last: {
                    // pr: "4"
                }
            },
            td: {
                px: "1",
                py: "3",
                // pt: "3",
                // pb: "3",
                lineHeight: "5",
                _first: {
                    pl: "4"
                },
                _last: {
                    // pr: "4"
                }
            },
            caption: {
                px: "6",
                py: "2",
                fontSize: "sm"
            }
        },
        lg: {
            th: {
                px: "8",
                py: "4",
                lineHeight: "5",
                fontSize: "sm"
            },
            td: {
                px: "8",
                py: "5",
                lineHeight: "6"
            },
            caption: {
                px: "6",
                py: "2",
                fontSize: "md"
            }
        }
    }
}
