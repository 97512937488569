import {
    Box, Divider,
    FlexProps,
    Stack, useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {deviceMenuItems, menuItems} from "./manager_menu";
import {NavLink} from "./MenuL1";
import {useManagerContext} from "../../store/managerStore";

interface NavbarProps extends FlexProps {
    onClose: any;
    isOpen: any;
    sidebarIsScrolled: any;
    onOpen?: any;
}

export const MenuL2 = (props: NavbarProps) => {
    let location = useLocation();
    let navigate = useNavigate();
    let {placeId, deviceId} = useParams();

    const {pageSettings} = useManagerContext();

    let submenuFound = menuItems.find(item => location.pathname.startsWith(`/manager/${placeId}${item.link}`))
    let submenuBase = submenuFound?.link
    let submenuItems = submenuFound?.submenu
    let subSubmenuFound = submenuItems ? submenuItems.find(
        item => location.pathname.startsWith(`/manager/${placeId}${submenuBase}${item.link}`)) : undefined

    let shadow = useColorModeValue(
        '0 0 4px rgba(0,0,0,.1),0 0 24px rgba(0,0,0,.08)',
        '0 0 4px rgba(255,255,255,.1),0 0 24px rgba(255,255,255,.08)'
    )

    if (submenuItems && submenuItems.length > 0) {
        return <Box
            borderRightWidth={submenuItems && submenuItems.length > 0 ? '1px' : undefined}
            // borderRightColor="gray.100"
            // width={{md: '20rem', xl: '24rem'}}
            // width={{md: '16rem'}}
            width={'15rem'}
            display={{base: 'none', md: 'initial'}}
            overflowY="auto"
            // onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
            //shadow={'0 0 4px rgba(0,0,0,.1),0 0 24px rgba(0,0,0,.08)'}
            shadow={shadow}
        >

            {/*
            <ColumnHeader shadow={props.sidebarIsScrolled ? 'base' : 'none'}>
                <HStack justify="space-between" width="full">
                    <HStack spacing="3">
                        <ColumnIconButton
                            onClick={props.onOpen}
                            aria-label="Open Navigation"
                            icon={<FiMenu/>}
                            display={{md: 'inline-flex', lg: 'none'}}
                        />
                        <Drawer isOpen={props.isOpen} placement="left" onClose={props.onClose}>
                            <DrawerOverlay/>
                            <DrawerContent>
                                <MenuL1 onClose={props.onClose}/>
                            </DrawerContent>
                        </Drawer>
                        <ColumnHeading>Список устройств</ColumnHeading>
                    </HStack>
                    <ColumnButton
                        leftIcon={<FiChevronsLeft/>}>Компактно</ColumnButton>
                </HStack>
            </ColumnHeader>
            */}

            {/*<Sidebar/>*/}

            {deviceId &&
              <Stack px="0"
                     pt={3}
                     pb={3}
                     spacing="6">
                <Box px="5"
                     pt="1.5"
                     fontWeight={'bold'}
                >
                  {pageSettings && pageSettings.title !== "" && location.pathname.startsWith(pageSettings.location) && pageSettings.title}
                </Box>
                <Stack spacing="1">
                    {deviceMenuItems && deviceMenuItems.map(item => {

                        let link = `/manager/${placeId}${submenuBase}${subSubmenuFound?.link}/${deviceId}${item.link}`
                        let active = location.pathname.startsWith(link)
                        if (item.link === "") {
                            active = location.pathname === link
                        }

                        return (
                            <NavLink
                                key={item.name}
                                // label={item.name}
                                icon={item.icon}
                                aria-current={active ? "page" : undefined}
                                onClick={() => {
                                    navigate(link)
                                }}
                                // disabled={item.link === ""}
                            >
                                {item.name}
                            </NavLink>
                        )
                    })}
                </Stack>
                <Divider />
              </Stack>}

            <Stack px="0"
                // pt={3}
                   pt={3}
                   spacing="6">
                <Box px="5"
                     pt="1.5"
                     fontWeight={'bold'}
                >{submenuFound?.name}</Box>
                <Stack spacing="1">
                    {submenuItems && submenuItems.map(item => {

                        let link = `/manager/${placeId}${submenuBase}${item.link}`
                        // let active = location.pathname.startsWith(link)
                        // if (link === "/") {
                            let active = location.pathname === link
                        // }

                        return (
                            <NavLink
                                key={item.name}
                                // label={item.name}
                                icon={item.icon}
                                aria-current={active ? "page" : undefined}
                                onClick={() => {
                                    navigate(link)
                                }}
                                // disabled={item.link === ""}
                            >
                                {item.name}
                            </NavLink>
                        )
                    })}
                </Stack>
            </Stack>
        </Box>
    }

    // height={`${props.height}px`}
    return null
}

