import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {
    Box,
    Center,
    Container,
    Grid,
    GridItem,
    Heading,
    HStack,
    SimpleGrid,
    Skeleton,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Text,
    Tr, useColorModeValue, VStack, Tooltip
} from "@chakra-ui/react";
import {Stat} from "../Cards/Stat";
import * as React from "react";
import {DevBoardStats, Device} from "../../store/devicesStore";
import {format_raw} from "../../utils/format";
import {LineChart} from "../Charts/LineChart";
import moment from "moment";
import {ElectricityHistoryItem} from "../../store/poolsStore";
import _ from "lodash";
import {Card} from "../Cards/Card";
import {IsAdmin, User} from "../../store/userHelper";
import {useCounterContext} from "../../store/userStore";

const AdminData = (props: any) => {
    if (!props.isAdmin) {
        return null
    }
    return (
        <Box color={"gray.400"} fontSize={11}>{props.children}</Box>
    )
}

const TH = () => {
    return (
        <Text
            color={useColorModeValue("gray.800", "gray.300")}
            style={{display: "inline"}}
            pl={"3px"}>TH</Text>
    )
}

const Prefix = (props: any) => {
    return (
        <Box
            width={"35px"}
            color={useColorModeValue("gray.800", "gray.500")}
            textAlign={"right"}
            style={{display: "inline-block"}}
            fontSize={10}
            pr={"4px"}
        >{props.children}</Box>
    )
}

const Caption = (props: any) => {
    return (
        <Box
            color={useColorModeValue("gray.800", "gray.500")}
            //textAlign={"right"}
            //style={{display: "inline-block"}}
            fontSize={10}
            //pr={"4px"}
        >{props.children}</Box>
    )
}

export const DevicePage = (props: any) => {
    const {currentUser, deviceStore, deviceInfoRequest, realtimeHashrateStore} = useCounterContext();
    let {deviceId} = useParams();

    let isAdmin = false
    if (currentUser !== undefined && IsAdmin(currentUser)) {
        isAdmin = true
    }

    // const memoizedValue = useMemo(() => computeExpensiveValue(a, b), [a, b]);

    const defaultState = [
        {
            "id": "Плата 0",
            "color": "hsl(216, 70%, 50%)",
            "data": [
                // {"x": 1, "y": 1}, {"x": 2, "y": 1}
            ],
        },
        {
            "id": "Плата 1",
            "color": "hsl(216,41%,48%)",
            "data": [
                // {"x": 1, "y": 1}, {"x": 2, "y": 1}
            ],
        },
        {
            "id": "Плата 2",
            "color": "hsl(215,76%,11%)",
            "data": [
                // {"x": 1, "y": 1}, {"x": 2, "y": 1}
            ],
        },
    ]

    const [chartData, setChartData] = useState(defaultState);

    useEffect(() => {
        let hashrate = realtimeHashrateStore.hashrate.hashrate
        // console.log(hashrate);

        // let seconds = new Date().toLocaleString()
// counter+=1
//     console.log(counter);

        const format = 'HH:mm:ss';
        // let date = moment().format(format)

        const limit = 12

        if (chartData[0].data.length < 3 && hashrate.length > 0) {
            for (let i = limit; i > 0; i--) {
                let now = moment()

                let s1 = moment.duration(i, 's')
                let d = now.subtract(s1).format(format)
                // @ts-ignore
                chartData[0].data.push({x: d, y: hashrate[0]})
                // @ts-ignore
                chartData[1].data.push({x: d, y: hashrate[1]})
                // @ts-ignore
                chartData[2].data.push({x: d, y: hashrate[2]})
            }

            setChartData([...chartData])
            // console.log(JSON.stringify(chartData));
        }


        // let chartData = chartData // _.cloneDeep(chartData)

        if (chartData[0].data.length > limit) {
            chartData[0].data = chartData[0].data.slice(1)
        }
        if (chartData[1].data.length > limit) {
            chartData[1].data = chartData[1].data.slice(1)
        }
        if (chartData[2].data.length > limit) {
            chartData[2].data = chartData[2].data.slice(1)
        }

        // @ts-ignore
        chartData[0].data.push({"x": moment().format(format), "y": hashrate[0]})
        // @ts-ignore
        chartData[1].data.push({"x": moment().format(format), "y": hashrate[1]})
        // @ts-ignore
        chartData[2].data.push({"x": moment().format(format), "y": hashrate[2]})

        // chartData = chartData
        // console.log(JSON.stringify(chartData));
        // setChartCounter(chartCounter + 1)

        const x = 10
        // console.log('>>', chartData[0].data.length, 0, chartData[0].data.length - x);
        if (chartData[0].data.length - x >= 0) {
            chartData[0].data = chartData[0].data.slice(chartData[0].data.length - x)
            chartData[1].data = chartData[1].data.slice(chartData[1].data.length - x)
            chartData[2].data = chartData[2].data.slice(chartData[2].data.length - x)
        }

        setChartData(chartData)

    }, [realtimeHashrateStore.hashrate]);

    // const [lastDeviceId, setLastDeviceId] = useState("");
    let lastDeviceId = ""
    useEffect(() => {
        if (!deviceId) {
            return
        }
        if (lastDeviceId !== deviceId) {
            console.log(deviceId);
        }
        setChartData(defaultState)
        lastDeviceId = deviceId
        deviceInfoRequest({"device_id": deviceId})
        // setLastDeviceId(deviceId)
    }, [deviceId]);


    let d: Device | undefined = undefined;
    if (deviceStore !== undefined && deviceStore.Device !== undefined) {
        d = deviceStore.Device
    }

    let descriptionColor = useColorModeValue("#606060", "#727272");

    let hashrate_1m = 0
    let hashrate_5m = 0
    let hashrate_15m = 0
    d && d.dev_boards_stats.forEach((stat: DevBoardStats) => {
        hashrate_1m += stat.hashrate_1m
        hashrate_5m += stat.hashrate_5m
        hashrate_15m += stat.hashrate_15m
    })

    return (
        <div>
            {!d ? (
                <Box
                    px={{base: '4', md: '6'}}
                    py={{base: '5', md: '6'}}
                    bg="bg-surface"
                    borderRadius="lg"
                >
                    <Stack>
                        <Skeleton height='20px'/>
                        <Skeleton height='20px'/>
                        <Skeleton height='20px'/>
                    </Stack>
                </Box>


            ) : (
                <>
                    <Box
                        bg="bg-surface"
                        borderRadius="lg"
                    >

                        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                            <GridItem w='100%'>
                                <Box
                                    px={{base: '4', md: '6'}}
                                    pt={{base: '5', md: '3'}}
                                    pb={{base: '5', md: '2'}}
                                    whiteSpace="nowrap"
                                >
                                    <Heading size={'xs'}>
                                        {d.brand} {d.model}
                                    </Heading>
                                    <Box fontSize={13}>
                                        {d.device_serial}
                                    </Box>
                                    <Box mt={2}>
                                        <TableContainer>
                                            <Table variant='unstyled' size='' fontSize={12} mb={2}>
                                                <Tbody>
                                                    <Tr>
                                                        <Td>
                                                            <Tooltip
                                                                hasArrow
                                                                placement="top"
                                                                label={`По данным устройства`} bg='gray.300' color='black'>
                                                                Device
                                                            </Tooltip>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>1M</Prefix>{format_raw(hashrate_1m / 1000 / 1000)}<TH/>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>5M</Prefix>{format_raw(hashrate_5m / 1000 / 1000)}<TH/>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>15M</Prefix>{format_raw(hashrate_15m / 1000 / 1000)}<TH/>
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>
                                                            <Tooltip
                                                                hasArrow
                                                                placement="top"
                                                                label={`По данным майнинг пула`} bg='gray.300' color='black'>
                                                                Pool
                                                            </Tooltip>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>NOW</Prefix>{format_raw(d.hashrate_stats.hashrate_now / 1000 / 1000 / 1000 / 1000)}<TH/>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>1H</Prefix>{format_raw(d.hashrate_stats.hash_rate_1h / 1000 / 1000 / 1000 / 1000)}<TH/>
                                                        </Td>
                                                        <Td>
                                                            <Prefix>24H</Prefix>{format_raw(d.hashrate_stats.hash_rate_24h / 1000 / 1000 / 1000 / 1000)}<TH/>
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                    <AdminData isAdmin={isAdmin}>
                                        worker: {d.worker_name},{' '}
                                        ip: <a
                                        target={"_blank"}
                                        href={`http://s10x${d.worker_name.replace("x", "x")}.radian.locations.vpn.prohash.cloud/cgi-bin/luci/admin/status/btminerstatus`}>{d.local_address}</a>
                                    </AdminData>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={2} w='100%'>
                                <VStack w={'100%'} h={'100%'} spacing={0}>
                                    <Box
                                        // bg={"green"}
                                        w={'100%'} h={'100%'}>
                                        {chartData[0].data.length > 2 ? (
                                            <LineChart simpleMode={true} data={chartData}/>
                                        ) : (
                                            <Stack pr={5} pt={4}>
                                                <Skeleton height='20px'/>
                                                <Skeleton height='20px'/>
                                                <Skeleton height='20px'/>
                                            </Stack>
                                        )}
                                    </Box>
                                    <Box w={'100%'}
                                        //h={'20px'}
                                        // bg={"red"}
                                         pb={2}
                                         textAlign={"center"}>
                                        <Caption>ХЕШРЕЙТ ОНЛАЙН (ПО ДАННЫМ УСТРОЙСТВА)</Caption>
                                    </Box>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Stack mt={5} spacing={{base: '5', lg: '6'}}>
                        <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                            {d.dev_boards_stats.map((board: DevBoardStats, id: number) => {
                                return (
                                    <Box
                                        px={{base: '4', md: '6'}}
                                        py={{base: '5', md: '6'}}
                                        bg="bg-surface"
                                        borderRadius="lg"
                                        key={id}
                                    >
                                        <HStack justify="space-between">
                                            <Box fontSize={18} mr={2}>
                                                Плата: {id}
                                            </Box>
                                            <Box fontSize={14}>
                                                {d && `чипов: ${d.stats.chips_temp[id].length}` || '-'}
                                            </Box>
                                            <Box fontSize={14} mr={2}>
                                                t: {board.temperature}°C
                                            </Box>
                                        </HStack>
                                        <AdminData isAdmin={isAdmin}>
                                            <Box
                                                pt={1} pb={0} fontSize={11}
                                                // color={descriptionColor}
                                            >
                                                ar: {board.accepted} / {board.rejected}
                                            </Box>
                                        </AdminData>
                                        <Box pb={2} fontSize={14}>
                                            sn: {hideSerial(board.serial_number)}
                                        </Box>
                                        <Box pb={2} fontSize={12}>
                                            <TableContainer>
                                                <Table variant='unstyled' size='' mb={2}>
                                                    <Thead>
                                                        <Tr>
                                                            <Th>
                                                                Factory
                                                            </Th>
                                                            <Th>
                                                                1M
                                                            </Th>
                                                            <Th>
                                                                5M
                                                            </Th>
                                                            <Th>
                                                                15M
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                            <Td>
                                                                {board.factory_hashrate !== 0 ?
                                                                    `${format_raw(board.factory_hashrate / 1000)}` :
                                                                    `${format_raw(d && d.device_hash_rate / 3 || 0)}`}<TH/>
                                                            </Td>
                                                            <Td>
                                                                {format_raw(board.hashrate_1m / 1000 / 1000)}<TH/>
                                                            </Td>
                                                            <Td>
                                                                {format_raw(board.hashrate_5m / 1000 / 1000)}<TH/>
                                                            </Td>
                                                            <Td>
                                                                {format_raw(board.hashrate_15m / 1000 / 1000)}<TH/>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </Box>

                                        <SimpleGrid columns={[3, null, 6]} spacing='10px'>
                                            {d && d.stats.chips_temp[id].map((temp: number, id2: number) => {
                                                return (
                                                    <Box
                                                        key={id2}
                                                        bg={getBackgroundColor(temp)}
                                                        p={2}>
                                                        <Center fontSize={'xs'}>
                                                            {/*<Container fontSize={'xs'}>*/}
                                                            {temp}°C
                                                            {/*</Container>*/}
                                                        </Center>
                                                    </Box>
                                                )
                                            })}
                                        </SimpleGrid>
                                    </Box>

                                    // <Stat key={id} {...stat} />
                                )
                            })}
                        </SimpleGrid>
                    </Stack>
                </>
            )}
        </div>
    )
}

function getBackgroundColor(t: number) {
    let res = `rgb(132, 191, 64, 0.7)` // '#84bf40'
    if (t > 100) {
        res = `rgba(255, 146, 0, 0.7)` // ff9200
    }
    if (t > 105) {
        res = `rgba(255, 68, 0, 0.7)` // res = '#ff6200'
    }
    if (t > 110) {
        res = `rgb(255, 0, 0, 0.8)` // res = '#ff1500'
    }
    return res
}

function hideSerial(serial: string) {
    if (serial.length > 10) {
        return serial.substring(0, 10) + '*******'
    }
    return serial
}
