import {
    Box,
    BoxProps,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    useColorModeValue, useMergeRefs,
} from '@chakra-ui/react'
import * as React from 'react'
import {PasswordField} from "../Signin/PasswordField";
import {ChangePasswordRequest, useCounterContext} from "../../store/userStore";
import {useState} from "react";
import {OtpInput} from "../Inputs/OtpInput";

export const SettingsPassword = (props: BoxProps) => {
    const {currentUser, changePassword} = useCounterContext();

    const emptyForm: ChangePasswordRequest = {
        one_time_code: "",
        old_password: "",
        new_password1: "",
        new_password2: "",
    }
    const [inputForm, setInputForm] = useState<ChangePasswordRequest>(emptyForm);

    const inputRef = React.useRef<HTMLInputElement>(null)

    const submitHandler = (e: any) => {
        e.preventDefault();

        if (inputRef.current) {
            inputRef.current.focus({preventScroll: true})
        }

        // console.log(inputForm);
        changePassword(
            {
                one_time_code: inputForm.one_time_code,
                old_password: inputForm.old_password,
                new_password1: inputForm.new_password1,
                new_password2: inputForm.new_password2,
            });
        cleanInput();
    }

    function cleanInput() {
        setInputForm(emptyForm);
    }

    function setInputOneTimeCode(value: string) {
        setInputForm({...inputForm, one_time_code: value});
    }

    function setInputOldPassword(value: string) {
        setInputForm({...inputForm, old_password: value});
    }

    function setInputNewPassword1(value: string) {
        setInputForm({...inputForm, new_password1: value});
    }

    function setInputNewPassword2(value: string) {
        setInputForm({...inputForm, new_password2: value});
    }


    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            {...props}
        >
            {/*<form name={"settingsPassword"}>*/}
            <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                {/*<Stack spacing="6" direction={{ base: 'column', md: 'row' }}>*/}
                {/*<FormControl id="lastName">*/}
                {/*  <FormLabel>Last Name</FormLabel>*/}
                {/*  <Input defaultValue="Winston" />*/}
                {/*</FormControl>*/}
                {/*</Stack>*/}
                <input type={"hidden"} name={"hidden"}/>
                <FormControl id="pwd">
                    <PasswordField
                        ref={inputRef}
                        onChange={(e) => {
                            setInputOldPassword(e.target.value);
                        }}
                        value={inputForm.old_password}
                        name={"old_password"} title={"Текущий пароль"}/>
                </FormControl>
                <FormControl id="new1">
                    <PasswordField
                        onChange={(e) => {
                            setInputNewPassword1(e.target.value);
                        }}
                        value={inputForm.new_password1}
                        name={"new_password1"} title={"Новый пароль"}/>
                </FormControl>
                <FormControl id="new2">
                    <PasswordField
                        onChange={(e) => {
                            setInputNewPassword2(e.target.value);
                        }}
                        value={inputForm.new_password2}
                        name={"new_password2"} title={"Повторите новый пароль"}/>
                </FormControl>
                {currentUser && currentUser.is_two_factor_enabled && (
                    <FormControl id="oneTimePassword1">
                        <FormLabel>Одноразовый код (one-time password)</FormLabel>
                        <OtpInput onChange={(value: any) => {
                            setInputOneTimeCode(value);
                        }}/>
                    </FormControl>
                )}

            </Stack>
            <Divider/>
            <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                <Button
                    onClick={submitHandler}
                    type="submit"
                    variant="primary">
                    Сменить пароль
                </Button>
            </Flex>
            {/*</form>*/}
        </Box>
    )
}
