import {
    Avatar,
    Box,
    BoxProps,
    Button,
    Divider,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Stack,
    Textarea,
    useColorModeValue,
} from '@chakra-ui/react'
import {QRCodeSVG} from 'qrcode.react'
import * as React from 'react'
import {PasswordField} from "../Signin/PasswordField";
import {useCounterContext} from "../../store/userStore";
import {useState} from "react";
import {Form} from "formik";
import {OtpInput} from "../Inputs/OtpInput";

export function Settings2FA(props: BoxProps) {
    const {currentUser, initTFA} = useCounterContext();

    const [inputForm, setInputForm] = useState(
        {
            password: "",
            one_time_code: "",
        });

    const submitHandler = (e: any) => {
        e.preventDefault();
        // console.log(inputForm);
        initTFA(inputForm);
        setInputPassword("");
        setInputOneTimeCode("");
    }

    function cleanInput() {
        setInputForm({
                         password: "",
                         one_time_code: "",
                     });
    }

    function setInputPassword(value: string) {
        setInputForm({...inputForm, password: value});
    }

    function setInputOneTimeCode(value: string) {
        setInputForm({...inputForm, one_time_code: value});
    }

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            {...props}
        >
            {/*<form name={"settings2FA"}>*/}
            <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                {currentUser && !currentUser.is_two_factor_enabled && (
                    <>
                        <FormLabel>Отсканируйте код инициализации</FormLabel>
                        <QRCodeSVG value={currentUser ? currentUser.two_factor_url : ""}/>
                    </>
                )}
                <FormControl id="pwd1">
                    <PasswordField
                        onChange={(e) => {
                            setInputPassword(e.target.value);
                        }}
                        value={inputForm.password}
                        name={"password"}
                        title={"Текущий пароль"}/>
                </FormControl>
                <FormControl id="oneTimePassword">
                    <FormLabel>Одноразовый код (one-time password)</FormLabel>
                    <OtpInput onChange={(value: any) => {
                        setInputOneTimeCode(value);
                    }}/>
                </FormControl>
            </Stack>
            <Divider/>
            <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                <Button
                    onClick={submitHandler}
                    type="submit"
                    variant="primary">
                    {currentUser && currentUser.is_two_factor_enabled ? "Отключить 2FA" : "Включить 2FA"}
                </Button>
            </Flex>
            {/*</form>*/}
        </Box>
    )
}
