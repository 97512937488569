import {PinInput, PinInputField} from "@chakra-ui/react";
import * as React from "react";

export const OtpInput = (props: any) => {
    return (
        <PinInput
            onChange={(value) => {
                props.onChange(value);
            }}
            otp>
            <PinInputField marginRight={3}/>
            <PinInputField marginRight={3}/>
            <PinInputField marginRight={3}/>
            <PinInputField marginRight={3}/>
            <PinInputField marginRight={3}/>
            <PinInputField marginRight={3}/>
        </PinInput>
    )
}
