import {
    Box, HStack,
    Input,
    InputGroup,
    InputLeftElement, TableContainer, Tbody, Td, Th, Thead, Tr, Table,
    Link,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    ColumnDef, flexRender,
    getCoreRowModel, getFilteredRowModel, getSortedRowModel, SortingState,
    useReactTable,
} from '@tanstack/react-table'
import {FiSearch} from 'react-icons/fi'
import {Icon} from "@chakra-ui/icons";
import moment from "moment/moment";
import {CopyToClipboardValue} from "../../CopyToClipboard/CopyToClipboard";
import {IndeterminateCheckbox} from "./TableCheckbox";
import {ColumnsMenu} from "../Devices/ColumnsMenu";
import {EditMenu} from "../Devices/EditMenu";
import {format_raw} from "../../../utils/format";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

require('moment/locale/ru');


export function TableWithFilter(props: any) {
    let navigate = useNavigate();

    // const [data, setData] = React.useState(() => [...props.data])
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        setData(props.data)
    }, [props.data]);

    // const rerender = React.useReducer(() => ({}), {})[1]
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const [rowSelection, setRowSelection] = React.useState({})
    const [globalFilter2, setGlobalFilter2] = React.useState('')
    const [globalFilter, setGlobalFilter] = React.useState('')

    let timeOutId: any = null
    const filterWithDelay = (query: string) => {
        setGlobalFilter2(query)
        // setGlobalFilter(query)
        // return
        if (timeOutId !== null) {
            clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => setGlobalFilter(query), 150);
    }


    let editMenuIsOpenColors = useColorModeValue("#333", "#ddd")
    let editMenuIsCloseColors = useColorModeValue('#777', '#777')

    moment.locale('ru');

    const columnsScheme: ColumnDef<any>[] = [
        {
            id: 'select',
            size: 1,
            maxSize: 1,
            header: ({table}) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({row}) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            ),
        },
        // {
        //     // id: "ip_address",
        //     accessorKey: "ip_address",
        //     header: "IP адрес",
        //     // @ts-ignore
        //     cell: info => <CopyToClipboard>{info.getValue()}</CopyToClipboard>,
        //     // size: 2,
        //     // maxSize: 2,
        //     // enableResizing: true,
        // },
        // {
        //     id: "last_activity",
        //     accessorKey: "last_activity",
        //     header: "Последняя активность",
        //     // cell: info => <i>{info.getValue()}</i>,
        //     // cell: info => <i>{JSON.stringify(info)}</i>,
        //     // @ts-ignore
        //     // cell: info => moment(info.getValue()).format('DD.MM.YYYY, HH:mm:ss'),
        //     cell: info => moment(info.getValue()).fromNow(),
        //
        // },
        // {
        //     id: "ports",
        //     accessorKey: "ports",
        //     header: "Доступные порты",
        // },
        // {
        //     accessorKey: "all_ports",
        //     header: "Все порты",
        // },

    ]

    props.columns.forEach((item: any, i: any) => {
        // console.log("item", i, item);
        const col: ColumnDef<any> = {
            id: item.id,
            accessorKey: item.id,
            accessorFn: (r) => _.get(r, item.id).toString(), // originalRow.matches.toString(), // matches is a number

            header: item.header,
            // cell: info => <i>{info.getValue()}</i>,
        }
        if (i === 0 || item.id.includes("ip") || item.id.includes("local_address")) {
            // col.cell = info => <CopyToClipboard>{info.getValue()}</CopyToClipboard>

            if (props.linkPrefix) {
                col.cell = info => <HStack><Link
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(`${props.linkPrefix}/${info.row.original.id}`)
                    }}
                    // @ts-ignore
                    href={`${props.linkPrefix}/${info.row.original.id}`}>{info.getValue()}</Link><CopyToClipboardValue
                    value={info.getValue()}/></HStack>
            } else {
                // @ts-ignore
                col.cell = info => <HStack><Text>{info.getValue()}</Text><CopyToClipboardValue value={info.getValue()}/></HStack>
            }
            // col.enableGlobalFilter = false
        }
        // if item.id has "created" or "updated" or "last_activity"
        if (item.id.includes("activity") || item.id.includes("created") || item.id.includes("updated")) {
            // @ts-ignore
            col.cell = info => moment(info.getValue()).fromNow()
            // col.enableGlobalFilter = false
        }
        if (item.id.includes("hashrate_stats.hash_rate")) {
            // @ts-ignore
            col.cell = info => <Box>{format_raw(info.getValue() / 1000000000000)}TH</Box>
            // col.enableGlobalFilter = false
        }
        columnsScheme.push(col)
    })

    columnsScheme.push(
        {
            id: 'edit',
            size: 1,
            maxSize: 1,
            header: ({table}) => (
                <ColumnsMenu id={table.getAllColumns()}
                             isOpenColors={editMenuIsOpenColors}
                             isCloseColors={editMenuIsCloseColors}
                />
            ),
            cell: ({row}) => (
                <EditMenu id={row}
                          isOpenColors={editMenuIsOpenColors}
                          isCloseColors={editMenuIsCloseColors}
                />
            ),
        },
    )

    // console.log(globalFilter2, globalFilter);

    const columns = React.useMemo<ColumnDef<any>[]>(
        () => columnsScheme,
        [])

    const table = useReactTable(
        {
            data,
            columns,
            state: {
                rowSelection,
                sorting,
                globalFilter,
            },
            onRowSelectionChange: setRowSelection,
            onSortingChange: setSorting,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            // debugTable: true
        })

    let tdDataColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

    const ref = React.useRef<HTMLInputElement>(null!)
    // const dimensions = useDimensions(ref)

    return (
        <>
            {/*
            <Box
                p={0}
                m={0}
                pos={"absolute"}
                bottom={0}
                h={20}
                left={dimensions ? dimensions.borderBox.left - 32 : undefined}
                right={0}
                bg={"custom.purple"}
                zIndex={5}
            >
                <Center h={"100%"} color='white'>
                    Выбраны 10 адресов
                </Center>
            </Box>
            */}
            <Box overflowX="auto" ref={ref}>
                <InputGroup maxW="md" size='sm'>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="#999" boxSize="5"/>
                    </InputLeftElement>
                    <Input
                        value={globalFilter2 ?? ''}
                        onChange={e => filterWithDelay(e.target.value)}
                        placeholder="Быстрый фильтр"
                    />
                </InputGroup>
                {/*{Object.keys(rowSelection).length > 0 && (*/}
                <Box px={2} py={3} fontSize={"sm"}>
                    {Object.keys(rowSelection).length} из{' '}
                    {table.getPreFilteredRowModel().rows.length} выбрано
                </Box>
                {/*)}*/}

                {/*
                <ManagerTable table={table}/>
                */}

                <TableContainer>
                    <Table mb={20} mt={1} variant={"unstyled"} fontSize={"13px"}>
                        <Thead
                            // bg={useColorModeValue('#eee', 'sm-dark')}
                        >
                            {table.getHeaderGroups().map(headerGroup => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <Th key={header.id}
                                            w={`${header.getSize()}px`}
                                            maxW={`${header.getSize()}px`}
                                            lineHeight={["select", "edit"].indexOf(header.column.id) > -1 ? 0 : undefined}
                                            // _last={{}}
                                            cursor={"pointer"}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <HStack
                                                    {...{
                                                        onClick: header.column.getToggleSortingHandler()
                                                    }}
                                                >
                                                    <Text>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </Text>
                                                    {{
                                                        asc: <Icon as={IoIosArrowUp} color="muted" boxSize="4"/>,
                                                        desc: <Icon as={IoIosArrowDown} color="muted" boxSize="4"/>
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </HStack>
                                            )}
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {table.getRowModel().rows.map(row => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell, i) => (
                                        <Td
                                            key={cell.id}
                                            lineHeight={["select", "edit"].indexOf(cell.column.id) > -1 ? 0 : undefined}
                                            color={i > 1 ? tdDataColor : undefined}
                                            width={`${cell.column.getSize()}px`}>
                                            {/*{JSON.stringify(cell)}*/}
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                        {/*
                <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </tfoot>
                */}
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}


