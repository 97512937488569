import {Icon} from '@chakra-ui/icons'
import {
    As,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Progress,
    Stack,
    Text, Tooltip,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiBarChart2,
    FiBookmark,
    FiCheckSquare, FiCommand, FiCpu, FiDollarSign, FiDroplet,
    FiHelpCircle,
    FiHome,
    FiSearch,
    FiSettings,
    FiTarget, FiTerminal,
    FiUsers,
} from 'react-icons/fi'
import {Logo} from '../Logo'
import {NavButton} from './NavButton'
import {Link as A, useLocation, useNavigate} from "react-router-dom";
import {ColorModeSwitcher} from "./ColorModeSwitcher";
import {IconType} from "react-icons/lib";
import {FaBitcoin, FaCoins, FaGem, FaMap, FaMapSigns, FaRegHandLizard, FaStream, FaTh} from "react-icons/fa";
import {AccountSwitcher} from "../AccountSwitcher/AccountSwitcher";
import {useCounterContext} from "../../store/userStore";
import {AllUserRoles, AllWithoutPlaceOwnerRoles, IsAdmin, IsPlaceOwner, Role} from "../../store/userHelper";


interface MenuElement {
    name: string
    link: string
    icon: IconType
    roles: Role[]
}

export const menuItems: MenuElement[] = [
    // {
    //     name: "Главное",
    //     link: "/dashboard",
    //     icon: FiHome,
    //     roles: AllWithotPlaceOwnerRoles,
    // },
    {
        name: "Инвестиции",
        link: "/investments",
        icon: FaCoins, //FaGem, // FaRegHandLizard, //
        roles: AllWithoutPlaceOwnerRoles,
    },
    {
        name: "Площадки",
        link: "", // /places
        icon: FaMap,
        roles: AllWithoutPlaceOwnerRoles,
    },
    {
        name: "Пулы",
        link: "/pools",
        icon: FaStream, //FaBitcoin,
        roles: AllUserRoles,
    },
    {
        name: "Устройства",
        link: "/devices",
        icon: FiCpu,
        roles: AllWithoutPlaceOwnerRoles,
    },
    {
        name: "Аналитика",
        link: "", // /analytics
        icon: FiBarChart2,
        roles: AllWithoutPlaceOwnerRoles,
    }
]

// export const menuItemsDisabled: MenuElement[] = [
//     {
//         name: "Аналитика",
//         link: "/analytics",
//         icon: FiBarChart2,
//     }
// ]

export const Sidebar = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const {currentUser} = useCounterContext();

    let isAdmin = false
    if (currentUser !== undefined && IsAdmin(currentUser)) {
        isAdmin = true
    }

    // height={`${props.height}px`}
    return (
        <Flex as="section"
              minH="100vh" maxH="100vh"
            // position={"absolute"}
              bg="bg-canvas">
            <Flex
                flex="1"
                bg="bg-surface"
                overflowY="auto"
                minH="100vh"
                maxH="100vh"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                maxW={{base: 'full', sm: 'xs'}}
                py={{base: '5', sm: '5'}}
                px={{base: '4', sm: '6'}}
            >
                <Stack justify="space-between" spacing="1">
                    <Stack spacing={{base: '5', sm: '6'}} shouldWrapChildren>
                        <HStack spacing="10" pb={3} justify="space-between">
                            <A to={"/"}><Logo color={useColorModeValue("#444", "#999")} color2={"#8000FF"}/></A>
                            <ColorModeSwitcher justifySelf="flex-end"/>
                        </HStack>
                        <AccountSwitcher/>

                        {/*<UserProfile*/}
                        {/*    name="Пользователь"*/}
                        {/*    // image="https://tinyurl.com/yhkm2ek8"*/}
                        {/*    email="2c745218-a261-4fdd-be8d-dc6a09daffe3"*/}
                        {/*/>*/}
                        <Divider/>
                        {/*<InputGroup>*/}
                        {/*    <InputLeftElement pointerEvents="none">*/}
                        {/*        <Icon as={FiSearch} color="muted" boxSize="5"/>*/}
                        {/*    </InputLeftElement>*/}
                        {/*    <Input placeholder="Search"/>*/}
                        {/*</InputGroup>*/}
                        <Stack spacing="1">
                            {menuItems.map(item => {
                                let active = location.pathname.startsWith(item.link)
                                if (item.link === "/") {
                                    active = location.pathname === item.link
                                }
                                if (currentUser && !item.roles.includes(currentUser.type)) {
                                    return null
                                }

                                if (item.link === "") {
                                    return (
                                        <Tooltip
                                            key={`disabled${item.name}`}
                                            hasArrow
                                            shouldWrapChildren
                                            placement="right" label='Скоро!' bg='gray.300' color='black'>
                                            <NavButton
                                                key={item.name}
                                                label={item.name}
                                                icon={item.icon}
                                                onClick={() => {
                                                    navigate(item.link)
                                                }}
                                                // aria-current={active ? "page" : undefined}
                                                disabled={item.link === ""}
                                            />
                                        </Tooltip>
                                    )
                                }

                                return (
                                    <NavButton
                                        key={item.name}
                                        label={item.name}
                                        icon={item.icon}
                                        onClick={() => {
                                            navigate(item.link)
                                        }}
                                        aria-current={active ? "page" : undefined}
                                        disabled={item.link === ""}
                                    />
                                )
                            })}

                            {/*{menuItemsDisabled.map(item => {*/}
                            {/*    let active = location.pathname.startsWith(item.link)*/}
                            {/*    if (item.link === "/") {*/}
                            {/*        active = location.pathname === item.link*/}
                            {/*    }*/}

                            {/*    return (*/}
                            {/*        <Tooltip*/}
                            {/*            key={`disabled${item.name}`}*/}
                            {/*            hasArrow*/}
                            {/*            shouldWrapChildren*/}
                            {/*            placement="right" label='Скоро!' bg='gray.300' color='black'>*/}
                            {/*            <NavButton*/}
                            {/*                key={item.name}*/}
                            {/*                label={item.name}*/}
                            {/*                icon={item.icon}*/}
                            {/*                onClick={() => {*/}
                            {/*                    navigate(item.link)*/}
                            {/*                }}*/}
                            {/*                aria-current={active ? "page" : undefined}*/}
                            {/*                disabled*/}
                            {/*            />*/}
                            {/*        </Tooltip>*/}
                            {/*    )*/}
                            {/*})}*/}
                        </Stack>
                    </Stack>
                    <Stack spacing={{base: '5', sm: '6'}}>
                        <Stack spacing="1">
                            {/*<NavButton label="Поддержка" icon={FiHelpCircle}/>*/}
                            {!IsPlaceOwner(currentUser) && (
                                <NavButton
                                    onClick={() => {
                                        navigate("/tickets")
                                    }}
                                    aria-current={location.pathname === "/tickets" ? "page" : undefined}
                                    label="Обращения" icon={FiCommand}/>
                            )}
                            {!IsPlaceOwner(currentUser) && (
                                <NavButton
                                    onClick={() => {
                                        navigate("/settings")
                                    }}
                                    aria-current={location.pathname === "/settings" ? "page" : undefined}
                                    label="Настройки" icon={FiSettings}/>
                            )}
                            {isAdmin && (
                                <NavButton
                                    onClick={() => {
                                        navigate("/manager")
                                    }}
                                    aria-current={location.pathname === "/manager" ? "page" : undefined}
                                    label="Для менеджера" icon={FiTerminal}/>
                            )}
                        </Stack>

                        {currentUser && !IsPlaceOwner(currentUser) && !currentUser.is_two_factor_enabled && (
                            <Box bg="bg-subtle" px="4" py="5" maxWidth={'200px'} borderRadius="lg">
                                <Stack spacing="4">
                                    <Stack spacing="1">
                                        <Text fontSize="sm" fontWeight="medium">
                                            Почти все готово
                                        </Text>
                                        <Text fontSize="sm" color="muted">
                                            Настройте двухфакторную авторизацию для безопасности вашего аккаунта.
                                        </Text>
                                    </Stack>
                                    <Progress value={80} size="sm" aria-label="Profile Update Progress"/>
                                    <HStack spacing="3">
                                        {/*<Button variant="link" size="sm">*/}
                                        {/*    Позже*/}
                                        {/*</Button>*/}
                                        <Button
                                            onClick={() => {
                                                navigate("/settings")
                                            }}
                                            variant="link" size="sm" colorScheme="blue">
                                            Настроить сейчас
                                        </Button>
                                    </HStack>
                                </Stack>
                            </Box>
                        )}
                        {/*<Divider/>*/}

                        {/*<Logo color={'#eee'} color2={'#e8e8e8'}/>*/}
                        {/*<Text fontSize="sm" color="subtle" pb={5}>*/}
                        {/*    &copy; {new Date().getFullYear()} Прохеш. Все права защищены.*/}
                        {/*</Text>*/}
                        {/*<UserProfile*/}
                        {/*    name="Unknown"*/}
                        {/*    // image="https://tinyurl.com/yhkm2ek8"*/}
                        {/*    email="2c745218-a261-4fdd-be8d-dc6a09daffe3"*/}
                        {/*/>*/}
                    </Stack>
                </Stack>
            </Flex>
        </Flex>
    )
}
