export const TypeUnknown = 0
export const TypeIncome = 1
export const TypeOutcome = 2

export const CurrencyTypeFiat = 1
export const CurrencyTypeCrypto = 2

export const CurrencyUsd = 1
export const CurrencyRub = 2
export const CurrencyBtc = 3
export const CurrencyEth = 4


interface InvestmentTransaction {
    id: string;
    value: number;
    fee: number;
    created: string;
    type: number;
    currency_type: number;
    currency_price: number;
    currency: number;
    description: string;
    comment: string;
}

export interface OutcomeDetail {
    type: string;
    value: number;
}

export interface InvestmentsDashboard {
    sum_income: number;
    sum_outcome: number;
    sum_outcome_details: OutcomeDetail[];
    sum_balance: number;

    reward_income: number;
    reward_outcome: number;
    reward_outcome_details: OutcomeDetail[];
    reward_balance: number;

    transactions: InvestmentTransaction[];
    transactions_limit: number;
    transactions_skip: number;
}

export interface InvestmentsStore {
    dashboard: InvestmentsDashboard | undefined;
}

