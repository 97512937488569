export interface Ticket {
    sub_tickets_count: number;
    is_finished: boolean;
    id: string;
    created: string;
    user_id: string;
    base_ticket_id: string;
    type: string;
    status: string;
    subject: string;
    message: string;
    deleted: boolean;
    sub_tickets: Ticket[];
    type_display: string;
    status_display: string;
}

export interface TicketsDashboard {
    count: number;
    count_opened: number;
    count_closed: number;

    tickets: Ticket[];
}

export interface TicketsStore {
    Dashboard: TicketsDashboard | undefined;
}

export function ticketsSetDashboard(ticketsStore: TicketsStore | undefined, payload: TicketsDashboard) {
    if (ticketsStore === undefined) {
        ticketsStore = {
            Dashboard: undefined,
        }
    }
    ticketsStore.Dashboard = payload;
    return ticketsStore;
}

