import {format_raw} from "../../utils/format";
import {useColorModeValue} from "@chakra-ui/react";
import {ResponsiveLine, Layer, LineSvgProps} from "@nivo/line";
import * as React from "react";

export const LineChart = (props: any) => {
    let layers: Layer[] = [
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
    ];
    let margin = {top: 15, right: 20, bottom: 50, left: 50};
    let grid = {
        "line": {
            "stroke": useColorModeValue("#dddddd", "#555"),
            "strokeWidth": 1
        }
    }
    // let curve: LineSvgProps = 'monotoneX';
    let c1 = useColorModeValue("#dddddd", "#555")

    let simpleMode = false
    if (props && props.simpleMode) {
        simpleMode = true
        // @ts-ignore
        // grid = {line: {stroke: '#ddd', "strokeDasharray": '3 5'}};
        // grid = false
        margin = {top: 20, right: 25, bottom: 28, left: 55};
        // margin = {top: 12, right: 15, bottom: 15, left: 10};
    } else {
        // layers.push('axes');
        margin = {top: 15, right: 20, bottom: 50, left: 50};
        grid = {
            "line": {
                "stroke": c1,
                "strokeWidth": 1
            }
        }
    }

    let defaultModeColors = useColorModeValue("#310062", "#a865f3")
    let simpleModeColors = defaultModeColors // useColorModeValue("#595959", "#9f9f9f")

    function formatTicks(value: any) {
        if (simpleMode) {
            return format_raw(value.toFixed(1))
        }
        return format_raw(value)
    }

    let strokeDomainLine = "#ccc";
    if (simpleMode) {
        strokeDomainLine = "rgba(0,0,0, 0)";
    }
    let strokeTicksLine = useColorModeValue("#777777", "#ffffff");
    if (simpleMode) {
        strokeTicksLine = "rgba(0,0,0, 0)";
    }
    let textColor = useColorModeValue("#333333", "#ffffff");
    let textColorSimpeMode = useColorModeValue("rgba(51,51,51,0.73)", "rgba(211,211,211,0.66)");

    return (
        <>
            <ResponsiveLine
                data={props.data}
                layers={layers}
                // keys={{"x": "abc", "y": "def"}}
                // indexBy="date"
                // areaBaselineValue={}
                areaBlendMode={'multiply'}
                // defs={[
                //     linearGradientDef('gradientA', [
                //         {offset: 0, color: 'inherit'},
                //         {offset: 100, color: 'inherit', opacity: 0},
                //     ]),
                // ]}
                // margin={{top: 20, right: 30, bottom: 40, left: 60}}
                margin={margin}
                //xScale={{
                //     type: 'log',
                //     base: 10,
                //     max: 'auto',
                //     type: 'linear',
                //     type: 'time',
                //     format: "%H:%M:%S",
                //      precision: "second",
                //}}
                yScale={{
                    // type: 'log',
                    // base: 2,
                    type: 'linear',
                    // type: 'point',
                    min: 'auto',
                    max: 'auto',
                    // stacked: false,
                    // reverse: true
                }}
                yFormat=" >-.2f"

                enableGridY={simpleMode ? false : true}
                // enablePoints={simpleMode ? false : true}
                pointSize={simpleMode ? 5 : 7}
                pointColor={'#fff'}
                // pointColor={{
                //     from: 'serieColor',
                //     modifiers: [['darker', 0.1]],
                // }}
                pointBorderWidth={2}
                // pointBorderColor={{from: 'serieColor'}}
                pointBorderColor={{
                    from: 'serieColor',
                    modifiers: [['darker', 0.2]],
                }}
                pointLabelYOffset={0}

                useMesh={true}
                // padding={0.5}
                // valueScale={{type: "linear"}}
                animate={false} // simpleMode
                // enableLabel={false}
                axisTop={null}
                enableSlices={simpleMode ? "x" : false}
                axisRight={null}
                axisLeft={{
                    tickValues: simpleMode ? 2 : undefined,
                    tickSize: 5,
                    tickPadding: 5,
                    // tickRotation: 0,
                    // tickValues: simpleMode ? [
                    //     // 25, 25.1, 25.2, 25.3, 25.4, 25.5, 25.6, 25.7, 25.8, 25.9,
                    //     26, 26.2,  26.4,  26.6,  26.8,
                    //     27, 27.2,  27.4,  27.6,  27.8,
                    //     28, 28.2, 28.4, 28.6, 28.8,
                    //     29, 29.2,  29.4,  29.6,  29.8,
                    //     30, 30.2,  30.4,  30.6,  30.8,
                    //     31, 31.2,  31.4,  31.6,  31.8,
                    //
                    // ] : undefined,
                    // legend: "degrees",
                    // legendPosition: "middle",
                    // legendOffset: -40
                    // format: value =>
                    //     `${Number(value).toLocaleString('ru-RU', {
                    //         minimumFractionDigits: 2,
                    //     })} ₽`,
                    format: value =>
                         simpleMode ? `${formatTicks(value)} TH` : `${formatTicks(value)}`, // кВт*ч
                }}
                // sliceTooltip={}

                axisBottom={{
                    // tickValues: simpleMode ? 8 : undefined,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: simpleMode ? 0 : -90,
                    // legend: 'transportation',
                    // legendOffset: 31,
                    // legendPosition: 'middle'
                }}
                crosshairType={"cross"}
                // colors={useColorModeValue("#310062", "#a865f3")}
                // colors={simpleMode ? simpleModeColors : defaultModeColors}
                colors={{ scheme: 'dark2' }}
                enableArea={simpleMode ? false : true}
                areaOpacity={useColorModeValue(0.05, 0.3)}
                curve='monotoneX'
                // curve='natural'
                // motionStiffness={120}
                // motionDamping={50}
                theme={{
                    // "background": useColorModeValue("#ffffff", "none"),

                    "textColor": simpleMode ? textColorSimpeMode : textColor,
                    "fontSize": 11,
                    "axis": {
                        "domain": {
                            "line": {
                                "stroke": strokeDomainLine,
                                "strokeWidth": 1
                            }
                        },
                        "legend": {
                            "text": {
                                "fontSize": 12,
                                "fill": "#333333"
                            }
                        },
                        "ticks": {
                            "line": {
                                "stroke": strokeTicksLine,
                                "strokeWidth": 1
                            },
                            "text": {
                                "fontSize": 11,
                                "fill": simpleMode ? textColorSimpeMode : textColor,
                            }
                        }
                    },
                    "grid": grid,
                    "legends": {
                        "title": {
                            "text": {
                                "fontSize": 11,
                                "fill": "#333333"
                            }
                        },
                        "text": {
                            "fontSize": 11,
                            "fill": "#333333"
                        },
                        "ticks": {
                            "line": {},
                            "text": {
                                "fontSize": 10,
                                "fill": "#333333"
                            }
                        }
                    },
                    "annotations": {
                        "text": {
                            "fontSize": 13,
                            "fill": "#333333",
                            "outlineWidth": 2,
                            "outlineColor": "#ffffff",
                            "outlineOpacity": 1
                        },
                        "link": {
                            "stroke": "#000000",
                            "strokeWidth": 1,
                            "outlineWidth": 2,
                            "outlineColor": "#ffffff",
                            "outlineOpacity": 1
                        },
                        "outline": {
                            "stroke": "#000000",
                            "strokeWidth": 2,
                            "outlineWidth": 2,
                            "outlineColor": "#ffffff",
                            "outlineOpacity": 1
                        },
                        "symbol": {
                            "fill": "#000000",
                            "outlineWidth": 2,
                            "outlineColor": "#ffffff",
                            "outlineOpacity": 1
                        }
                    },
                    "tooltip": {
                        "container": {
                            "background": "#ffffff",
                            "color": "#333333",
                            "fontSize": 14
                        },
                        "basic": {},
                        "chip": {},
                        "table": {},
                        "tableCell": {},
                        "tableCellValue": {}
                    }
                }}
            />
        </>
    )
}
