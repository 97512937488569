import constate from "constate";
import {useState} from "react";
import {JsonPrimitive} from "react-use-websocket/src/lib/types";
import {User} from "./userHelper";

export type JsonArray = JsonValue[];
export type JsonObject = { [Key in string]?: JsonValue | JsonPrimitive };
export type JsonValue = JsonObject | JsonArray;

export interface Settings {
    title: string;
    location: string;
}

function useStore() {
    const [pageSettings, setPageSettings] = useState<Settings>();

    function saveSettings(u: Settings) {
        // console.log("saveSettings", u);
        setPageSettings(u);
    }

    return {
        pageSettings,
        setPageSettings,
        saveSettings,
    };
}

const [ManagerProvider, useManagerContext] = constate(useStore);

export {ManagerProvider, useManagerContext};
