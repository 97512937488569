import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack,
    SimpleGrid, Skeleton,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from '../Cards/Stat'
import {useCounterContext} from '../../store/userStore';
import {PoolItem} from "./Pool";
import {Pool} from "../../store/poolsStore";
import {Card} from '../Cards/Card';
import {IsPlaceOwner} from "../../store/userHelper";


export const Pools = () => {
    const {currentUser, poolsStore} = useCounterContext();

    // console.log(poolsStore);

    let stats: any[] = [undefined, undefined, undefined]
    if (poolsStore !== undefined && poolsStore.Dashboard !== undefined) {
        stats = [
            {label: 'Участие в пулах', value: poolsStore.Dashboard.count, delta: {value: '0', isUpwardsTrend: true}},
            {
                label: 'Доля участия во всех пулах',
                value: `${poolsStore.Dashboard.share_pct / 100}%`,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {label: 'Номинальный хешрейт', value: `${poolsStore.Dashboard.hashrate} TH`, delta: {value: '0', isUpwardsTrend: false}},
        ]
    }

    return (
        <>
            <Stack
                spacing="4"
                direction={{base: 'column', lg: 'row'}}
                justify="space-between"
                align={{base: 'start', lg: 'center'}}
            >
                <Stack spacing="1">
                    <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                        Пулы,
                    </Heading>
                    <Text color="muted">информация и статистика</Text>
                </Stack>
                {/*<HStack spacing="3">*/}
                {/*<Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>*/}
                {/*    Загрузить отчет*/}
                {/*</Button>*/}
                {/*<Button variant="primary">Create</Button>*/}
                {/*</HStack>*/}
            </Stack>

            {!IsPlaceOwner(currentUser) && (
                <Stack spacing={{base: '5', lg: '6'}}>
                    <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                        {stats.map((stat, id) => {
                            if (stat === undefined) {
                                return (
                                    <Box
                                        px={{base: '4', md: '6'}}
                                        py={{base: '5', md: '6'}}
                                        bg="bg-surface"
                                        borderRadius="lg"
                                        key={id}>
                                        <Stack>
                                            <Skeleton height='20px'/>
                                            <Skeleton height='20px'/>
                                            <Skeleton height='20px'/>
                                        </Stack>
                                    </Box>
                                )
                            }
                            return (
                                <Stat key={id} {...stat} />
                            )
                        })}
                    </SimpleGrid>
                </Stack>
            )}

            {poolsStore === undefined &&
              <Stack>
                <Skeleton height='50px'/>
                <Skeleton height='20px'/>
              </Stack>
            }
            {poolsStore === undefined && (
                <Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                            <Skeleton height='50px'/>
                        </Stack>
                    </Card>
                    <Box py={4}>
                        <Skeleton height='50px'/>
                    </Box>
                    <Card p={4} minH={1}>
                        <Stack>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                            <Skeleton height='30px'/>
                        </Stack>
                    </Card>
                </Box>
            )}
            {poolsStore !== undefined && poolsStore.Dashboard !== undefined && poolsStore.Dashboard.pools.map((pool: Pool, id) => {
                return (
                    <PoolItem key={id} pool={pool}/>
                )
            })}

        </>
    )
}
