import {chakra, HTMLChakraProps} from '@chakra-ui/react'
import * as React from 'react'

export const Logo = (props: any) => (
    <svg width="122" height="34" viewBox="0 0 122 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 34L4.77197 7.55556H7.65824L7.61976 8.68889H7.81218C8.40226 8.21037 9.06931 7.84519 9.81332 7.59333C10.583 7.3163 11.3527 7.17778 12.1223 7.17778C13.4821 7.17778 14.5725 7.48 15.3934 8.08445C16.2401 8.6637 16.7917 9.67111 17.0482 11.1067C17.3048 12.517 17.2022 14.4815 16.7404 17C16.2786 19.5185 15.6628 21.4956 14.8932 22.9311C14.1491 24.3415 13.2512 25.3489 12.1993 25.9533C11.1731 26.5326 9.98009 26.8222 8.62033 26.8222C7.85066 26.8222 7.17078 26.7341 6.5807 26.5578C6.01627 26.3815 5.46467 26.1548 4.9259 25.8778L3.46353 34H0ZM7.85066 24.5556C8.6973 24.5556 9.45414 24.3541 10.1212 23.9511C10.8139 23.523 11.4168 22.7674 11.9299 21.6844C12.4687 20.5763 12.9177 19.0148 13.2769 17C13.636 14.9852 13.7515 13.4363 13.6232 12.3533C13.4949 11.2452 13.1614 10.4896 12.6226 10.0867C12.1095 9.65852 11.4296 9.44445 10.583 9.44445C10.0955 9.44445 9.58242 9.52 9.04365 9.67111C8.50488 9.82222 8.06873 9.99852 7.73521 10.2L5.31074 23.8C5.5673 23.9763 5.92648 24.1526 6.38828 24.3289C6.87574 24.48 7.3632 24.5556 7.85066 24.5556Z" fill={props.color}/>
<path d="M19.2743 26.4444L22.6608 7.55556H25.5856L25.5086 9.06667H25.701C26.2142 8.68889 26.894 8.34889 27.7407 8.04667C28.5873 7.71926 29.5879 7.55556 30.7424 7.55556H32.6666L32.1278 10.3889H28.2795C27.792 10.3889 27.3045 10.4519 26.8171 10.5778C26.3553 10.6785 25.9063 10.8674 25.4701 11.1444L22.7378 26.4444H19.2743Z" fill={props.color}/>
<path d="M38.4376 26.8222C36.8983 26.8222 35.654 26.52 34.7047 25.9156C33.7554 25.2859 33.1397 24.2533 32.8575 22.8178C32.5753 21.3822 32.6523 19.443 33.0884 17C33.5245 14.5319 34.1403 12.5926 34.9356 11.1822C35.7309 9.74667 36.7059 8.72667 37.8604 8.12222C39.0405 7.49259 40.4003 7.17778 41.9396 7.17778C43.479 7.17778 44.7233 7.49259 45.6725 8.12222C46.6218 8.72667 47.2375 9.74667 47.5198 11.1822C47.8276 12.5926 47.7635 14.5319 47.3273 17C46.8912 19.443 46.2626 21.3822 45.4416 22.8178C44.6463 24.2533 43.6586 25.2859 42.4784 25.9156C41.3239 26.52 39.977 26.8222 38.4376 26.8222ZM38.784 24.7444C39.4254 24.7444 40.0668 24.5178 40.7082 24.0644C41.3495 23.6111 41.9396 22.8304 42.4784 21.7222C43.0428 20.5889 43.5046 19.0148 43.8638 17C44.223 14.9852 44.3256 13.4237 44.1717 12.3156C44.0434 11.1822 43.7355 10.3889 43.2481 9.93556C42.7863 9.48222 42.2347 9.25556 41.5933 9.25556C40.9519 9.25556 40.3105 9.48222 39.6691 9.93556C39.0277 10.3889 38.4376 11.1822 37.8989 12.3156C37.3601 13.4237 36.9111 14.9852 36.5519 17C36.1927 19.0148 36.0773 20.5889 36.2056 21.7222C36.3339 22.8304 36.6289 23.6111 37.0907 24.0644C37.5782 24.5178 38.1426 24.7444 38.784 24.7444Z" fill={props.color}/>
<path d="M53.7141 26.4444V0H58.3321V8.31111C58.8196 7.93333 59.384 7.6563 60.0254 7.48C60.6925 7.27852 61.4108 7.17778 62.1805 7.17778C63.2837 7.17778 64.2843 7.34148 65.1822 7.66889C66.0802 7.9963 66.7985 8.60074 67.3373 9.48222C67.8761 10.3385 68.1455 11.5852 68.1455 13.2222V26.4444H63.5274V13.2222C63.5274 11.8874 63.2067 10.9933 62.5654 10.54C61.924 10.0615 61.2184 9.82222 60.4488 9.82222C59.9613 9.82222 59.538 9.89778 59.1788 10.0489C58.8196 10.2 58.5374 10.3763 58.3321 10.5778V26.4444H53.7141Z" fill={props.color2 ? props.color2 : props.color}/>
<path d="M78.1422 26.8222C77.0647 26.8222 76.0641 26.6585 75.1405 26.3311C74.2425 26.0037 73.5242 25.4119 72.9854 24.5556C72.4466 23.6741 72.1772 22.4148 72.1772 20.7778C72.1772 19.1407 72.4466 17.8941 72.9854 17.0378C73.5498 16.1563 74.3067 15.5519 75.2559 15.2244C76.2308 14.897 77.3212 14.7333 78.527 14.7333C79.1171 14.7333 79.6815 14.7711 80.2203 14.8467C80.7847 14.9222 81.3107 15.0104 81.7981 15.1111V13.6C81.7981 12.0889 81.439 11.0815 80.7206 10.5778C80.0022 10.0741 79.0145 9.82222 77.7574 9.82222H73.909V7.93333C74.4991 7.73185 75.2174 7.55556 76.0641 7.40445C76.9364 7.25333 77.7574 7.17778 78.527 7.17778C80.2716 7.17778 81.7212 7.35407 82.8757 7.70667C84.0558 8.03408 84.941 8.6637 85.5311 9.59556C86.1211 10.5022 86.4162 11.837 86.4162 13.6V26.4444H83.1451L82.5678 25.3111H82.3754C81.9649 25.6889 81.4133 26.0415 80.7206 26.3689C80.0535 26.6711 79.1941 26.8222 78.1422 26.8222ZM79.6815 24.1778C80.169 24.1778 80.5923 24.1022 80.9515 23.9511C81.3363 23.7748 81.6186 23.5985 81.7981 23.4222V17.3778H79.6815C79.2454 17.3778 78.7964 17.4785 78.3346 17.68C77.8985 17.8815 77.5265 18.2341 77.2186 18.7378C76.9364 19.2163 76.7953 19.8963 76.7953 20.7778C76.7953 21.6593 76.9364 22.3519 77.2186 22.8556C77.5265 23.3341 77.8985 23.6741 78.3346 23.8756C78.7964 24.077 79.2454 24.1778 79.6815 24.1778Z" fill={props.color2 ? props.color2 : props.color}/>
<path d="M95.4448 26.8222C94.4955 26.8222 93.5719 26.7341 92.674 26.5578C91.776 26.4067 91.032 26.243 90.4419 26.0667V24.1778H94.6751C96.1118 24.1778 97.2022 23.9763 97.9462 23.5733C98.7159 23.1452 99.1007 22.4652 99.1007 21.5333C99.1007 20.8785 98.8827 20.3496 98.4465 19.9467C98.0104 19.5185 97.4459 19.1533 96.7532 18.8511C96.0862 18.5489 95.355 18.2467 94.5597 17.9444C93.79 17.617 93.0588 17.2393 92.3661 16.8111C91.6991 16.3578 91.1475 15.7911 90.7113 15.1111C90.2752 14.4059 90.0571 13.5244 90.0571 12.4667C90.0571 10.3763 90.6985 8.97852 91.9813 8.27333C93.2897 7.54296 95.1498 7.17778 97.5614 7.17778C98.5363 7.17778 99.4727 7.25333 100.371 7.40445C101.269 7.55556 102 7.73185 102.564 7.93333V9.82222H98.3311C97.2792 9.82222 96.4069 10.0111 95.7142 10.3889C95.0215 10.7667 94.6751 11.3333 94.6751 12.0889C94.6751 12.7437 94.8932 13.2852 95.3293 13.7133C95.7655 14.1163 96.3171 14.4689 96.9841 14.7711C97.6768 15.0733 98.408 15.3881 99.1777 15.7156C99.973 16.0178 100.704 16.3956 101.371 16.8489C102.064 17.277 102.628 17.8311 103.065 18.5111C103.501 19.1911 103.719 20.0726 103.719 21.1556C103.719 22.6667 103.411 23.8378 102.795 24.6689C102.205 25.4748 101.294 26.0415 100.063 26.3689C98.857 26.6711 97.3177 26.8222 95.4448 26.8222Z" fill={props.color2 ? props.color2 : props.color}/>
<path d="M107.569 26.4444V0H112.187V8.31111C112.674 7.93333 113.239 7.6563 113.88 7.48C114.547 7.27852 115.265 7.17778 116.035 7.17778C117.138 7.17778 118.139 7.34148 119.037 7.66889C119.935 7.9963 120.653 8.60074 121.192 9.48222C121.731 10.3385 122 11.5852 122 13.2222V26.4444H117.382V13.2222C117.382 11.8874 117.061 10.9933 116.42 10.54C115.778 10.0615 115.073 9.82222 114.303 9.82222C113.816 9.82222 113.392 9.89778 113.033 10.0489C112.674 10.2 112.392 10.3763 112.187 10.5778V26.4444H107.569Z" fill={props.color2 ? props.color2 : props.color}/>
</svg>


)
