import {
    Box,
} from '@chakra-ui/react'
import * as React from 'react'
import {Header} from "../Header/Header";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_ADDRESS} from "../../../Config";
import {axiosConfig} from "../../../store/localStore";
import {useParams} from "react-router-dom";
import {TableWithFilter} from "../Table/TableWithFilter";

require('moment/locale/ru');


type SimpleAgentData = {
    ip: string
    os: string
    arch: string
    version: string
    branch: string
    build: number
}

type SimpleAgent = {
    created: string
    modified: string
    name: string
    data: SimpleAgentData
    last_activity: string
}


type SimpleAgentResp = {
    data: SimpleAgent[];
}

export const AgentsPage = () => {
    let {placeId} = useParams();

    async function fetch(): Promise<SimpleAgentResp> {
        const res = await axios.get(`${API_ADDRESS}/v1/manager/places/${placeId}/agents`, axiosConfig())
        return res.data
    }

    const {
        isLoading, error, data,
        // isFetching, isFetched, isFetchedAfterMount
    } = useQuery(["agents", placeId], fetch);

    if (isLoading) return <Box>Loading...</Box>;
    if (error && error instanceof Error) return <Box>An error has occurred {error.message}</Box>;

    const columns = [
        {
            id: "name",
            header: "Имя",
        },
        {
            id: "last_activity",
            header: "Активность",
        },
        {
            id: "data.ip",
            header: "IP адрес",
        },
        {
            id: "data.os",
            header: "ОС",
        },
        {
            id: "data.arch",
            header: "Архитектура",
        },
        {
            id: "data.branch",
            header: "Ветка",
        },
        {
            id: "data.version",
            header: "Версия",

        },
        {
            id: "data.build",
            header: "Сборка",
        },


    ]

    return (
        <Box>
            {/*
            <Header>Агенты</Header>
            */}
            <Box pt={5}>
                <TableWithFilter columns={columns} data={data}/>
            </Box>
        </Box>
    )
}




