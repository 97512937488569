import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack, Link,
    SimpleGrid,
    Stack, StackDivider, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from '../Cards/Stat'
import {FiDownloadCloud} from "react-icons/fi";
import {Card} from "../Cards/Card";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {SettingsProfile} from "./SettingsProfile";
import {Settings2FA} from "./Settings2FA";
import {ExternalLinkIcon} from '@chakra-ui/icons';
import {SettingsPassword} from './SettingsPassword';
import {SettingsNotifications} from './SettingsNotifications';
import {FaTelegram, FaTelegramPlane, FaUserCircle, FaUserClock, FaUserLock} from "react-icons/all";
import {useCounterContext} from "../../store/userStore";


const stats = [
    {label: 'Внесено', value: '3,500,000', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'В работе', value: '2,923,796', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'Баланс', value: '600,323', delta: {value: '0', isUpwardsTrend: false}},
]


export const Settings = (props: any) => {
    const {currentUser, saveUser} = useCounterContext();
    const pt = 4
    return (
        <>
            <Stack spacing="1" pb={3}>
                <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    Настройки
                </Heading>
                {/*<Text color="muted">профиль и двухфакторная авторизация</Text>*/}
            </Stack>
            <Stack spacing="4">

                <Box flexShrink={0}>
                    <HStack>
                        {/*<FaUserCircle color={"#555"} fontSize="1.25rem"/>*/}
                        <Text fontSize="lg" fontWeight="medium">
                            Профиль
                        </Text>
                    </HStack>
                    <Text color="muted" fontSize="sm">
                        Вы можете добавить ваше имя.
                    </Text>
                </Box>
                <SettingsProfile {...props} maxW={{lg: '4xl'}}/>

                <Box flexShrink={0} pt={pt}>
                    <HStack>
                        {/*<FaTelegram color={"#555"} fontSize="1.25rem"/>*/}
                        <Text fontSize="lg" fontWeight="medium">
                            Уведомления
                        </Text>
                    </HStack>
                    <Text color="muted" fontSize="sm">
                        Вы можете получать важные уведомления и новости сервиса в Телеграм.
                    </Text>
                </Box>
                <SettingsNotifications {...props} maxW={{lg: '4xl'}}/>

                <Box flexShrink={0} pt={pt}>
                    <HStack>
                        {/*<FaUserLock color={"#555"} fontSize="1.25rem"/>*/}
                        <Text fontSize="lg" fontWeight="medium">
                            Пароль
                        </Text>
                    </HStack>

                    <Text color="muted" fontSize="sm">
                        Вы можете сменить пароль.
                    </Text>
                </Box>
                <SettingsPassword maxW={{lg: '4xl'}}/>

                <Box flexShrink={0} pt={pt}>
                    <Box fontSize="lg" pb={3} fontWeight="medium">

                        <HStack>
                            {/*<FaUserClock color={"#555"} fontSize="1.25rem"/>*/}
                            <Text fontSize="lg" fontWeight="medium">
                                Двухфакторная авторизация
                            </Text>
                            {currentUser && currentUser.is_two_factor_enabled && (
                                <Badge colorScheme='green'>Включено</Badge>
                            )}
                            {currentUser && !currentUser.is_two_factor_enabled && (
                                <Badge colorScheme='red'>Выключено</Badge>
                            )}
                            {currentUser && !currentUser.is_two_factor_enabled && (
                                <Badge colorScheme='yellow'>Обязательно для вывода средств</Badge>
                            )}

                        </HStack>
                    </Box>
                    <Box color="muted" fontSize="sm" maxW={{lg: '4xl'}}>
                        Настройте авторизацию с использованием одноразовых кодов. Выберите приложение для авторизации, которое вы хотите
                        использовать.
                        Найти популярные приложения можно
                        <Link pl={2} href="https://geekflare.com/two-factor-authentication-apps/" isExternal>
                            здесь <ExternalLinkIcon mx='2px'/>
                        </Link>, или выбрать из предложенных ниже.
                    </Box>
                    <Text color="muted" fontSize="sm">
                        Google Authenticator:

                        <Link pl={2} href="https://apps.apple.com/us/app/google-authenticator/id388497605" isExternal>
                            iOS <ExternalLinkIcon mx='2px'/>
                        </Link>
                        <Link pl={2} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
                              isExternal>
                            Android <ExternalLinkIcon mx='2px'/>
                        </Link>
                    </Text>
                    <Box color="muted" fontSize="sm">
                        Lastpass:

                        <Link pl={2} href="https://apps.apple.com/us/app/lastpass-authenticator/id1079110004" isExternal>
                            iOS <ExternalLinkIcon mx='2px'/>
                        </Link>
                        <Link pl={2} href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator"
                              isExternal>
                            Android <ExternalLinkIcon mx='2px'/>
                        </Link>
                    </Box>
                    <Box color="muted" fontSize="sm">
                        Authy:

                        <Link pl={2} href="https://apps.apple.com/us/app/authy/id494168017" isExternal>
                            iOS <ExternalLinkIcon mx='2px'/>
                        </Link>
                        <Link pl={2} href="https://play.google.com/store/apps/details?id=com.authy.authy"
                              isExternal>
                            Android <ExternalLinkIcon mx='2px'/>
                        </Link>
                    </Box>
                </Box>
                <Settings2FA maxW={{lg: '4xl'}}/>
                <Box flexShrink={0} pt={5}>
                    {' '}
                </Box>

            </Stack>
        </>
    )
}
