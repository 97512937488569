import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input, PinInput, PinInputField,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {Logo} from '../Logo'
import {PasswordField} from "./PasswordField";
import {GuestMenu} from "../../GuestMenu";
import {ColorModeSwitcher} from "../Navigation/ColorModeSwitcher";
import {useState} from "react";
import {useCounterContext} from "../../store/userStore";

export function Signin() {
    const {wsSendJson, currentSettings} = useCounterContext();
    const [inputForm, setInputForm] = useState({});

    function setInputUsername(value: string) {
        setInputForm({...inputForm, username: value});
    }

    function setInputPassword(value: string) {
        setInputForm({...inputForm, password: value});
    }

    function setInputRememberMe(value: boolean) {
        setInputForm({...inputForm, remember_me: value});
    }

    function setOneTimeCode(value: string) {
        setInputForm({...inputForm, one_time_code: value});
    }

    const submitHandler = (e: any) => {
        e.preventDefault();
        console.log(inputForm);

        wsSendJson(
            {
                type: 'signin',
                payload: inputForm
            }
        )

    }

    return (
        <Container maxW="lg"
                   py={{base: '12', md: '24'}}
                   px={{base: '0', sm: '8'}}
        >
            {/*<GuestMenu/>*/}
            <Stack spacing="8">
                <Stack spacing="6">
                    {/*<Stack spacing={{ base: '2', md: '3' }} textAlign="center">*/}
                    {/*<Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>*/}
                    {/*  Вход в аккаунт*/}
                    {/*</Heading>*/}
                    {/*<HStack spacing="1" justify="center">*/}
                    {/*  <Text color="muted">Нет аккаунта?</Text>*/}
                    {/*  <Button variant="link" colorScheme="blue">*/}
                    {/*    Зарегистрироваться*/}
                    {/*  </Button>*/}
                    {/*</HStack>*/}
                    {/*</Stack>*/}
                </Stack>
                <Box
                    py={{base: '0', sm: '8'}}
                    px={{base: '4', sm: '10'}}
                    bg={useBreakpointValue({base: 'transparent', sm: 'bg-surface'})}
                    boxShadow={{base: 'none', sm: useColorModeValue('md', 'md-dark')}}
                    borderRadius={{base: 'none', sm: 'xl'}}
                >
                    <Stack spacing="6">
                        <Stack spacing="5">
                            <Stack
                                pb="5"
                                align={'center'}
                                alignSelf={'center'}>
                                <Logo color={"#444"}
                                      color2={"#8000FF"}
                                />
                            </Stack>
                            <ColorModeSwitcher justifySelf="flex-end"/>
                            <FormControl>
                                <FormLabel htmlFor="username">Идентификатор</FormLabel>
                                <Input
                                    id="username"
                                    type="username"
                                    onChange={(e) => {
                                        setInputUsername(e.target.value);
                                    }}
                                />
                            </FormControl>
                            <PasswordField
                                onChange={(e) => {
                                    setInputPassword(e.target.value);
                                }}
                            />
                        </Stack>
                        <HStack justify="space-between">
                            <Checkbox onChange={(e) => { // defaultChecked
                                setInputRememberMe(e.target.checked);
                            }}>Запомнить меня</Checkbox>
                            {/*<Button variant="link" colorScheme="blue" size="sm">*/}
                            {/*  Forgot password?*/}
                            {/*</Button>*/}
                        </HStack>

                        {currentSettings && currentSettings.require_tfa && (
                            <>
                                <FormLabel htmlFor="one-time-code">Одноразовый код (one-time password)</FormLabel>
                                <HStack justify="space-between">
                                    <PinInput
                                        onChange={(value) => {
                                            setOneTimeCode(value);
                                        }}
                                        otp>
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                    </PinInput>
                                </HStack>
                            </>
                        )}

                        <Stack spacing="6">
                            <Button variant="solid"
                                    onClick={submitHandler}>Войти</Button>
                            {/*<HStack>*/}
                            {/*  <Divider />*/}
                            {/*  <Text fontSize="sm" whiteSpace="nowrap" color="muted">*/}
                            {/*    or continue with*/}
                            {/*  </Text>*/}
                            {/*  <Divider />*/}
                            {/*</HStack>*/}
                            {/*<OAuthButtonGroup />*/}
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}
