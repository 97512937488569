import {Avatar, Box, HStack, Text, useColorModeValue} from '@chakra-ui/react'
import * as React from 'react'
import {FaBitcoin} from "react-icons/fa";

interface UserProfileProps {
    name: any
    // image: string
    email: any
}

const Balance = (props: any) => {
    return (
        <Box color="muted" fontSize="sm">
            <HStack>
                <FaBitcoin
                    style={{marginRight: 5}}
                    color={useColorModeValue('#ff9000', '#FBD38D')}/><>{props.balance}</>
            </HStack>
        </Box>
    )
}

export const UserProfile = (props: UserProfileProps) => {
    const {
        name,
        // image,
        email
    } = props
    return (
        <HStack spacing="3" ps="1">
            <Avatar name={name} boxSize="10"/>
            <Box>
                <Box fontWeight="medium" fontSize="sm">
                    {name}
                </Box>
                {email !== "" && <Balance balance={email}/>}
            </Box>
        </HStack>
    )
}
