import {
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from './components/Cards/Stat'
import {FiDownloadCloud} from "react-icons/fi";
import {Card} from "./components/Cards/Card";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {FaBitcoin, FaCheckCircle} from "react-icons/fa";


const stats = [
    {label: 'Хешрейт', value: '4,320', delta: {value: '1,200', isUpwardsTrend: true}},
    {label: 'Курс BTC', value: '20,309', delta: {value: '10.3%', isUpwardsTrend: true}},
    {label: 'Аптайм', value: '99.98%', delta: {value: '0.1%', isUpwardsTrend: false}},
]


const data = [
    {
        day: "02.06",
        hashrate: 1475
    },
    {
        day: "03.06",
        hashrate: 1950
    },
    {
        day: "04.06",
        hashrate: 2020
    },
    {
        day: "05.06",
        hashrate: 4199
    },
    {
        day: "06.06",
        hashrate: 4150
    },
    {
        day: "07.06",
        hashrate: 4490
    },
    {
        day: "08.06",
        hashrate: 4320
    }
];


const btcData = [
    {
        "id": "japan",
        "color": "hsl(216, 70%, 50%)",
        "data": [
            {
                x: "02.06",
                y: 29284
            },
            {
                x: "03.06",
                y: 30400
            },
            {
                x: "04.06",
                y: 31384
            },
            {
                x: "05.06",
                y: 28826
            },
            {
                x: "06.06",
                y: 25836
            },
            {
                x: "07.06",
                y: 19850
            },
            {
                x: "08.06",
                y: 20309
            }
        ]
    }
]

export const Dashboard = () => (
    <>

        <Stack
            spacing="4"
            direction={{base: 'column', lg: 'row'}}
            justify="space-between"
            align={{base: 'start', lg: 'center'}}
        >
            <Stack spacing="1">
                <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    Главное,
                </Heading>
                <Text color="muted">сводная информация об аккаунте</Text>
            </Stack>
            <HStack spacing="3">
                <Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>
                    Загрузить отчет
                </Button>
                {/*<Button variant="primary">Create</Button>*/}
            </HStack>
        </Stack>
        <Stack spacing={{base: '5', lg: '6'}}>
            <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                {stats.map((stat, id) => (
                    <Stat key={id} {...stat} />
                ))}
            </SimpleGrid>
        </Stack>
        <Stack spacing={{base: '5', lg: '6'}}>
            <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                <Card>
                    <ResponsiveBar
                        data={data}
                        keys={["hashrate"]}
                        indexBy="day"
                        margin={{top: 40, right: 30, bottom: 40, left: 60}}
                        padding={0.5}
                        valueScale={{type: "linear"}}
                        colors={useColorModeValue("#8000FF", "#deb9ff")}
                        borderColor={useColorModeValue("#8000FF", "#e5cdff")}
                        labelTextColor={useColorModeValue("#8000FF", "#e5cdff")}
                        animate={false}
                        enableLabel={false}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: "degrees",
                            // legendPosition: "middle",
                            // legendOffset: -40
                        }}
                    />

                </Card>
                <Card>
                    <ResponsiveLine
                        data={btcData}
                        // keys={["hashrate"]}
                        pointLabelYOffset={0}
                        // indexBy="day"
                        // enableArea={true}
                        margin={{top: 40, right: 30, bottom: 40, left: 60}}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false
                        }}
                        yFormat=" >-.2f"
                        useMesh={true}
                        // padding={0.5}
                        // valueScale={{type: "linear"}}
                        colors={useColorModeValue("#8000FF", "#deb9ff")}
                        animate={false}
                        // enableLabel={false}
                        axisTop={null}
                        axisRight={null}
                        pointSize={7}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: "degrees",
                            // legendPosition: "middle",
                            // legendOffset: -40
                        }}
                    />
                </Card>
                <Card p={5}>
                    <HStack pb={3}>
                        <>Статус платформы</>
                    </HStack>
                    <HStack>
                        <FaCheckCircle style={{marginRight: 5}} color={useColorModeValue('#128100', '#6da66a')}/>
                        <>Operational</>
                    </HStack>
                </Card>
            </SimpleGrid>
        </Stack>
        <Card minH="sm"/>

    </>
)
