import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack,
    SimpleGrid,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from './components/Cards/Stat'
import {FiDownloadCloud} from "react-icons/fi";
import {Card} from "./components/Cards/Card";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'


const stats = [
    {label: 'Приборов учета', value: '1', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'Потребление в текущем месяце', value: '92,796 кВч', delta: {value: '12%', isUpwardsTrend: true}},
    {label: 'Среднее за 6 месяцев', value: '78,204 кВч', delta: {value: '3%', isUpwardsTrend: false}},
]


export const Places = () => (
    <>

        <Stack
            spacing="4"
            direction={{base: 'column', lg: 'row'}}
            justify="space-between"
            align={{base: 'start', lg: 'center'}}
        >
            <Stack spacing="1">
                <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    Площадки,
                </Heading>
                <Text color="muted">информация о потреблении электроэнергии</Text>
            </Stack>
            <HStack spacing="3">
                <Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>
                    Загрузить отчет
                </Button>
                {/*<Button variant="primary">Create</Button>*/}
            </HStack>
        </Stack>
        <Stack spacing={{base: '5', lg: '6'}}>
            <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                {stats.map((stat, id) => (
                    <Stat key={id} {...stat} />
                ))}
            </SimpleGrid>
        </Stack>

        <Stack spacing="1">
            <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                Радиан, г. Иркутск
            </Heading>
            <Text color="muted">статистика по месяцам</Text>
        </Stack>
        <Card minH="sm" p={4}>
            <TableContainer>
                <Table variant='striped'>
                    {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                    <Thead>
                        <Tr>
                            <Th>Дата</Th>
                            <Th isNumeric>Потребление</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Июнь 2022</Td>
                            <Td isNumeric>92,796 кВч</Td>
                        </Tr>
                        <Tr>
                            <Td>Июль 2022</Td>
                            <Td isNumeric>123,293 кВч</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>

    </>
)
