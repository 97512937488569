import {Box, Menu, MenuButton, MenuItem, MenuList, Portal, useColorModeValue} from "@chakra-ui/react";
import {BsThreeDots} from "react-icons/bs";
import * as React from "react";
import {GoGear} from "react-icons/go";

export const ColumnsMenu = (props: any) => {

    // let editMenuIsOpenColors = useColorModeValue("#333", "#ddd")
    // let editMenuIsCloseColors = useColorModeValue('#777', '#777')

    return (
        <Box zIndex={20}>
            <Menu
                isLazy
                // size={'xs'}
            >
                {({isOpen}) => (
                    <>
                        <MenuButton>
                            <GoGear size={20} color={isOpen ? props.isOpenColors : props.isCloseColors}/>
                        </MenuButton>
                        <Portal>
                            <MenuList>
                                <MenuItem
                                    // icon={<RepeatIcon/>}
                                    style={{fontSize: "13px"}}
                                >
                                    Обновить статус
                                </MenuItem>
                                <MenuItem
                                    style={{fontSize: "13px"}}
                                >
                                    Удалить из списка
                                </MenuItem>
                                <MenuItem
                                    style={{fontSize: "13px"}}
                                >
                                    Редактировать
                                </MenuItem>
                            </MenuList>
                        </Portal>
                    </>
                )}
            </Menu>
        </Box>
    )
}
