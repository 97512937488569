import * as React from "react"
import {
    ChakraProvider,
    extendTheme,
} from "@chakra-ui/react"
import './index.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {NotFound} from "./components/NotFound/NotFound";
import {Signin} from "./components/Signin/Signin";
import {CounterProvider} from "./store/userStore";
import {Main} from "./Main";
import {Dashboard} from "./Dashboard";
import {theme} from '@chakra-ui/pro-theme'
import {Investments} from "./Investments";
import {Settings} from "./components/Settings/Settings";
import {Analitycs} from "./Analitycs";
import {Pools} from "./components/Pools/Pools";
import {Devices} from "./components/Devices/Devices";
import {Places} from "./Places";
import {SigninByClick} from "./components/Signin/SigninByClick";
import {Withdrawal} from "./components/Withdrawal/Withdrawal";
import {Redirect} from "./Redirect";
import {Tickets} from "./components/Tickets/Tickets";
import {Manager} from "./components/Manager/Manager";
import {ManagerDevicesPage} from "./components/Manager/Devices/ManagerDevicesPage";
import {ManagerUsersPage} from "./components/Manager/ManagerUsers/ManagerUsersPage";
import {ManagerIpAddressesPage} from "./components/Manager/Devices/ManagerIpAddressesPage";
import {AgentsPage} from "./components/Manager/Agents/AgentsPage";
import {ManagerDevicePage} from "./components/Manager/Devices/ManagerDevicePage";


// const myTheme = extendTheme(
//     {
//         colors: {
//             brand: {
//                 100: "#a041ff",
//                 // ...
//                 900: "#8000FF",
//             },
//         },
//     })

const myTheme = extendTheme(
    {
        colors: {
            ...theme.colors,
            brand: theme.colors.purple,
        },
    },
    theme,
)

export function App() {
    // let location = useLocation();
    // React.useEffect(() => {
    //     console.log(location.pathname)
    //     // ga.send(["pageview", location.pathname]);
    // }, [location]);

    return (
        <Router>
            <CounterProvider>
                <ChakraProvider theme={myTheme}>
                    <Routes>
                        <Route element={<Manager/>}>
                            <Route path="/manager/:placeId/*" element={<Dashboard/>}/>
                            <Route path="/manager/:placeId/agents" element={<AgentsPage/>}/>
                            <Route path="/manager/:placeId/devices/devices-list" element={<ManagerDevicesPage/>}/>
                            <Route path="/manager/:placeId/devices/devices-list/:deviceId" element={<ManagerDevicePage/>}/>
                            <Route path="/manager/:placeId/devices/devices-list/:deviceId/*" element={<ManagerDevicePage/>}/>
                            <Route path="/manager/:placeId/devices/ip_addresses" element={<ManagerIpAddressesPage/>}/>
                            <Route path="/manager/:placeId/users" element={<ManagerUsersPage/>}/>
                        </Route>
                        <Route element={<Main/>}>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/investments" element={<Investments/>}/>
                            <Route path="/devices" element={<Devices/>}/>
                            <Route path="/devices/:deviceId" element={<Devices/>}/>
                            <Route path="/pools" element={<Pools/>}/>
                            <Route path="/places" element={<Places/>}/>
                            <Route path="/analytics" element={<Analitycs/>}/>
                            {/*<Route path="/support" element={<Footer/>}/>*/}
                            <Route path="/tickets" element={<Tickets/>}/>
                            <Route path="/tickets/withdrawal" element={<Withdrawal/>}/>
                            <Route path="/tickets/new" element={<Withdrawal/>}/>
                            <Route path="/tickets/:ticketId" element={<Tickets/>}/>
                            <Route path="/settings" element={<Settings/>}/>
                        </Route>
                        <Route path="/" element={<Redirect/>}/>
                        <Route path="/signin" element={<Signin/>}/>
                        <Route path="/signin-by-click" element={<SigninByClick/>}/>
                        {/*<Route path="/first-signin" element={<FirstSignin/>}/>*/}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </ChakraProvider>
            </CounterProvider>
        </Router>
    )
}
