import {Box, Flex, FlexProps, HStack, Img, Skeleton, Stack, useColorModeValue, useMenuButton, VStack} from '@chakra-ui/react'
import * as React from 'react'
import {HiSelector} from 'react-icons/hi'
import {TiLocation} from "react-icons/all";

export const PlaceSwitcherButton = (props: any) => {
    const buttonProps = useMenuButton(props)

    // const {currentUser, setCurrentUser, currentUsers} = useCounterContext();


    // let userStr = localStorage.getItem("user")
    // let user = undefined
    // if (userStr !== null && userStr !== "") {
    //     user = JSON.parse(userStr)
    // }
    return (
        <Flex
            {...buttonProps}
            w="full"
            display="flex"
            alignItems="flex-start"
            rounded="lg"
            // bg="gray.700"
            m="-2px 4px -2px -2px"
            px="3"
            py="5px"
            fontSize="sm"
            userSelect="none"
            cursor="pointer"
            // outline="0"
            // transition="all 0.2s"
            // backgroundColor={useColorModeValue('gray.700', 'gray.100')}
            _active={{bg: useColorModeValue('gray.100', 'gray.700')}}
            _focus={{shadow: 'outline', backgroundColor: useColorModeValue('gray.100', 'gray.700')}}
        >
            <HStack width={"100%"}
                // justify="space-between"
            >
                <TiLocation size={20}/>
                <Box fontSize="15px" color={useColorModeValue('gray.900', 'gray.200')}>
                    {props.currentplace.title}
                </Box>
                <Box fontSize="lg" color="gray.400">
                    <HiSelector/>
                </Box>
            </HStack>
        </Flex>
    )
}
