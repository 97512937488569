import {IconType} from "react-icons/lib";
import {AllUserRoles, AllWithoutPlaceOwnerRoles, Role} from "../../store/userHelper";
import {FaBookReader, FaCoins, FaGripHorizontal, FaLock, FaMap, FaStar, FaStream, FaUserShield} from "react-icons/fa";
import {FiActivity, FiBarChart2, FiCpu, FiMessageCircle, FiMonitor, FiUsers} from "react-icons/fi";
import {RiDashboardLine, RiEarthFill, RiStackFill, RiStackshareLine} from "react-icons/ri";
import {BiDevices, FaRegStar, MdDeviceHub} from "react-icons/all";
import {VscDashboard} from "react-icons/vsc";


interface MenuElement {
    name: string
    link: string
    icon: IconType
    // roles: Role[]
    submenu?: MenuElement[]
}

export const deviceMenuItems: MenuElement[] = [
    {
        name: "Обзор",
        link: "",
        icon: RiDashboardLine,
    },
    {
        name: "Журнал",
        link: "/events",
        icon: FaBookReader,
    },
    {
        name: "Мониторинг",
        link: "/monitoring",
        icon: FiMonitor,
    },
    {
        name: "Потребление",
        link: "/consumption",
        icon: VscDashboard,
    },
    {
        name: "Пулы",
        link: "/pools",
        icon: RiEarthFill,
    },
    {
        name: "Prohash-Access",
        link: "/access",
        icon: FaUserShield,
    },
]


export const menuItems: MenuElement[] = [
    {
        name: "Обзор",
        link: "/overview",
        icon: RiDashboardLine,
    },
    {
        name: "Устройства",
        link: "/devices",
        icon: BiDevices,
        submenu: [
            {
                name: "Группы размещений",
                link: "/groups",
                icon: RiStackFill,
            },
            {
                name: "Список устройств",
                link: "/devices-list",
                icon: BiDevices,
            },
            {
                name: "IP адреса и порты",
                link: "/ip_addresses",
                icon: RiStackshareLine,
            },
            {
                name: "Операции",
                link: "/activity",
                icon: FiActivity,
            },
        ]
    },
    {
        name: "Пользователи",
        link: "/users",
        icon: FiUsers,
        // submenu: [
        //     {
        //         name: "Список пользователей",
        //         link: "",
        //         icon: FiUsers,
        //     },
        // ]
    },
    {
        name: "Обращения",
        link: "/tickets",
        icon: FiMessageCircle,
        submenu: [
            {
                name: "Обзор",
                link: "",
                icon: RiDashboardLine,
            },
            {
                name: "Список обращений",
                link: "/tickets-list",
                icon: BiDevices,
            },
            {
                name: "Мониторинг",
                link: "/activity",
                icon: FiActivity,
            },
        ]
    },
    {
        name: "Сервисы",
        link: "/services",
        icon: FaGripHorizontal,
    },
    {
        name: "Агенты",
        link: "/agents",
        icon: MdDeviceHub,
    },
    {
        name: "Избранное",
        link: "/favourites",
        icon: FaStar,
    }
]
