import {
    Box,
    BoxProps,
    Button,
    Divider,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input, Link,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {useCounterContext} from "../../store/userStore";
import {useState} from "react";

export function SettingsProfile(props: BoxProps) {
    const {currentUser, saveUser} = useCounterContext();

    const [inputForm, setInputForm] = useState({});

    function setInputFirstname(value: string) {
        setInputForm({...inputForm, firstname: value});
    }


    const submitHandler = (e: any) => {
        e.preventDefault();
        // console.log(inputForm);

        let user = currentUser
        if (user) {
            // @ts-ignore
            // user.username = inputForm.username
            let newUser = {...user, firstname: inputForm.firstname}
            saveUser(newUser);
        }

        // wsSendJson(
        //     {
        //         type: 'signin',
        //         payload: inputForm
        //     }
        // )

    }

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            {...props}
        >
            <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                <Stack spacing="6" direction={{base: 'column', md: 'row'}}>
                    <FormControl id="firstName">
                        <FormLabel>Ваше имя или псевдоним</FormLabel>
                        <Input defaultValue={currentUser && currentUser.firstname}
                               onChange={(e) => {
                                   setInputFirstname(e.target.value);
                               }}
                               placeholder={"Макото Акияма"}/>
                    </FormControl>
                </Stack>
                {/*<Stack spacing="6" direction={{base: 'column', md: 'row'}}>*/}
                {/*    <FormControl id="firstName">*/}
                {/*        <FormLabel>Telegram ID</FormLabel>*/}
                {/*        <Input defaultValue={currentUser && currentUser.username}*/}
                {/*               onChange={(e) => {*/}
                {/*                   setInputFirstname(e.target.value);*/}
                {/*               }}*/}
                {/*               placeholder={"Пользователь"}/>*/}
                {/*        <FormHelperText color="subtle">*/}
                {/*            Для получения уведомлений подключитесь к{' '}*/}
                {/*            <Link href="https://t.me/ProHashCloudBot" target={"_blank"}>*/}
                {/*                @ProHashCloudBot*/}
                {/*            </Link>*/}
                {/*            {' '}затем введите ваш ID в это поле*/}
                {/*        </FormHelperText>*/}
                {/*    </FormControl>*/}
                {/*</Stack>*/}
            </Stack>
            <Divider/>

            <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                <Button type="submit"
                        onClick={submitHandler}
                        variant="primary">
                    Обновить
                </Button>
            </Flex>
        </Box>
    )
}
