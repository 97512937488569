
let formatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'RUB',
    maximumFractionDigits: 2,
});

export function format(num: number) {
    return formatter.format(num / 100);
}

export function format_raw(num: number) {
    return formatter.format(num);
}

export function format_satoshi(num: number) {
    return naiveRound(num / 100000000, 8);
}

export function format_btc(num: number) {
    return naiveRound(num, 8);
}

function naiveRound(num: number, decimalPlaces = 0) {
    let p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
}
