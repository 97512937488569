export const getUserFromLocal = () => {
    const u = localStorage.getItem("user")
    if (u !== null && u !== "") {
        return JSON.parse(u)
    }
    return undefined
}

export const getToken = () => {
    let u = getUserFromLocal()
    if (u !== undefined) {
        return u.token
    }
    return undefined
}

export const axiosConfig = () => {
    return {
        headers: {"X-Auth-Token": getToken()}
    }
}
