import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack, Input, Select,
    SimpleGrid, Skeleton,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tooltip, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from './components/Cards/Stat'
import {FiDownloadCloud} from "react-icons/fi";
import {Card} from "./components/Cards/Card";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {useCounterContext} from "./store/userStore";
import {format, format_btc, format_raw, format_satoshi} from "./utils/format";
import moment from 'moment';
import {CurrencyRub, CurrencyTypeFiat, OutcomeDetail, TypeIncome, TypeOutcome, TypeUnknown} from "./store/investmentsStore";
import {RadioButton, RadioButtonGroup} from "./RadioButtonGroup";
import {useState} from "react";


function TooltipData(props: any) {
    return (
        <TableContainer whiteSpace={'normal'}>
            <Table variant='simple'
                   bg={useColorModeValue('gray.300', 'gray.300')}
                // w={"500px"}
                   size='' mb={2}>
                <Tbody>
                    {props.data.map((detail: OutcomeDetail, index: number) => (
                        <Tr key={index}>
                            <Td
                                //w={"200px"}
                                pr={5}
                                whiteSpace={'normal'}>
                                {detail.type}
                            </Td>
                            <Td
                                valign={'top'}
                                isNumeric={true}
                                // w={"100px"}
                            >
                                {props.type === "fiat" ?
                                    `${format_raw(detail.value / 100)} руб.` :
                                    `${format_satoshi(detail.value)} BTC`}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export function Investments() {

    let {
        investmentsStore,
        realtimeRatesStore,
    } = useCounterContext();

    const {wsSendJson} = useCounterContext();

    let statsOut: any[] = [undefined, undefined, undefined]
    if (investmentsStore !== undefined && investmentsStore.dashboard !== undefined) {
        statsOut = [
            {
                label: 'Поступления руб.',
                value: `${format(investmentsStore.dashboard.sum_income)} `,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {
                label: 'Расходы руб.',
                description: investmentsStore.dashboard.sum_outcome > 0 ?
                    <TooltipData type={'fiat'} data={investmentsStore.dashboard.sum_outcome_details}/> : undefined,
                value: `${format(investmentsStore.dashboard.sum_outcome)}`,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {
                label: 'Остаток руб.',
                value: `${format(investmentsStore.dashboard.sum_balance)}`,
                delta: {value: '0', isUpwardsTrend: false}
            },
        ]
    }

    let statsIn: any[] = [undefined, undefined, undefined]
    if (investmentsStore !== undefined && investmentsStore.dashboard !== undefined) {
        statsIn = [
            {
                label: 'Пополнение BTC',
                value: `${format_satoshi(investmentsStore.dashboard.reward_income)} `,
                delta: {
                    value: "0",
                    // value: investmentsStore.dashboard.reward_income > 0 && realtimeRatesStore ?
                    //     `$${format_raw((realtimeRatesStore.btc * investmentsStore.dashboard.reward_income / 100000000))}`
                    //     // `$${(realtimeRatesStore.btc * investmentsStore.dashboard.reward_income / 100000000).toFixed(2)}`
                    //     : '0',
                    isUpwardsTrend: true
                }
            },
            {
                label: 'Списание BTC',
                description: investmentsStore.dashboard.reward_outcome > 0 ?
                    <TooltipData type={'crypto'} data={investmentsStore.dashboard.reward_outcome_details}/> : undefined,
                value: `${format_satoshi(investmentsStore.dashboard.reward_outcome)} `,
                delta: {
                    value: "0",
                    //investmentsStore.dashboard.reward_outcome > 0 && realtimeRatesStore ?
                    //  `$${format_raw((realtimeRatesStore.btc * investmentsStore.dashboard.reward_outcome / 100000000))}`
                    //: '0',
                    isUpwardsTrend: undefined
                }
            },
            {
                label: 'Баланс BTC',
                value: `${format_satoshi(investmentsStore.dashboard.reward_balance)} `,
                delta: {
                    value: investmentsStore.dashboard.reward_balance > 0 && realtimeRatesStore ?
                        `$${format_raw((realtimeRatesStore.btc * investmentsStore.dashboard.reward_balance / 100000000))}`
                        // `$${(realtimeRatesStore.btc * investmentsStore.dashboard.reward_balance / 100000000).toFixed(2)}`
                        : '0', isUpwardsTrend: true
                }
            },
        ]
    }

    const [currentFilter, setCurrentFilter] = useState(0);

    function changeFilter(value: string) {
        if (value === 'income') {
            setCurrentFilter(TypeIncome);
            return
        }
        if (value === 'outcome') {
            setCurrentFilter(TypeOutcome);
            return
        }
        setCurrentFilter(TypeUnknown)
    }

    const limit = 50;

    function prevPage() {
        let skip = 0;
        if (investmentsStore && investmentsStore.dashboard) {
            skip = investmentsStore.dashboard.transactions_skip - limit
            if (skip < 0) {
                skip = 0
            }
        }
        wsSendJson(
            {
                type: 'investments',
                payload: {
                    limit: limit,
                    skip: skip,
                },
            },
        )
    }

    function nextPage() {
        let skip = 0;
        if (investmentsStore && investmentsStore.dashboard) {
            skip = investmentsStore.dashboard.transactions_skip + limit
        }
        wsSendJson(
            {
                type: 'investments',
                payload: {
                    limit: limit,
                    skip: skip,
                },
            },
        )
    }

    return (
        <>
            <Stack
                spacing="4"
                direction={{base: 'column', lg: 'row'}}
                justify="space-between"
                align={{base: 'start', lg: 'center'}}
            >
                <Stack spacing="1">
                    <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                        Инвестиции,
                    </Heading>
                    <Text color="muted">история движения средств</Text>
                </Stack>
                <HStack spacing="3">
                    <Tooltip
                        hasArrow
                        shouldWrapChildren
                        placement="left" label='Скоро!' bg='gray.300' color='black'>
                        <Input
                            disabled={true}
                            // size="md"
                            // backgroundColor="#ffffff"
                            onChange={(e) => {
                                console.log(e.target.value)
                            }}
                            value={"2023-04"}
                            type="month"
                        />
                    </Tooltip>

                    <Tooltip
                        hasArrow
                        shouldWrapChildren
                        placement="left" label='Скоро!' bg='gray.300' color='black'>
                        <Button
                            disabled={true}
                            variant="secondary"
                            leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>
                            Загрузить отчет
                        </Button>
                    </Tooltip>
                    {/*<Button variant="primary">Create</Button>*/}
                </HStack>
            </Stack>
            <Stack spacing={{base: '5', lg: '6'}}>
                <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                    {statsOut.map((stat, id) => {
                        if (stat === undefined) {
                            return (
                                <Stack key={id}>
                                    <Skeleton height='20px'/>
                                    <Skeleton height='20px'/>
                                    <Skeleton height='20px'/>
                                </Stack>
                            )
                        }
                        return (
                            <Stat key={id} {...stat} />
                        )
                    })}
                </SimpleGrid>
            </Stack>
            <Stack spacing={{base: '5', lg: '6'}}>
                <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                    {statsIn.map((stat, id) => {
                        if (stat === undefined) {
                            return (
                                <Stack key={id}>
                                    <Skeleton height='20px'/>
                                    <Skeleton height='20px'/>
                                    <Skeleton height='20px'/>
                                </Stack>
                            )
                        }
                        return (
                            <Stat key={id} {...stat} />
                        )
                    })}
                </SimpleGrid>
            </Stack>

            <HStack justify="space-between">
                {/*
                <Select placeholder='Все пулы' w={300}>

                    <option value='all'>Все пулы</option>

                    <option value='62b78eed36711b939dad216a'>Радиан Пул 062022</option>
                    <option value='6366acfbabc72e031b8fecb7'>Усть-Ордынский 102022</option>
                    <option value='643cfe4136711b44904e6da5'>Радиан Пул 122022</option>
                    <option value='64428db336711b808dadde5f'>Радиан Пул 042024</option>
                </Select>
                 */}
                <Box>
                    <Stack spacing="0">
                        <RadioButtonGroup onChange={changeFilter} defaultValue="all" size={'sm'}>
                            <RadioButton value="all">Все операции</RadioButton>
                            <RadioButton value="income">Пополнение</RadioButton>
                            <RadioButton value="outcome">Списание</RadioButton>
                        </RadioButtonGroup>
                    </Stack>
                </Box>
            </HStack>

            <Card minH="sm" p={4}>

                <HStack pb={4} justifyContent={"center"}>
                    <Button
                        disabled={investmentsStore?.dashboard?.transactions_skip === 0}
                        onClick={() => {
                            prevPage()
                        }}>Назад</Button>
                    <Button onClick={() => {
                        nextPage()
                    }}>Далее</Button>
                </HStack>

                <TableContainer>
                    <Table variant='striped'>
                        {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                        <Thead>
                            <Tr>
                                <Th>Дата</Th>
                                <Th>Операция</Th>
                                <Th>Описание</Th>
                                {/*<Th>Тип валюты</Th>*/}
                                <Th>Валюта</Th>
                                <Th isNumeric>Сумма</Th>
                                {/*<Th isNumeric>Комиссия</Th>*/}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {investmentsStore !== undefined && investmentsStore.dashboard !== undefined &&
                                investmentsStore.dashboard.transactions.map((t, id) => {
                                    if (currentFilter !== TypeUnknown && currentFilter !== t.type) {
                                        return <></>
                                    }
                                    return (
                                        <Tr key={id}>
                                            <Td>{moment(t.created).format('DD.MM.YYYY, HH:mm:ss')}</Td>
                                            <Td>
                                                <Badge size="sm" colorScheme={t.type === TypeIncome ? "green" : "red"}>
                                                    {t.type === TypeIncome ? "Пополнение" : "Списание"}
                                                </Badge>
                                            </Td>
                                            <Td>
                                                {t.description}
                                                {t.comment && t.comment !== t.description && (
                                                    <Box pt={2}><Badge colorScheme='yellow'>{t.comment}</Badge></Box>
                                                )}
                                            </Td>
                                            <Td>
                                                {/*{t.currency_type === CurrencyTypeFiat ? "Фиат" : "Криптовалюта"}, */}
                                                {t.currency === CurrencyRub ? "Рубли" : "BTC"}</Td>

                                            <Td isNumeric>
                                                {t.currency === CurrencyRub ?
                                                    format(t.value)
                                                    : `${format_satoshi(t.value)} ($${format_raw((t.currency_price / 100) * (t.value / 100000000))})`}
                                            </Td>
                                            {/*<Td isNumeric>*/}
                                            {/*    {t.currency === CurrencyRub ?*/}
                                            {/*        format(t.fee)*/}
                                            {/*        : `${format_satoshi(t.fee)} ($${format_raw((t.currency_price / 100) * (t.fee / 100000000))})`}*/}
                                            {/*</Td>*/}
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                    <HStack pb={4} justifyContent={"center"}>
                        <Button
                            disabled={investmentsStore?.dashboard?.transactions_skip === 0}
                            onClick={() => {
                                prevPage()
                            }}>Назад</Button>
                        <Button onClick={() => {
                            nextPage()
                        }}>Далее</Button>
                    </HStack>
                </TableContainer>
            </Card>

        </>
    )
}
