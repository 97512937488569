import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack,
    SimpleGrid,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Stat} from './components/Cards/Stat'
import {FiDownloadCloud} from "react-icons/fi";
import {Card} from "./components/Cards/Card";
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'


const stats = [
    {label: 'Внесено', value: '3,500,000', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'В работе', value: '2,923,796', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'Баланс', value: '600,323', delta: {value: '0', isUpwardsTrend: false}},
]


export const Analitycs = () => (
    <>

        <Stack
            spacing="4"
            direction={{base: 'column', lg: 'row'}}
            justify="space-between"
            align={{base: 'start', lg: 'center'}}
        >
            <Stack spacing="1">
                <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    Аналитика,
                </Heading>
                <Text color="muted">история движения средств</Text>
            </Stack>
            <HStack spacing="3">
                <Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem"/>}>
                    Загрузить отчет
                </Button>
                {/*<Button variant="primary">Create</Button>*/}
            </HStack>
        </Stack>
        <Stack spacing={{base: '5', lg: '6'}}>
            <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                {stats.map((stat, id) => (
                    <Stat key={id} {...stat} />
                ))}
            </SimpleGrid>
        </Stack>
        <Card minH="sm" p={4}>
            <TableContainer>
                <Table variant='striped'>
                    {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                    <Thead>
                        <Tr>
                            <Th>Дата</Th>
                            <Th>Операция</Th>
                            <Th isNumeric>Сумма</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>08.06.2022</Td>
                            <Td><Badge size="sm" colorScheme="green">Приход</Badge></Td>
                            <Td isNumeric>3,500,000</Td>
                        </Tr>
                        <Tr>
                            <Td>10.06.2022</Td>
                            <Td><Badge size="sm" colorScheme="red">Расход</Badge></Td>
                            <Td isNumeric>2,923,796</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>

    </>
)
