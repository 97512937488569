import {
    ChakraProvider,
    extendTheme,
    theme as defaultTheme,
    Flex,
    useDisclosure, ComponentStyleConfig,
} from '@chakra-ui/react'
import * as React from 'react'
import {ManagerContent} from './ManagerContent'
import {MenuL1, NavLink} from './MenuL1'
import colors from "../Theme/colors";
import {MenuL2} from "./MenuL2";
import {QueryClientProvider, QueryClient} from "@tanstack/react-query";
import {StyleConfig} from "@chakra-ui/theme-tools";
import {Button} from "./Theme/Button";
import {Table} from "./Theme/Table";
import {ManagerProvider} from '../../store/managerStore';
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();


// https://medium.com/meliopayments/why-we-chose-chakra-ui-for-our-design-system-part-2-36865e5ec2be
const myTheme = extendTheme(
    {
        // styles: {
        //     global: (props: any) => ({
        //         'html, body': {
        //             bg: mode('white', '#202023')(props)
        //         }
        //     }),
        // },
        fonts: {
            heading: `'Roboto', sans-serif`,
            body: `'Roboto', sans-serif`,
        },

        components: {
            // Button,
            Table,
        },

    }, {
        config: defaultTheme.config,
        direction: defaultTheme.direction,
        transition: defaultTheme.transition,
        zIndices: defaultTheme.zIndices,
        colors: colors,
        // styles: {
        //     global: (props: any) => ({
        //         'html, body': {
        //             bg: mode('white', '#202023')(props)
        //         }
        //         //     'html, body': {
        //         //         // fontSize: 'sm',
        //         //         // color: props.colorMode === 'dark' ? 'red' : 'gray.600',
        //         //         backgroundColor: props.colorMode === 'dark' ? 'gray.900' : 'white',
        //         //         // lineHeight: 'tall',
        //         //     },
        //         //     a: {
        //         //         // color: props.colorMode === 'dark' ? 'gray.50' : 'teal.500',
        //         //     },
        //     }),
        // },
        // fonts: {
        //     body: "system-ui, sans-serif",
        //     // heading: "Roboto, serif",
        //     mono: "Menlo, monospace",
        // },

        // theme,
        // components: (props: any) => ({
        //     Text: {
        //         variants: {
        //             mono: {
        //                 fontFamily: 'Roboto Mono',
        //             },
        //             sec: {
        //                 color: props.colorMode === 'dark' ? '#fbfbfb' : '#333333',
        //             },
        //             tri: {
        //                 color: props.colorMode === 'dark' ? 'brand.one' : 'brand.three',
        //             },
        //         },
        //     }
        // })
    })


export const Manager = () => {
    const [sidebarIsScrolled, setSidebarIsScrolled] = React.useState(false)
    const [mainIsScrolled, setmMainIsScrolled] = React.useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()

    // console.log(submenuFound);

    return (
        <ManagerProvider>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={myTheme}>
                    <Flex
                        height="100vh"
                    >
                        <MenuL1/>
                        <MenuL2 isOpen={isOpen} onClose={onClose} sidebarIsScrolled={sidebarIsScrolled}/>
                        <ManagerContent/>
                    </Flex>
                    <ReactQueryDevtools initialIsOpen/>
                </ChakraProvider>
            </QueryClientProvider>
        </ManagerProvider>
    )
}
