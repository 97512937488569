import {Box, Menu, MenuButton, MenuItem, MenuList, Portal, useColorModeValue} from "@chakra-ui/react";
import {BsThreeDots} from "react-icons/bs";
import * as React from "react";

export const EditMenu = (props: any) => {

    // console.log(JSON.stringify(props.id));
    // console.log(props.id.original.ip_address);

    return (
        <Box>
            <Menu
                isLazy
                // size={'xs'}
            >
                {({isOpen}) => (
                    <>
                        <MenuButton>
                            <BsThreeDots
                                size={20}
                                // color={isOpen ? props.isOpenColors : props.isCloseColors}
                                color={isOpen ? props.isOpenColors : props.isCloseColors}
                            />
                        </MenuButton>
                        <Portal>
                            <MenuList>
                                <MenuItem
                                    // icon={<RepeatIcon/>}
                                    style={{fontSize: "13px"}}
                                >
                                    Обновить статус
                                </MenuItem>
                                <MenuItem
                                    style={{fontSize: "13px"}}
                                >
                                    Удалить из списка
                                </MenuItem>
                                <MenuItem
                                    style={{fontSize: "13px"}}
                                >
                                    Редактировать
                                </MenuItem>
                                <MenuItem
                                    style={{fontSize: "13px"}}
                                    onClick={() => {
                                        // http://s10x112x5x92.radian.locations.vpn.prohash.cloud/
                                        let url = "http://s" + props.id.original.ip_address.replaceAll(".", "x")
                                            + ".radian.locations.vpn.prohash.cloud/";
                                        let win = window.open(url, '_blank');
                                        win?.focus();
                                        props.onClick(props.id.original.ip_address);
                                    }}
                                >
                                    Страница устройства
                                </MenuItem>
                            </MenuList>
                        </Portal>
                    </>
                )}
            </Menu>
        </Box>
    )
}
