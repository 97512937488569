import {Box, HStack, useColorModeValue, Text, useClipboard} from "@chakra-ui/react";
import * as React from "react";
import {FiCopy} from "react-icons/fi";


export const CopyToClipboard = (props: any) => {
    const {hasCopied, onCopy} = useClipboard(props.children)

    return (
        <HStack
            onClick={onCopy}
            style={{cursor: 'pointer'}}
        ><Text>{props.children}</Text><FiCopy color={useColorModeValue(hasCopied ? '#777' : '#bbb', hasCopied ? '#999' : '#666',)}/></HStack>
    )
}

export const CopyToClipboardValue = (props: any) => {
    const {hasCopied, onCopy} = useClipboard(props.value)

    return (
        <HStack
            onClick={onCopy}
            style={{cursor: 'pointer'}}
        ><FiCopy color={useColorModeValue(hasCopied ? '#777' : '#bbb', hasCopied ? '#999' : '#666',)}/></HStack>
    )
}
