import {
    Box,
} from '@chakra-ui/react'
import * as React from 'react'
import {Header} from "../Header/Header";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_ADDRESS} from "../../../Config";
import {axiosConfig} from "../../../store/localStore";
import {useParams} from "react-router-dom";
import {TableWithFilter} from "../Table/TableWithFilter";
import {useCounterContext} from "../../../store/userStore";
import {useManagerContext} from "../../../store/managerStore";
import {useEffect} from "react";

require('moment/locale/ru');


declare interface Stats {
    boards_temp: number[];
    chips_temp: number[][];
}

declare interface HashrateStats {
    hashrate_now: number;
    hash_rate_15m: number;
    hash_rate_1h: number;
    hash_rate_24h: number;
    hash_rate_24h_sum: number;
}

declare interface DevBoardStats {
    temperature: number;
    factory_hashrate: number;
    hashrate_average: number;
    hashrate_1m: number;
    hashrate_5m: number;
    hashrate_15m: number;
    accepted: number;
    rejected: number;
    serial_number: string;
}

export declare interface Device {
    device_type: number;
    device_name: string;
    device_brand: string;
    device_model: string;
    device_serial: string;
    device_hash_rate: number;
    internal_ids: string[];
    place_id: string;
    pool_id: string;
    miner_pool_id: number;
    owner_user_id: string;
    worker_name: string;
    local_address: string;
    prev_worker_name: string;
    prev_local_address: string;
    is_online: boolean;
    online_at: string;
    offline_at: string;
    last_share: string;
    last_activity: string;
    last_activity_list: string[];
    comment: string;
    deleted: boolean;
    stats: Stats;
    hashrate_stats: HashrateStats;
    dev_boards_stats: DevBoardStats[];
}

type DeviceResp = {
    data: Device[];
}

export const ManagerDevicesPage = () => {
    let {placeId} = useParams();

    async function fetchSimpleDevices(): Promise<DeviceResp> {
        const res = await axios.get(`${API_ADDRESS}/v1/manager/places/${placeId}/devices`, axiosConfig())
        return res.data
    }

    const {
        isLoading, error, data,
        // isFetching, isFetched, isFetchedAfterMount
    } = useQuery(["devices", placeId], fetchSimpleDevices);

    // useEffect(() => {
    //     setPageSettings({title: "asda"})
    // }, [data])

    if (isLoading) return <Box>Loading...</Box>;
    if (error && error instanceof Error) return <Box>An error has occurred {error.message}</Box>;

    const columns = [
        {
            id: "local_address",
            header: "IP адрес",
        },
        {
            id: "last_activity",
            header: "Активность",
        },
        {
            id: "worker_name",
            header: "Имя воркера",
        },
        // {
        //     id: "device_name",
        //     header: "Имя устройства",
        // },
        {
            id: "device_brand",
            header: "Бренд",
        },
        {
            id: "device_model",
            header: "Модель",
        },
        // {
        //     id: "device_serial",
        //     header: "Серийный номер",
        // },
        {
            id: "device_hash_rate",
            header: "Хэшрейт",
        },
        {
            id: "hashrate_stats.hash_rate_24h",
            header: "Хэшрейт за 24 часа",
        },
        {
            id: "hashrate_stats.hash_rate_1h",
            header: "Хэшрейт за 1 час",
        },
    ]

    return (
        <Box>
            {/*
            <Header>Список устройств</Header>
            */}
            <Box pt={5}>
                <TableWithFilter
                    columns={columns}
                    data={data}
                    linkPrefix={`/manager/${placeId}/devices/devices-list`}
                />
            </Box>
        </Box>
    )
}




