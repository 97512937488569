import {
    As,
    Box,
    Button,
    ButtonProps, CircularProgress,
    Flex,
    FlexProps,
    HStack,
    Icon,
    Link,
    LinkProps, Spacer,
    Stack,
    Text,
    TextProps, Tooltip, useColorModeValue,
    useColorModeValue as mode, VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiArrowUpRight,
    FiX,
} from 'react-icons/fi'
import {ColumnHeader, ColumnIconButton} from './Column'
import {Link as A, useLocation, useNavigate, useParams} from "react-router-dom";
import {Logo} from "../Logo";
import {ColorModeSwitcher} from "../Navigation/ColorModeSwitcher";
import {AccountSwitcher} from "../AccountSwitcher/AccountSwitcher";
import {PlaceSwitcher} from "../PlaceSwitcher/PlaceSwitcher";
import {useCounterContext} from "../../store/userStore";
import {IsAdmin} from "../../store/userHelper";
import {menuItems} from "./manager_menu";
import {LogoManager} from "../LogoManager";
import {useIsFetching} from "@tanstack/react-query";

interface NavbarProps extends FlexProps {
    onClose?: () => void
}

export const MenuL1 = (props: NavbarProps) => {
    let location = useLocation();
    let navigate = useNavigate();
    let {placeId} = useParams();
    const {currentUser} = useCounterContext();

    const isFetching = useIsFetching()

    let isAdmin = false
    if (currentUser !== undefined && IsAdmin(currentUser)) {
        isAdmin = true
    }

    // height={`${props.height}px`}
    return (
        <Box
            height="full"
            // width={{md: '14rem', xl: '14rem'}}
            width={'14rem'}
            display={{base: 'none', lg: 'initial'}}
            overflowY="auto"
            // borderRightWidth="1px"
        >
            {isFetching ?
                <Box
                    top={"0"}
                    left={"5px"}
                    position={"absolute"}>
                    <CircularProgress
                        color='purple.500'
                        size={5}
                        isIndeterminate/>
                </Box>
                : null}
            <Flex as="nav"
                  bgGradient={useColorModeValue("linear(custom.bg 0%, white 15%, white 30%)", "")}
                  height="full" direction="column" justify="space-between" {...props}>
                <Stack spacing="3">
                    <ColumnHeader>
                        <HStack spacing="3">
                            <ColumnIconButton
                                onClick={props.onClose}
                                aria-label="Close navigation"
                                icon={<FiX/>}
                                display={{base: 'inline-flex', lg: 'none'}}
                            />
                            {/*<Text fontWeight="bold" fontSize="sm" lineHeight="1.25rem">*/}
                            {/*  Jenny Campbell*/}
                            {/*</Text>*/}
                            <HStack spacing="30" justify="space-between">
                                <Box pr={"10"} pt={3}>
                                    <A to={"/"}>
                                        <LogoManager color={useColorModeValue("#444", "#999")}
                                                     color2={useColorModeValue("#282828", "#d3d3d3")}/>
                                    </A>
                                </Box>
                                <ColorModeSwitcher/>
                            </HStack>
                        </HStack>
                    </ColumnHeader>

                    <Box px={3} pt={3} pb={5} borderBottomWidth="1px">
                        <PlaceSwitcher/>
                    </Box>

                    <Stack px="0" pt={0} spacing="6">
                        <Stack spacing="1">
                            {menuItems?.map(item => {

                                let link = `/manager/${placeId}${item.link}`
                                let active = location.pathname.startsWith(link)
                                if (link === "/") {
                                    active = location.pathname === link
                                }

                                return (
                                    <NavLink
                                        key={item.name}
                                        // label={item.name}
                                        icon={item.icon}
                                        aria-current={active ? "page" : undefined}
                                        onClick={() => {
                                            navigate(link)
                                        }}
                                        // disabled={item.link === ""}
                                    >
                                        {item.name}
                                    </NavLink>
                                )
                            })}
                        </Stack>
                        {/*<Stack spacing="3">*/}
                        {/*    <NavHeading>Радиан, Иркутск</NavHeading>*/}
                        {/*    <Stack spacing="1">*/}
                        {/*        <NavLink icon={FiBookmark}>BookMarks</NavLink>*/}
                        {/*        <NavLink icon={FiCamera}>Photography</NavLink>*/}
                        {/*        <NavLink icon={FiMessageCircle}>Chat</NavLink>*/}
                        {/*    </Stack>*/}
                        {/*</Stack>*/}
                        {/*<Stack spacing="3">*/}
                        {/*    <NavHeading>Projects</NavHeading>*/}
                        {/*    <Stack spacing="1">*/}
                        {/*        <NavLink icon={FiMic} isExternal>*/}
                        {/*            Podcasts*/}
                        {/*        </NavLink>*/}
                        {/*        <NavLink icon={FiFigma} isExternal>*/}
                        {/*            Figma Plugins*/}
                        {/*        </NavLink>*/}
                        {/*        <NavLink icon={FiShield}>Security Checklist</NavLink>*/}
                        {/*        <NavLink icon={FiMessageSquare}>Hacker News</NavLink>*/}
                        {/*        <NavLink icon={FiFilm}>Movie Guide</NavLink>*/}
                        {/*    </Stack>*/}
                        {/*</Stack>*/}
                        {/*<Stack spacing="3">*/}
                        {/*    <NavHeading>Online</NavHeading>*/}
                        {/*    <Stack spacing="1">*/}
                        {/*        <NavLink icon={FiTwitter} isExternal>*/}
                        {/*            Twitter*/}
                        {/*        </NavLink>*/}
                        {/*        <NavLink icon={FiGithub} isExternal>*/}
                        {/*            GitHub*/}
                        {/*        </NavLink>*/}
                        {/*    </Stack>*/}
                        {/*</Stack>*/}
                    </Stack>
                </Stack>
                <Box px={3} py={5} borderTopWidth="1px">
                    <AccountSwitcher/>
                </Box>

                {/*<Box borderTopWidth="1px">*/}
                {/*<NavButton>Sign In</NavButton>*/}
                {/*</Box>*/}
            </Flex>
        </Box>
    )
}

const NavButton = (props: ButtonProps) => (
    <Button
        width="full"
        borderRadius="0"
        variant="ghost"
        size="lg"
        fontSize="sm"
        _hover={{bg: mode('gray.100', 'gray.700')}}
        _active={{bg: mode('gray.200', 'gray.600')}}
        _focus={{boxShadow: 'none'}}
        _focusVisible={{boxShadow: 'outline'}}
        {...props}
    />
)

interface NavLinkProps extends LinkProps {
    icon: As
}

export const NavLink = (props: NavLinkProps) => {
    const {icon, ...linkProps} = props
    return (
        <Link
            px="5"
            py="1.5"
            borderRadius="0"
            _hover={{bg: mode('gray.100', 'gray.700')}}
            _activeLink={{
                bg: mode('custom.menuBgLight', 'gray.700'),
                color: mode('black', 'white'),
            }}
            {...linkProps}
        >
            <HStack justify="space-between">
                <HStack as="a" spacing="4">
                    <Icon as={icon}
                          color={useColorModeValue('custom.menuIconsLight',
                                                   'custom.menuIconsDark')}
                        // fontSize={"xl"}
                          fontSize={"lg"}
                    />
                    <Text as="span"
                          fontSize="13px"
                        // fontFamily={"Arial"}
                          fontWeight={'normal'}
                          lineHeight="1.5rem">
                        {props.children}
                    </Text>
                </HStack>
                {props.isExternal && (
                    <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')}/>
                )}
            </HStack>
        </Link>
    )
}

export const NavHeading = (props: TextProps) => (
    <Text
        as="h4"
        fontSize="xs"
        fontWeight="semibold"
        px="2"
        lineHeight="1.25"
        color={mode('gray.600', 'gray.400')}
        {...props}
    />
)
