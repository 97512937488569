import {
    Box,
    BoxProps,
    Button, Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Stack, StackDivider, Switch,
    useColorModeValue, Link,
} from '@chakra-ui/react'
import * as React from 'react'
import {useCounterContext} from "../../store/userStore";
import {useEffect, useState} from "react";
import {useClipboard} from '@chakra-ui/react'
import {FiDownloadCloud} from 'react-icons/fi';
import {FaTelegram, FaTelegramPlane} from "react-icons/all";
import _ from "lodash";


export function SettingsNotifications(props: BoxProps) {
    const {currentUser, currentUsers, setCurrentUser, wsSendJson} = useCounterContext();
    const {hasCopied, onCopy} = useClipboard(currentUser ? currentUser.telegram_secret : "")

    const [inputForm, setInputForm] = useState({});

    const switchNotification = (e: any) => {
        e.preventDefault();
        // console.log(e.target.value);
        // console.log(e.target.checked);
        wsSendJson(
            {
                type: 'telegram-notification-type-switch',
                payload: {
                    name: e.target.value,
                    is_active: e.target.checked,
                },
            }
        )
    }

    const sendTestNotification = (e: any) => {
        e.preventDefault();
        wsSendJson(
            {
                type: 'telegram-notification-test',
                payload: "",
            }
        )
    }

    const removeTelegramLink = (e: any) => {
        e.preventDefault();
        wsSendJson(
            {
                type: 'telegram-notification-remove-link',
                payload: "",
            }
        )
    }

    const notifications = [
        // {
        //     type: 'Пополнение счета',
        //     description: 'Начисление BTC',
        //     isActive: false,
        // },
        {
            type: 'Вывод средств',
            description: "Уведомление о получении запроса на вывод средств, а также о его выполнении",
            value: "withdraw_info",
            isActive: currentUser && _.has(currentUser, "telegram_notification_types.withdraw_info") ?
                currentUser.telegram_notification_types.withdraw_info : true
        },
        {
            type: 'Важные новости сервиса',
            description: '',
            value: "news",
            isActive: currentUser && _.has(currentUser, "telegram_notification_types.news") ?
                currentUser.telegram_notification_types.news : true
        },
    ]

    // const [notifications, setNotifications] = useState<any>([]);
    //
    // useEffect(() => {
    //
    //     setNotifications(
    //         [
    //             // {
    //             //     type: 'Пополнение счета',
    //             //     description: 'Начисление BTC',
    //             //     isActive: false,
    //             // },
    //             new Object({
    //                 type: 'Вывод средств',
    //                 description: "Уведомление о получении запроса на вывод средств, а также о его выполнении",
    //                 value: "withdraw_info",
    //                 isActive: currentUser && _.has(currentUser, "telegram_notification_types.withdraw_info") ?
    //                     currentUser.telegram_notification_types.withdraw_info : true
    //             }),
    //             {
    //                 type: 'Важные новости сервиса',
    //                 description: '',
    //                 value: "news",
    //                 isActive: currentUser && _.has(currentUser, "telegram_notification_types.news") ?
    //                     currentUser.telegram_notification_types.news : true
    //             },
    //         ])
    //
    // }, [currentUser]);

    // <Button variant={"outline"} size={"xs"} color={"red"} onClick={onCopy} ml={2} mr={1}>Отвязать</Button>
    return (
        <Box
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            p={{base: '4', md: '6'}}
            {...props}
        >
            <Stack spacing="5" divider={<StackDivider/>}>
                <Stack spacing="1">
                    {/*<Text fontSize="lg" fontWeight="medium">*/}
                    {/*    Notifications*/}
                    {/*</Text>*/}
                    {currentUser && currentUser.is_telegram_enabled ? (
                        <Box fontSize="sm" color="muted">
                            Ваш аккаунт привязан к телеграм боту {' '}
                            <Link href="https://t.me/ProHashCloudBot" target={"_blank"}>
                                @ProHashCloudBot
                            </Link>.
                        </Box>
                    ) : (
                        <Box fontSize="sm" color="muted">
                            Для получения уведомлений откройте {' '}
                            <Link href="https://t.me/ProHashCloudBot" target={"_blank"}>
                                @ProHashCloudBot
                            </Link>
                            {' '}затем отправьте код <Button size={"xs"} onClick={onCopy} ml={1} mr={1}>
                            {hasCopied ? 'скопирован!' : currentUser ? currentUser.telegram_secret : ""}
                        </Button> в чат.
                        </Box>
                    )}
                </Stack>
                {notifications.map((n: any) => (
                    <Stack key={n.value} justify="space-between" direction="row" spacing="4">
                        <Stack spacing="0.5" fontSize="sm">
                            <Box color="emphasized" fontWeight="medium">
                                {n.type}
                            </Box>
                            <Text color="muted">{n.description}</Text>
                        </Stack>
                        <Switch
                            isChecked={n.isActive}
                            value={n.value}
                            onChange={switchNotification}/>
                    </Stack>
                ))}
                {currentUser && currentUser.is_telegram_enabled && (
                    <Stack justify="right" direction="row" spacing="4">
                        <Stack spacing="0.5" fontSize="sm">
                            <Box color="emphasized" fontWeight="medium">
                            </Box>
                        </Stack>
                        <Button
                            onClick={sendTestNotification}
                            size={"sm"}
                            // leftIcon={<FaTelegramPlane color={"#555"} fontSize="1.1rem"/>}
                            variant="outline">
                            Отправить тестовое уведомление
                        </Button>

                        <Button
                            onClick={removeTelegramLink}
                            size={"sm"}
                            color={"red"}
                            // leftIcon={<FaTelegramPlane color={"#555"} fontSize="1.1rem"/>}
                            variant="outline">
                            Отменить привязку аккаунта
                        </Button>


                    </Stack>
                )}
            </Stack>

        </Box>
    )
}
