import {
    Box,
    BoxProps,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input, PinInput, PinInputField, Slider, SliderFilledTrack, SliderThumb, SliderTrack,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {PasswordField} from "../Signin/PasswordField";
import {Link as A} from "react-router-dom";
import {CheckboxCard, CheckboxCardGroup} from "../CheckboxCardGroup";
import {ChangePasswordRequest, useCounterContext, WithdrawalRequest} from "../../store/userStore";
import {useState} from "react";
import {OtpInput} from '../Inputs/OtpInput';

export const WithdrawalForm = (props: BoxProps) => {
    const {currentUser, withdrawalRequest} = useCounterContext();

    const emptyForm: WithdrawalRequest = {
        wallet: "",
        value: "",
        password: "",
        one_time_code: "",
        agrees: [],
    }
    const [inputForm, setInputForm] = useState<WithdrawalRequest>(emptyForm);

    const inputRef = React.useRef<HTMLInputElement>(null)

    const submitHandler = (e: any) => {
        e.preventDefault();

        // console.log(inputForm);
        withdrawalRequest(
            {
                one_time_code: inputForm.one_time_code,
                wallet: inputForm.wallet,
                value: inputForm.value,
                password: inputForm.password,
                agrees: inputForm.agrees,
            });
        // cleanInput();
    }

    function cleanInput() {
        setInputForm(emptyForm);
    }

    function setInputOneTimeCode(value: string) {
        setInputForm({...inputForm, one_time_code: value});
    }

    function setInputValue(value: string) {
        setInputForm({...inputForm, value: value});
    }

    function setInputWallet(value: string) {
        setInputForm({...inputForm, wallet: value});
    }

    function setInputPassword(value: string) {
        setInputForm({...inputForm, password: value});
    }

    function setInputCheckbox(values: any) {
        setInputForm({...inputForm, agrees: values});
    }

    // setInputForm({...inputForm, checkbox: value});


    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            {...props}
        >
            <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                {/*<Stack spacing="6" direction={{base: 'column', md: 'row'}}>*/}
                {/*<FormControl id="lastName">*/}
                {/*  <FormLabel>Last Name</FormLabel>*/}
                {/*  <Input defaultValue="Winston" />*/}
                {/*</FormControl>*/}
                {/*</Stack>*/}

                <FormControl id="firstName">
                    <FormLabel>Адрес BTC кошелька</FormLabel>
                    <Input
                        onChange={(e) => {
                            setInputWallet(e.target.value);
                        }}
                        value={inputForm.wallet}
                        placeholder={""}/>
                </FormControl>
                <FormControl id="firstName">
                    <FormLabel>Сумма (доступно <Button
                        onClick={() => {
                            setInputValue(currentUser ? currentUser.balance : "");
                        }}
                        variant={"link"}>{currentUser ? currentUser.balance : ""}</Button>)</FormLabel>
                    <Input
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        value={inputForm.value}
                        placeholder={""}/>
                </FormControl>

                {/*
                <FormControl id="pwd">
                    <PasswordField
                        onChange={(e) => {
                            setInputPassword(e.target.value);
                        }}
                        value={inputForm.password}
                        name={"password"} title={"Текущий пароль"}/>
                </FormControl>
                */}
                <FormControl id="oneTimePassword">
                    <FormLabel>Одноразовый код (one-time password)</FormLabel>

                    <OtpInput onChange={(value: any) => {
                        setInputOneTimeCode(value);
                    }}/>

                </FormControl>

                {/*<Slider defaultValue={60} min={0} max={300} step={30}>*/}
                {/*    <SliderTrack bg='red.100'>*/}
                {/*        <Box position='relative' right={10}/>*/}
                {/*        <SliderFilledTrack bg='tomato'/>*/}
                {/*    </SliderTrack>*/}
                {/*    <SliderThumb boxSize={6}/>*/}
                {/*</Slider>*/}

                <CheckboxCardGroup
                    onChange={setInputCheckbox}
                    spacing="3">
                    <FormLabel>Прочтите и согласитесь с условиями</FormLabel>
                    <CheckboxCard value={"ok1"}>
                        <Text color="emphasized" fontWeight="medium" fontSize="sm">
                            Контроль над кошельком
                        </Text>
                        <Text color="muted" fontSize="sm">
                            Я подтверждаю, что указал верный адрес кошелька, имею доступ к этому кошельку или его seed фразе.
                        </Text>
                    </CheckboxCard>

                    <CheckboxCard value={"ok2"}>
                        <Text color="emphasized" fontWeight="medium" fontSize="sm">
                            Комиссия за перевод
                        </Text>
                        <Text color="muted" fontSize="sm">
                            Я понимаю, что из суммы перевода будет вычтен сетевой сбор за транзакцию.
                        </Text>
                    </CheckboxCard>

                </CheckboxCardGroup>

                <Text color="muted" fontSize="sm">
                    Сеть Bitcoin взимает сетевой сбор за транзакцию, которая зависит от нагрузки на блокчейн.
                    <br/>
                    Сервис ProHash не взимает комиссий за вывод средств.
                </Text>
            </Stack>

            <Divider/>
            <Flex direction="row-reverse" py="4" px={{base: '4', md: '6'}}>
                <Button
                    onClick={submitHandler}
                    type="submit" variant="primary">
                    Отправить запрос
                </Button>
            </Flex>
        </Box>
    )
}
