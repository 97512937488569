export enum Role {
    Guest = 0,
    User = 1,
    Investor = 50,
    Support = 60,
    It = 70,
    PlaceOwner = 80,
    Superuser = 90,
    FeeAccount = 98,
    System = 99,
    Root = 500,
}

export const AllUserRoles = [
    Role.User,
    Role.Investor,
    Role.Support,
    Role.It,
    Role.PlaceOwner,
    Role.Superuser,
    Role.FeeAccount,
    Role.System,
    Role.Root,
];

export const AllWithoutPlaceOwnerRoles = [
    Role.User,
    Role.Investor,
    Role.Support,
    Role.It,
    Role.Superuser,
    Role.FeeAccount,
    Role.System,
    Role.Root,
];

export const AllowedPlaceOwnerParthnames = [
    "/pools",
    // "/settings",
    "/signin",
];

interface TelegramNotificationTypes {
    withdraw_info: boolean;
    news: boolean;
}

export interface User {
    IsAdmin(): boolean;
    IsPlaceOwner(): boolean;
    id: string;
    type: number;
    username: string;
    firstname: string;
    balance: string;
    token: string;
    is_two_factor_enabled: boolean;
    two_factor_url: string;
    is_telegram_enabled: boolean;
    telegram_secret: string;
    telegram_notification_types: TelegramNotificationTypes;
}

export const IsPlaceOwner = (u: User | undefined) => {
    // console.log(u && u.type);
    return u && u.type === Role.PlaceOwner;
}

export const IsAdmin = (u: User | undefined) => {
    // console.log(u && u.type);
    return u && (u.type === Role.Superuser || u.type === Role.Support || u.type === Role.It);
}
