import {
    Box,
} from '@chakra-ui/react'
import * as React from 'react'
import {Header} from "../Header/Header";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_ADDRESS} from "../../../Config";
import {axiosConfig} from "../../../store/localStore";
import {useParams} from "react-router-dom";
import {TableWithFilter} from "../Table/TableWithFilter";

require('moment/locale/ru');


type SimpleDevice = {
    ip_address: string
    // mac_address: string
    last_activity: string
    // status: string
    ports: number[]
    all_ports: number[]
}


type SimpleDeviceResp = {
    data: SimpleDevice[];
}

export const ManagerIpAddressesPage = () => {
    let {placeId} = useParams();

    async function fetchSimpleDevices(): Promise<SimpleDeviceResp> {
        const res = await axios.get(`${API_ADDRESS}/v1/manager/places/${placeId}/simple_devices`, axiosConfig())
        return res.data
    }

    const {
        isLoading, error, data,
        // isFetching, isFetched, isFetchedAfterMount
    } = useQuery(["simple_devices", placeId], fetchSimpleDevices);

    if (isLoading) return <Box>Loading...</Box>;
    if (error && error instanceof Error) return <Box>An error has occurred {error.message}</Box>;

    const columns = [
        {
            id: "ip_address",
            header: "IP адрес",
        },
        {
            id: "last_activity",
            header: "Последняя активность",
        },
        {
            id: "ports",
            header: "Доступные порты",
        },
        {
            id: "all_ports",
            header: "Все порты",
        }
    ]

    return (
        <Box>
            <Header>IP адреса и порты</Header>
            <Box pt={5}>
                <TableWithFilter columns={columns} data={data}/>
            </Box>
        </Box>
    )
}




