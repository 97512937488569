import {
    Heading,
} from '@chakra-ui/react'
import * as React from 'react'


export const Header = (props: any) => {

    return (
        <Heading as="h1" size="md">{props.children}</Heading>
    )
}
