export interface Chip {
    temp: number;
}

export interface Board {
    temp: number;
    chips: Chip[];
}

export interface Stats {
    boards_temp: number[];
    chips_temp: number[][];
}

export interface HashrateStats {
    hashrate_now: number;
    hash_rate_1h: number;
    hash_rate_24h: number;
}

export interface DevBoardStats {
    temperature: number;
    factory_hashrate: number;
    hashrate_average: number;
    hashrate_1m: number;
    hashrate_5m: number;
    hashrate_15m: number;
    accepted: number;
    rejected: number;
    serial_number: string;
}

export interface Device {
    dev_boards_stats: DevBoardStats[];
    is_ok: any;
    id: string;
    name: string;
    description: string;
    created: string;
    brand: string;
    model: string;
    device_hash_rate: number;
    device_serial: string;
    stats: Stats;
    hashrate_stats: HashrateStats;
    owner_description: string;
    local_address: string;
    worker_name: string;
}

export interface DevicesDashboard {
    count: number;
    count_good: number;
    count_warning: number;

    devices: Device[];
}

export interface DevicesStore {
    Device: Device | undefined;
    Dashboard: DevicesDashboard | undefined;
}

export interface RealtimeHashrate {
    device_id: string;
    hashrate: number[];
}

export interface RealtimeHashrateStore {
    hashrate: RealtimeHashrate;
}

function getDefaultStore(): DevicesStore {
    return {
        Device: undefined,
        Dashboard: undefined,
    };
}

export function devicesSetDashboard(devicesStore: DevicesStore | undefined, payload: DevicesDashboard) {
    if (devicesStore === undefined) {
        devicesStore = getDefaultStore()
    }
    devicesStore.Dashboard = payload;
    return devicesStore;
}

export function devicesSetDevice(devicesStore: DevicesStore | undefined, payload: Device) {
    if (devicesStore === undefined) {
        devicesStore = getDefaultStore()
    }
    devicesStore.Device = payload;
    return devicesStore;
}

export const defaultRealtimeHashrateStore = {
    hashrate: {
        device_id: "",
        hashrate: [],
    },
};

export function devicesSetRealtimeHashrate(realtimeHashrateStore: RealtimeHashrateStore, payload: RealtimeHashrate) {
    // if (realtimeHashrateStore === undefined) {
    //     realtimeHashrateStore = {
    //         hashrate: {
    //             device_id: "",
    //             hashrate: [],
    //         },
    //     };
    // }
    realtimeHashrateStore.hashrate = payload;
    return realtimeHashrateStore;
}
