import {
    Box,
    Heading,
} from '@chakra-ui/react'
import * as React from 'react'
import {Header} from "../Header/Header";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {API_ADDRESS} from "../../../Config";

interface GithubResp {
    data: {
        name: string;
    }
}

export const ManagerUsersPage = () => {

    const {isLoading, error, data, isFetching} = useQuery(["repoData"], () =>
        axios
            .get(`${API_ADDRESS}/test/delay`)
            .then((res: GithubResp) => res.data)
    );

    if (isLoading) return <Box>Loading...</Box>;

    if (error && error instanceof Error) return <Box>An error has occurred{error.message}</Box>;

    return (
        <Box>
            <Header>Пользователи</Header>
            <Box>
                <Heading>{data?.name}</Heading>
            </Box>
        </Box>
    )
}
