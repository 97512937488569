import {
    Button,
    ButtonGroup,
    Container,
    Divider,
    HStack,
    IconButton,
    Input,
    Stack,
    Text, useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FaBitcoin,
    FaEthereum,
    FaSketch,
} from 'react-icons/fa'
import {Logo} from './components/Logo'
import {useLocation, useNavigate} from "react-router-dom";
import {menuItems} from "./components/Navigation/Sidebar";
import {useCounterContext} from "./store/userStore";
import {format_raw} from './utils/format';
import {useState} from "react";

export const Footer = () => {
    let location = useLocation();
    let navigate = useNavigate();

    let {
        currentUser,
        realtimeRatesStore,
        newsSubscribe,
    } = useCounterContext();

    if (realtimeRatesStore) {
        // console.log(realtimeRatesStore);
    }

    const [inputForm, setInputForm] = useState(
        {
            email: "",
        });

    function setInputEmail(value: string) {
        setInputForm({...inputForm, email: value});
    }

    const submitSubscribeHandler = (e: any) => {
        e.preventDefault();
        newsSubscribe(inputForm);
        setInputEmail("");
    }

    return (
        <Container as="footer" role="contentinfo" maxW={'100vw'}>
            <Stack
                spacing="8"
                direction={{base: 'column', md: 'row'}}
                justify="space-between"
                py={{base: '12', md: '16'}}
            >
                <Stack spacing={{base: '6', md: '3'}} align="start">
                    {/*<Text color="#555">Grow your wealth with our experience.</Text>*/}
                    {/*<Text color="#555">&copy; {new Date().getFullYear()} Прохеш. Все права защищены.</Text>*/}
                    {/*<FaBitcoin style={{marginRight: 3}} color={useColorModeValue('#ff9000', '#FBD38D')} />*/}
                    <HStack>
                        <FaBitcoin style={{marginRight: 0}} color={useColorModeValue('#ff9000', '#FBD38D')}/>
                        <Text
                            color="#555">BTC/USD {realtimeRatesStore ? format_raw(realtimeRatesStore.btc) : "..."}</Text>
                        {/*<HiTrendingUp/>*/}
                    </HStack>
                    <HStack>
                        <FaEthereum style={{marginRight: 0}} color={useColorModeValue('#215caf', '#89bcef')}/>
                        <Text color="#555">ETH/USD {realtimeRatesStore ? format_raw(realtimeRatesStore.eth) : "..."}</Text>
                        {/*<HiTrendingDown/>*/}
                    </HStack>
                    <HStack>
                        <FaSketch style={{marginRight: 0}} color={useColorModeValue('#4521af', '#89bcef')}/>
                        <Text color="#555">TON/USD {realtimeRatesStore ? format_raw(realtimeRatesStore.ton) : "..."}</Text>
                        {/*<HiTrendingDown/>*/}
                    </HStack>
                    {/*<HStack>*/}
                    {/*    <FaGlobeAmericas style={{marginRight: 0}} color={useColorModeValue('#4521af', '#89bcef')}/>*/}
                    {/*    <Text color="#911">SP500 4,131.92</Text> <HiTrendingDown/>*/}
                    {/*</HStack>*/}
                    <HStack pt={5}>
                        <Logo
                            color={useColorModeValue('#eee', '#333')}
                            color2={useColorModeValue('#ddd', '#555')}/>
                    </HStack>
                </Stack>
                <Stack
                    direction={{base: 'column-reverse', md: 'column', lg: 'row'}}
                    spacing={{base: '12', md: '8'}}
                >
                    <Stack direction="row" spacing="8">
                        <Stack spacing="4" minW="36" flex="1">
                            {/*<Text fontSize="sm" fontWeight="semibold" color="subtle">*/}
                            {/*    Продукт*/}
                            {/*</Text>*/}
                            <Stack spacing="3" shouldWrapChildren>

                                {menuItems.slice(0).map(item => {
                                    if (currentUser && !item.roles.includes(currentUser.type)) {
                                        return null
                                    }
                                    if (item.link === "") {
                                        return null
                                    }
                                    return (
                                        <Button
                                            key={item.name}
                                            onClick={() => {
                                                navigate(item.link)
                                            }} variant={location.pathname === item.link ? "link" : "link"}>{item.name}</Button>
                                    )
                                })}

                            </Stack>
                        </Stack>
                        {/*<Stack spacing="4" minW="36" flex="1">*/}
                        {/*  <Text fontSize="sm" fontWeight="semibold" color="subtle">*/}
                        {/*    Legal*/}
                        {/*  </Text>*/}
                        {/*  <Stack spacing="3" shouldWrapChildren>*/}
                        {/*    <Button variant="link">Privacy</Button>*/}
                        {/*    <Button variant="link">Terms</Button>*/}
                        {/*    <Button variant="link">License</Button>*/}
                        {/*  </Stack>*/}
                        {/*</Stack>*/}
                    </Stack>
                    <Stack spacing="4">
                        <Text fontSize="sm" fontWeight="semibold" color="subtle">
                            Подпишитесь на новости
                        </Text>
                        <Stack spacing="4" direction={{base: 'column', sm: 'row'}} maxW={{lg: '360px'}}>
                            <Input
                                onChange={(e) => {
                                    setInputEmail(e.target.value);
                                }}
                                value={inputForm.email}
                                placeholder="Введите ваш email"
                                type="email" required/>
                            <Button
                                onClick={submitSubscribeHandler}
                                variant="solid" type="submit" flexShrink={0}>
                                Подписаться
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Divider/>
            <Stack
                pt="4"
                pb="5"
                justify="space-between"
                direction={{base: 'column-reverse', md: 'row'}}
                align="center"
            >
                <Text fontSize="sm" color="subtle">
                    &copy; {new Date().getFullYear()} Прохеш. Все права защищены.
                </Text>
                {/*<ButtonGroup variant="ghost">*/}
                {/*  <IconButton*/}
                {/*    as="a"*/}
                {/*    href="#"*/}
                {/*    aria-label="LinkedIn"*/}
                {/*    icon={<FaLinkedin fontSize="1.25rem" />}*/}
                {/*  />*/}
                {/*  <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />*/}
                {/*  <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />*/}
                {/*</ButtonGroup>*/}
            </Stack>
        </Container>
    )
}
