export interface PoolHistory {
    date: string;
    filling_pct: number;
    cumulative_shared_pct: number;
    item_share_pct: number;
    description: string;
}

export interface Device {
    id: string;
    is_ok: boolean;
    seen: string;
}

interface Weather {
    now: string;
    forecast: string;
}

export interface PoolStats {
    weather: Weather;
    devices: Device[]
}

export interface ElectricityHistoryItem {
    cost: number;
    id: string;
    date: string;
    value: number;
    diff_value: number;
    diff_avg_value: number;
    cum_avg_value: number;
    comment: string;
}

export interface ElectricityStats {
    daily_avg: number;
    month_sum: number;
    daily_data: ElectricityHistoryItem[];
    minute_data: ElectricityHistoryItem[];
    quality_data: ElectricityHistoryItem[];
}

export interface Pool {
    hashrate: number;
    tariff_kwh_price: number;
    id: string;
    title: string;
    name: string;
    description: string;
    closed_at: string;
    stats: PoolStats
    history: PoolHistory[];
    electricity_stats: ElectricityStats;
}

export interface PoolsDashboard {
    count: number;
    share_pct: number;
    reward: number;
    hashrate: string;

    pools: Pool[];
}

export interface PoolsStore {
    Dashboard: PoolsDashboard | undefined;
}

export function poolsSetDashboard(poolsStore: PoolsStore | undefined, payload: PoolsDashboard) {
    if (poolsStore === undefined) {
        poolsStore = {
            Dashboard: undefined,
        }
    }
    poolsStore.Dashboard = payload;
    return poolsStore;
}
