import * as React from "react"
import {
    Container,
    useBreakpointValue,
    Flex,
    Stack,
    useDimensions,
} from "@chakra-ui/react"
import {Footer} from "./Footer";
import './index.css';
import {
    useLocation, Outlet, useNavigate
} from "react-router-dom";
import {Sidebar} from "./components/Navigation/Sidebar";
import {Navbar} from "./components/Navigation/Navbar";
import {useEffect, useRef} from "react";
import {useCounterContext} from "./store/userStore";
import {AllowedPlaceOwnerParthnames, IsPlaceOwner} from "./store/userHelper";


export function Main() {
    const {currentUser} = useCounterContext();

    let navigate = useNavigate();
    let location = useLocation();

    // React.useEffect(() => {
    //     console.log(location.pathname)
    //     // ga.send(["pageview", location.pathname]);
    // }, [location]);

    useEffect(() => {
        if (currentUser) {
            // AllowedPlaceOwnerParthnames

            if (IsPlaceOwner(currentUser) && !AllowedPlaceOwnerParthnames.includes(location.pathname)) {
                navigate("/pools", {replace: true})
            }
        }
    });

    const elementRef = useRef()
    // @ts-ignore
    const dimensions = useDimensions(elementRef, true)

    const isDesktop = useBreakpointValue({base: false, lg: true})
    // console.log(dimensions && dimensions.marginBox.height);
    return (
        <Flex
            as="section"
            direction={{base: 'column', lg: 'row'}}
            height="100vh"
            bg="bg-canvas"
            // bg="#f9f9f9"
            overflowY="auto"
        >

            {isDesktop ?
                // @ts-ignore
                <Sidebar height={dimensions ? dimensions.marginBox.height : 100}/>
                : <Navbar/>}


            <Container
                pt="5" flex="1" pb={"5"}>
                <Stack spacing={{base: '8', lg: '6'}}
                    // @ts-ignore
                       ref={elementRef}>
                    <Outlet/>
                </Stack>
                <Footer/>
            </Container>
        </Flex>
    )
}
