import {
    Badge,
    Box,
    Center,
    Container, Grid,
    GridItem,
    Heading,
    HStack, Icon,
    Input,
    Link, LinkBox,
    SimpleGrid, Skeleton,
    Stack, Table, TableContainer, Tbody, Td, Text,
    Th, Thead, Tooltip, Tr, useBoolean,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {Card} from "../Cards/Card";
import {Pool, PoolHistory, ElectricityHistoryItem} from "../../store/poolsStore";
import moment from "moment";
import {
    Link as A, useLocation, useNavigate,
} from "react-router-dom";
import {
    FaCoins,
    FaGem,
    FaGripHorizontal,
    FaHistory,
    FaLightbulb,
    FaMap,
    FaRegCheckCircle,
    FaRegClock,
    FaRegHandLizard,
    FaStream
} from 'react-icons/fa';
import {Stat} from "../Cards/Stat";
import {useCounterContext} from "../../store/userStore";
import {StatSmall} from "../Cards/StatSmall";
import {format, format_raw} from "../../utils/format";
import {RadioButton, RadioButtonGroup} from "../../RadioButtonGroup";
import {useEffect, useRef, useState} from "react";
import {TypeIncome, TypeOutcome, TypeUnknown} from "../../store/investmentsStore";
import {ResponsiveLine} from "@nivo/line";
import _ from 'lodash'
import {linearGradientDef} from '@nivo/core';
import {NavButton} from "../Navigation/NavButton";
import {FiArrowDownRight, FiArrowUpRight, FiBarChart2, FiCpu, FiHome} from "react-icons/fi";
import {IconType} from "react-icons/lib";
import {LineChart} from '../Charts/LineChart';
import {IsPlaceOwner} from "../../store/userHelper";

const useScroll = () => {
    const elRef = useRef(null);
    // @ts-ignore
    const executeScroll = () => elRef.current.scrollIntoView();

    return [executeScroll, elRef];
};

interface PoolItemProps {
    pool: Pool
}

interface MenuElement {
    name: string
    onClick: any
    icon: IconType
}

export function PoolItem(props: PoolItemProps) {
    let location = useLocation();
    let navigate = useNavigate();
    const {currentUser, poolsStore} = useCounterContext();

    const [visible, setVisible] = useBoolean()

    // const devicesRef = useRef<HTMLInputElement>(null)

    const [onDevicesClick, devicesRef] = useScroll()
    const [onElectricityClick, electricityRef] = useScroll()
    const [onJournalClick, journalRef] = useScroll()

    const menuItems: MenuElement[] = [
        {
            name: "Устройства",
            onClick: onDevicesClick,
            icon: FaGripHorizontal,
        },
        {
            name: "Электроэнергия",
            onClick: onElectricityClick,
            icon: FaLightbulb,
        },
        {
            name: "Журнал",
            onClick: onJournalClick,
            icon: FaHistory, //FaGem, // FaRegHandLizard, //
        },
    ]

    const [currentFilter, setCurrentFilter] = useState(0);

    function changeElectricityView(value: string) {
        if (value === 'income') {
            setCurrentFilter(TypeIncome);
            return
        }
        if (value === 'outcome') {
            setCurrentFilter(TypeOutcome);
            return
        }
        setCurrentFilter(TypeUnknown)
    }

    // console.log(poolsStore);

    let stats: any[] = [undefined, undefined, undefined, undefined]
    if (!IsPlaceOwner(currentUser) && poolsStore !== undefined && poolsStore.Dashboard !== undefined) {
        stats = [
            // {
            //     label: 'Тариф, руб./кВт',
            //     value: `${format_raw(props.pool.tariff_kwh_price / 100)}`,
            //     delta: {value: '0', isUpwardsTrend: true}
            // },

            {
                label: 'Всего устройств',
                value: props.pool.stats.devices.length,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {
                label: 'Общий хешрейт',
                value: `${format_raw(props.pool.hashrate / 1000)} PH`,
                delta: {value: '0', isUpwardsTrend: true}
            },

            {
                label: 'Энергопотребление за месяц',
                value: `${format_raw(props.pool.electricity_stats.month_sum / 1000)} кВт*ч`,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {
                label: 'За сутки среднее',
                value: `${format_raw(props.pool.electricity_stats.daily_avg / 1000)} кВт*ч`,
                delta: {value: '0', isUpwardsTrend: true}
            },
            {
                label: 'Температура воздуха',
                value: `${props.pool.stats.weather.now}`,
                delta: {value: `${props.pool.stats.weather.forecast}`, isUpwardsTrend: undefined}
            },
        ]
    }

    let electricityData: any[] = []
    let electricityStats = [
        {
            "id": "consumption",
            // "color": "#310062",
            "data": electricityData,
        }
    ]

    let daily_data = _.cloneDeep(props.pool.electricity_stats.daily_data)
    _.reverse(daily_data).map((item: ElectricityHistoryItem, i) => {
        electricityStats[0].data.push(
            {
                x: moment(item.date).format('DD.MM'),
                y: item.diff_value / 1000
            })
    })
    // save last 90 days for electricityStats
    electricityStats[0].data = electricityStats[0].data.slice(-90)


    // console.log(JSON.stringify(electricityStats));

    // let el_data = _.cloneDeep(props.pool.electricity_stats.minute_data)
    // _.reverse(el_data).map((item: ElectricityHistoryItem, i) => {
    //     electricityStats[0].data.push(
    //         {
    //             // x: moment(item.date).format('DD.MM'),
    //             x: moment(item.date).format('HH:mm'),
    //             y: item.value / 1000
    //         })
    // })


    return (
        <>
            <Stack spacing="1" pt={5}>
                <Heading
                    onClick={setVisible.toggle}
                    cursor={"pointer"}
                    size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    <Link textDecoration={"underline"}>{props.pool.title}</Link>
                    {moment(props.pool.closed_at).format('YYYY') !== "0001" &&
                      <Badge ml={3} colorScheme='yellow'>Закрыт с {moment(props.pool.closed_at).format('DD.MM.YYYY')}</Badge>
                    }
                </Heading>
                {/*<Text color="muted">история заполнения пула</Text>*/}
                {!IsPlaceOwner(currentUser) &&
                  <Text color="muted" fontSize={"13"}>
                      {props.pool.description}
                  </Text>
                }
            </Stack>

            {!IsPlaceOwner(currentUser) &&
              <Stack spacing={{base: '5', lg: '6'}}>
                <Grid templateColumns='repeat(8, 1fr)' gap={6}>
                  <GridItem w='100%'>
                    <StatSmall {...stats[0]} />
                  </GridItem>
                  <GridItem w='100%'>
                    <StatSmall {...stats[1]} />
                  </GridItem>
                    {/*<GridItem w='100%' colSpan={2}>*/}
                    {/*    <Box*/}
                    {/*        px={{base: '4', md: '4'}}*/}
                    {/*        py={{base: '5', md: '4'}}*/}
                    {/*        bg="bg-surface"*/}
                    {/*        borderRadius="lg"*/}
                    {/*        boxShadow={useColorModeValue('sm', 'sm-dark')}*/}
                    {/*    >*/}
                    {/*        <Stack>*/}
                    {/*            <HStack justify="space-between">*/}
                    {/*                <Text fontSize="xs" color="muted">*/}
                    {/*                    Комиссии*/}
                    {/*                </Text>*/}
                    {/*            </HStack>*/}
                    {/*            <HStack justify="space-between">*/}
                    {/*                <TableContainer>*/}
                    {/*                    <Table variant='unstyled' size='' fontSize={11} mb={2}>*/}
                    {/*                        <Tbody>*/}
                    {/*                            <Tr>*/}
                    {/*                                <Td>*/}
                    {/*                                    3%*/}
                    {/*                                </Td>*/}
                    {/*                                <Td>*/}
                    {/*                                    лицензия ProHash*/}
                    {/*                                </Td>*/}
                    {/*                            </Tr>*/}
                    {/*                            <Tr>*/}
                    {/*                                <Td>*/}
                    {/*                                    2%*/}
                    {/*                                </Td>*/}
                    {/*                                <Td>*/}
                    {/*                                    техническая поддержка, г.Иркутск*/}
                    {/*                                </Td>*/}
                    {/*                            </Tr>*/}
                    {/*                            <Tr>*/}
                    {/*                                <Td>*/}
                    {/*                                    2%*/}
                    {/*                                </Td>*/}
                    {/*                                <Td>*/}
                    {/*                                    техническая поддержка, г.Санкт-Петербург (Центр мониторинга Prohash)*/}
                    {/*                                </Td>*/}
                    {/*                            </Tr>*/}
                    {/*                        </Tbody>*/}
                    {/*                    </Table>*/}
                    {/*                </TableContainer>*/}
                    {/*            </HStack>*/}
                    {/*        </Stack>*/}
                    {/*    </Box>*/}
                    {/*</GridItem>*/}
                  <GridItem w='100%' colSpan={2}>
                    <StatSmall {...stats[2]} />
                  </GridItem>
                  <GridItem w='100%' colSpan={2}>
                    <StatSmall {...stats[3]} />
                  </GridItem>
                  <GridItem w='100%' colSpan={2}>
                    <StatSmall {...stats[4]} />
                  </GridItem>
                </Grid>


                  {/*<SimpleGrid columns={{base: 2, md: 5}} gap="6">*/}
                  {/*    {stats.map((stat, id) => {*/}
                  {/*        if (stat === undefined) {*/}
                  {/*            return (*/}
                  {/*                <Box*/}
                  {/*                    px={{base: '4', md: '6'}}*/}
                  {/*                    py={{base: '5', md: '6'}}*/}
                  {/*                    bg="bg-surface"*/}
                  {/*                    borderRadius="lg"*/}
                  {/*                    key={id}>*/}
                  {/*                    <Stack>*/}
                  {/*                        <Skeleton height='20px'/>*/}
                  {/*                        <Skeleton height='20px'/>*/}
                  {/*                        <Skeleton height='20px'/>*/}
                  {/*                    </Stack>*/}
                  {/*                </Box>*/}
                  {/*            )*/}
                  {/*        }*/}
                  {/*        return (*/}
                  {/*            <StatSmall key={id} {...stat} />*/}
                  {/*        )*/}
                  {/*    })}*/}
                  {/*</SimpleGrid>*/}
              </Stack>
            }

            {visible && (
                <>
                    {!IsPlaceOwner(currentUser) && (
                        <>
                            <Card p={4} minH={1}>
                                <HStack>
                                    {menuItems.map(item => {

                                        return (
                                            <NavButton
                                                key={item.name}
                                                label={item.name}
                                                icon={item.icon}
                                                onClick={item.onClick}
                                            />
                                        )
                                    })}
                                </HStack>
                            </Card>


                            <Grid>
                                <GridItem colSpan={2}>
                                    <Box>
                                        <Heading
                                            ref={devicesRef}
                                            size={"xs"} fontWeight="medium">
                                            <Link href={"/devices"}>Устройства ({props.pool.stats.devices.length})</Link>
                                        </Heading>
                                        <Card mt={5} p={4} minH={1}>
                                            <SimpleGrid columns={[3, null, 12]} spacing='10px'>
                                                {props.pool.stats.devices.map((device, index) => {
                                                    return (
                                                        <LinkBox key={index}>
                                                            <A to={`/devices/${device.id}`}>
                                                                <Box
                                                                    _hover={{
                                                                        backgroundColor: device.is_ok ? '#538f11' : '#b46700'
                                                                    }}
                                                                    bg={device.is_ok ? '#84bf40' : '#ff9200'}
                                                                    p={2} fontSize={12}>
                                                                    {/*<HStack color={device.is_ok ? '#2e4618' : '#423217'} fontSize={12}>*/}
                                                                    <Center>
                                                                        {device.is_ok ?
                                                                            <FaRegCheckCircle
                                                                                color={device.is_ok ? '#3e6511' : '#6e4c22'}
                                                                            />
                                                                            :
                                                                            <FaRegClock
                                                                                color={device.is_ok ? '#3e6511' : '#6e4c22'}
                                                                            />
                                                                        }
                                                                    </Center>
                                                                    {/*<>{device.seen}</>*/}
                                                                    {/*</HStack>*/}
                                                                </Box>
                                                            </A>
                                                        </LinkBox>
                                                    )
                                                })}
                                            </SimpleGrid>
                                        </Card>
                                    </Box>

                                </GridItem>
                                <GridItem colStart={3} colSpan={1} ml={10}>
                                    <Heading size={"xs"} fontWeight="medium">
                                        Веб-камера
                                    </Heading>
                                    <Card mt={5} p={4} minH={10} minW={300}>
                                        <Center h={140}>
                                            <Badge colorScheme='yellow'>Скоро</Badge>
                                        </Center>
                                    </Card>
                                </GridItem>
                            </Grid>
                        </>
                    )}


                    <HStack justify="space-between">
                        <Heading
                            ref={electricityRef}
                            size={"xs"} fontWeight="medium">
                            Электроэнергия
                        </Heading>
                        <Box>
                            <Stack spacing="0">
                                <RadioButtonGroup onChange={changeElectricityView} defaultValue="daily_data" size={'sm'}>
                                    <RadioButton value="daily_data">Показания</RadioButton>
                                    <RadioButton value="minute_data" disabled>
                                        <Tooltip hasArrow placement="top" label='Скоро!' bg='gray.300' color='black'>
                                            Потребление онлайн
                                        </Tooltip>
                                    </RadioButton>
                                    <RadioButton value="quality_data" disabled>
                                        <Tooltip hasArrow shouldWrapChildren placement="top" label='Скоро!' bg='gray.300' color='black'>
                                            Качество сети
                                        </Tooltip>
                                    </RadioButton>
                                </RadioButtonGroup>
                            </Stack>
                        </Box>
                        <Box>
                            <Input disabled
                                // size="md"
                                // backgroundColor="#ffffff"
                                   onChange={(e) => {
                                       console.log(e.target.value)
                                   }}
                                   value={"2022-07"}
                                   type="month"
                            />
                        </Box>

                    </HStack>

                    <SimpleGrid columns={{base: 1, md: 1}} gap="6">
                        <Card p={1}>
                            <LineChart data={electricityStats}/>
                        </Card>
                    </SimpleGrid>

                    <Card minH="sm" p={4}>
                        <TableContainer>
                            <Table variant='striped'>
                                {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                                <Thead>
                                    <Tr>
                                        <Th>Дата, время</Th>
                                        <Th isNumeric>Показания на начало суток</Th>
                                        <Th isNumeric>Разница с пред. сутками</Th>
                                        {/*<Th isNumeric>Среднее (накопительное)</Th>*/}
                                        <Th isNumeric>Отклонение от среднего</Th>
                                        {!IsPlaceOwner(currentUser) && (
                                            <Th isNumeric>Стоимость</Th>
                                        )}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.pool.electricity_stats.daily_data.map((item: ElectricityHistoryItem, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Tr>
                                                    <Td>{moment(item.date).format('DD.MM.YYYY HH:mm')}</Td>
                                                    <Td isNumeric>{format_raw(item.value / 1000)} кВт*ч</Td>
                                                    <Td isNumeric>{format_raw(item.diff_value / 1000)} кВт*ч</Td>
                                                    {/*<Td isNumeric>{format_raw(item.cum_avg_value / 1000)} кВт*ч</Td>*/}
                                                    <Td isNumeric
                                                        fontWeight={item.diff_avg_value > 100000 ? "bold" : "normal"}
                                                        color={item.diff_avg_value > 100000 ? "red.500" : "green.500"}>
                                                        {format_raw(item.diff_avg_value / 1000)} кВт*ч
                                                    </Td>
                                                    {!IsPlaceOwner(currentUser) && (
                                                        <Td isNumeric>{format_raw(item.cost / 100)} руб.</Td>
                                                    )}
                                                </Tr>
                                                {item.comment && (
                                                    <Tr>
                                                        <Td colSpan={6}>
                                                            <Badge colorScheme='yellow'>{item.comment}</Badge>
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Card>

                    {!IsPlaceOwner(currentUser) && (
                        <>
                            <Heading
                                ref={journalRef}
                                size={"xs"} fontWeight="medium">
                                Журнал
                            </Heading>
                            <Card minH="sm" p={4}>
                                <TableContainer>
                                    <Table variant='striped'>
                                        {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                                        <Thead>
                                            <Tr>
                                                <Th>Дата, время</Th>
                                                <Th isNumeric>Сбор пула, %</Th>
                                                <Th isNumeric>Доля, %</Th>
                                                <Th isNumeric>Совокупная доля, %</Th>
                                                <Th>Примечание</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {props.pool.history.map((item: PoolHistory, i) => {
                                                return (
                                                    <Tr key={i}>
                                                        <Td>{moment(item.date).format('DD.MM.YYYY, HH:mm:ss')}</Td>
                                                        <Td isNumeric>{item.filling_pct}</Td>
                                                        <Td isNumeric>{item.item_share_pct / 100}</Td>
                                                        <Td isNumeric>{item.cumulative_shared_pct / 100}</Td>
                                                        <Td>{item.description}</Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </>
                    )}
                </>
            )}
        </>
    )
}
