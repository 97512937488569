import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    HStack, Link,
    SimpleGrid,
    Stack, StackDivider, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import {WithdrawalForm} from "./WithdrawalForm";
import {useCounterContext} from '../../store/userStore';
import {Link as A} from "react-router-dom";


const stats = [
    {label: 'Внесено', value: '3,500,000', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'В работе', value: '2,923,796', delta: {value: '0', isUpwardsTrend: true}},
    {label: 'Баланс', value: '600,323', delta: {value: '0', isUpwardsTrend: false}},
]


export const Withdrawal = (props: any) => {
    const {currentUser, setCurrentUser, currentUsers} = useCounterContext();
    return (
        <>
            <Stack spacing="1" pb={3}>
                <Heading size={useBreakpointValue({base: 'xs', lg: 'sm'})} fontWeight="medium">
                    Запрос на вывод средств
                </Heading>
            </Stack>
            <Stack spacing="5">
                <Box flexShrink={0} pt={0}>
                    {currentUser && !currentUser.is_two_factor_enabled && (
                        <Badge colorScheme='yellow'>
                            Для вывода средств необходимо активировать двухфакторную авторизацию.
                            {" "}<A to={"/settings"}>Перейти в настройки</A>.
                        </Badge>
                    )}
                </Box>
                <Box flexShrink={0} pt={3}>
                    <Text color="muted" fontSize="sm">
                        Пожалуйста, внимательно заполните поля.
                    </Text>
                </Box>
                <WithdrawalForm maxW={{lg: '4xl'}}/>

            </Stack>
        </>
    )
}
